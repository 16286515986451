/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from "react";

import StockStatus from "./StockStatus";

import defaultTyre from "../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";

function ProductCard({ resellerProduct, handleClickOpenModal }) {
    const [productWithStock, setProductWithStock] = useState(null);

    useLayoutEffect(() => {
        const productsWithStock = resellerProduct.productResellersList?.filter(
            (product) => product.iProductQuantity > 0
        );

        if (productsWithStock.length > 0) {
            setProductWithStock(productsWithStock[0]);
        }
    }, []);

    return (
        <div
            key={resellerProduct?.gGuid}
            className="tyre-cards col-12 col-md-4 px-lg-4 col-lg-3 mb-3 mb-md-5 d-flex flex-column align-items-center"
        >
            <img
                className="img-fluid product-images"
                src={
                    resellerProduct?.strFilePathForImage === null
                        ? defaultTyre
                        : `data:image/png;base64,${resellerProduct?.strFilePathForImage}`
                }
                alt="Tyre"
            />
            <div className="tyre-text my-2 ml-1">
                <h4 className="tyre-brand">{resellerProduct?.brand}</h4>
                <h3 className="tyre-size mt-1">
                    {resellerProduct?.strTyreSize +
                        " " +
                        resellerProduct?.strTreadPattern}
                </h3>
                <div className="stock-price">
                    {productWithStock !== null
                        ? "R" + productWithStock.dblItemPrice
                        : "R -"}
                </div>
            </div>
            <div className="stock-status">
                <StockStatus
                    quantity={
                        resellerProduct.productResellersList?.filter(
                            (product) => product.iProductQuantity > 0
                        )[0]?.iProductQuantity
                    }
                />
            </div>
            <button
                className="product-more-details-btn"
                onClick={() => {
                    handleClickOpenModal(resellerProduct);
                }}
            >
                BUY NOW
            </button>
        </div>
    );
}

export default ProductCard;
