import { useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../../../Utility/ApiUrlPath";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { isObjEmpty } from "../../../../Utility/Utils";

toast.configure();

const useForm = (
    validate,
    forgotPasswordGuid,
    setIsLoading,
    setHasErrorsFetchingReset,
    setResetSuccess
) => {
    const [values, setValues] = useState({ password: null, password2: null });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        //var userNewPassword = values.password;

        var resetUserPassword = {
            forgotPasswordGuid: forgotPasswordGuid,
            newPassword: values.password,
        };

        const errorsObj = validate(values);
        setErrors(errorsObj);

        if (isObjEmpty(errorsObj)) {
            (async () => {
                try {
                    setIsLoading(true);

                    const response = await fetch(
                        `${API.QA_URL}api/v1/users/password_reset`,
                        {
                            body: JSON.stringify(resetUserPassword),
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "minestar-api-token": "xyz",
                            },
                        }
                    );

                    const responseJson = await response.json();

                    if (responseJson.bResetPassword) {
                        setResetSuccess(true);
                    } else {
                        setHasErrorsFetchingReset(true);
                    }
                    setIsLoading(false);
                } catch (e) {
                    setIsLoading(false);
                    setHasErrorsFetchingReset(true);
                }
            })();
        }
    };

    return { handleChange, handleSubmit, values, errors };
};

export default useForm;
