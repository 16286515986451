/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { API } from "../../../../Utility/ApiUrlPath";
import AddressForm from "./AddressForm";
// import AddressForm from "../../../../Components/Forms/AddressForm/AddressForm";
import DeliveryAddressList from "./Address/DeliveryAddressList/DeliveryAddressList";
import "./DeliveryAddress.scss";

import { UserContext } from "../../../../Contexts/user.context";

import "../../../../Components/Errors/Errors.scss";
// import MinestarLoader from "../../../../Assests/Animations/Minestar-Loader.gif";
import MinestarLoader from "../../../../Assests/Animations/Minestar-Loader-Small.gif";
import DeliveryAddressListMobile from "./DeliveryAddressListMobile/DeliveryAddressListMobile";

const DeliveryAddress = ({ setFinalAddress }) => {
    const { user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingAddresses, setHasErrorFetchingAddresses] =
        useState(false);

    const [active, setActive] = useState(() => "AddressList");
    const [currentSelectedAddress, setCurrentSelectedAddress] = useState(null);
    const [addressList, setAddressList] = useState([]);

    /**
     * Fetch all user addresses.
     * @returns List of the associated user's addresses.
     */
    const fetchUserAddresses = async () => {
        const abortController = new AbortController();
        try {
            setIsLoading(true);
            setHasErrorFetchingAddresses(false);
            const data = await fetch(
                `${API.QA_URL}api/v1/users/get_single_userinformation?gGuid=${user?.userGuid}`,
                {
                    method: "GET",
                    mode: Request.mode,
                    headers: {
                        "Content-Type": "application/json",
                        "minestar-api-token": "xyz",
                    },
                    signal: abortController.signal,
                }
            );

            const responseJson = await data.json();
            if (responseJson.userInformationList?.$values.length > 0) {
                const addressList = responseJson.userInformationList.$values;
                const populatedList = addressList.map((address) => {
                    return {
                        id: address.$id,
                        iUserInformationId: address.iUserInformationId,
                        iUserId: user.id,
                        strCountry: address.strCountry ?? "",
                        strAddressLineOne: address.strAddressLineOne ?? "",
                        strAddressLineTwo: address.strAddressLineTwo ?? "",
                        strProvince: address.strProvince ?? "",
                        strCity: address.strCity ?? "",
                        strPostalCode: address.strPostalCode ?? "",
                        bIsDefault: address.bIsDefault ?? false,
                        strAddressTitle: address.strAddressTitle ?? "",
                    };
                });
                setAddressList(populatedList);
                const defaultAddress = populatedList.find(
                    (address) => address.bIsDefault
                );
                setCurrentSelectedAddress(defaultAddress);
                setFinalAddress(defaultAddress);
                setIsLoading(false);
                setHasErrorFetchingAddresses(false);
            }
        } catch (error) {
            setIsLoading(false);
            setHasErrorFetchingAddresses(true);
            console.error(error);
            // toast.error("Address list could not be loaded.", {
            //     autoClose: toastrTime,
            // });
        }

        return () => abortController.abort();
    };

    // get address info for user:
    useEffect(() => {
        if (active === "AddressList") {
            fetchUserAddresses();
        }
    }, [active]);

    return (
        <div id="deliver-address-list-container" className="mt-2 mt-md-4">
            {active === "AddressList" && (
                <>
                    {!isLoading &&
                    !hasErrorFetchingAddresses &&
                    addressList.length > 0 ? (
                        <>
                            <div className="row mx-0 mb-md-3">
                                <h2 className="col-12 col-md-8 px-0 ml-md-4 delivery-heading">
                                    Select Delivery Address
                                </h2>
                                <button
                                    className="col-12 col-md-3 ml-1 mb-2 add-delivery-address-btn secondary-outline-btn"
                                    onClick={() => {
                                        setActive("EditAddress");

                                        setAddressList([]);
                                        setCurrentSelectedAddress(null);
                                        setFinalAddress(null);
                                    }}
                                >
                                    Add Address
                                </button>
                                <div className="delivery-address-list-mobile">
                                    <DeliveryAddressListMobile
                                        addressList={addressList}
                                        currentSelectedAddressState={{
                                            currentSelectedAddress,
                                            setCurrentSelectedAddress,
                                        }}
                                        setFinalAddress={setFinalAddress}
                                    />
                                </div>
                            </div>

                            <div className="delivery-address-list">
                                <DeliveryAddressList
                                    addressList={addressList}
                                    currentSelectedAddressState={{
                                        currentSelectedAddress,
                                        setCurrentSelectedAddress,
                                    }}
                                    setFinalAddress={setFinalAddress}
                                />
                            </div>
                        </>
                    ) : isLoading ? (
                        <div className="notFoundContent my-3 my-md-5">
                            <div className="d-flex flex-column align-items-center">
                                <img
                                    className="my-2"
                                    src={MinestarLoader}
                                    alt="Loading..."
                                    height={200}
                                    width={200}
                                />
                                <p
                                    className="not-found-text"
                                    style={{ color: "#e50119" }}
                                >
                                    Loading Addresses...
                                </p>
                            </div>
                        </div>
                    ) : (
                        hasErrorFetchingAddresses && (
                            <div className="notFoundContent my-2 my-md-5 d-flex align-items-center justify-content-center">
                                <p
                                    className="not-found-text mx-4"
                                    style={{ color: "#e50119" }}
                                >
                                    Sorry, we could not retrieve your addresses
                                    at this time.
                                    <br />
                                    Please try again later.
                                </p>
                            </div>
                        )
                    )}
                </>
            )}
            {active === "EditAddress" && <AddressForm setActive={setActive} />}
        </div>
    );
};

export default DeliveryAddress;
