import React from "react";
import "./Construction.scss";
import logo from "./Assests/Icons/logoOnly.png";

function Construction() {
    return (
        <div id="construction-container">
            <div id="construction-content">
                <div id="logo-container">
                    <img src={logo} alt="logo" />
                </div>
                <div id="text">
                    <h1>UNDER CONSTRUCTION</h1>
                </div>
            </div>
        </div>
    );
}

export default Construction;
