import React from "react";
import one from "../../../Assests/Instructions/1.png";
import two from "../../../Assests/Instructions/2.png";
import wrong from "../../../Assests/Instructions/wrong.png";
import right from "../../../Assests/Instructions/right.png";

import "./AddStockInstructions.scss";

import { useNavigate } from "react-router-dom";

function AddStockInstructions() {
    const navigate = useNavigate();
    return (
        <div className="add-stock-instructions-container px-2 py-3">
            <div className="px-3">
                <h3 className="mb-0">Step 1</h3>
                <div className="row ml-2 align-items-center">
                    <p>Click on the corresponding</p>{" "}
                    <img
                        className="mx-2"
                        src={one}
                        width={30}
                        height={30}
                        alt=""
                    />{" "}
                    <p> button of the stock item you want to add.</p>
                </div>
            </div>
            <div className="px-3 mt-3">
                <h3>Step 2</h3>
                <div className="row ml-2 align-items-end">
                    <p>
                        On the next page fill out the form with your stock
                        item's information.
                        <span className="d-block">
                            <strong>Note</strong> - Don't forget to specify if
                            the item you're trying to add is used.
                        </span>
                    </p>
                </div>
            </div>
            <div className="px-3 mt-3">
                <h3>Step 3</h3>
                <div className="row ml-2 align-items-center">
                    <p>Click the</p>{" "}
                    <img
                        className="mx-2"
                        src={two}
                        width={100}
                        height={30}
                        alt=""
                    />
                    <p>
                        {" "}
                        button when you're done filling out the form to add your
                        stock item.
                    </p>
                </div>
            </div>
            <div className="px-3 mt-3">
                <h3>Success</h3>
                <div className="row ml-2 flex-column">
                    <p>
                        If your stock item has been received you will see the
                        following notification:{" "}
                    </p>{" "}
                    <img
                        className="mx-2"
                        src={right}
                        width={300}
                        height={30}
                        alt=""
                    />
                </div>
            </div>
            <div className="px-3 mt-3">
                <h3>Failure</h3>
                <div className="row ml-2 flex-column">
                    <p>
                        If something went wrong with receiving your stock item
                        you will see the following notification:{" "}
                    </p>
                    <img
                        className="d-block mx-2 p-2"
                        src={wrong}
                        width={300}
                        height={30}
                        alt=""
                    />
                    <p>
                        <strong>Note</strong> - If adding your stock item was
                        unsuccessful please make sure that you are not trying to
                        add a duplicate of a stock item that you already added.{" "}
                        <span
                            className="click-here"
                            onClick={() => navigate("/accountsettings/mystock")}
                        >
                            Click Here
                        </span>{" "}
                        to go through your existing stock.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default React.memo(AddStockInstructions);
