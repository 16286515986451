export default function ValidateAddressInfo(values) {
    const errors = {};

    if (!values.strAddressLineOne) {
        errors.strAddressLineOne = "Address Line 1 is required.";
    } else if (values.strAddressLineOne.trim().length < 2) {
        errors.strAddressLineOne = "Address Line 1 is a little too short.";
    }

    if (!values.strCity) {
        errors.strCity = "City is required.";
    } else if (values.strCity.trim().length < 2) {
        errors.strCity = "City name is a little too short.";
    }

    if (!values.strProvince) {
        errors.strProvince = "Province is required.";
    } else if (values.strProvince.trim().length < 2) {
        errors.strProvince = "Province name is a little too short.";
    }

    if (!values.strCountry) {
        errors.strCountry = "Country is required.";
    } else if (values.strCountry.trim().length < 2) {
        errors.strCountry = "Country name is a little too short.";
    }

    if (!values.strPostalCode) {
        errors.strPostalCode = "Postal Code is required.";
    } else if (values.strPostalCode.trim().length !== 4) {
        errors.strPostalCode = "Postal Code must be 4 digits long.";
    }

    return errors;
}
