import { useEffect, useState } from "react";

const useTyreImage = (brandName, fileName) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import(
                    `../../Assests/Tyres/${brandName
                        ?.trim()
                        .toUpperCase()}/${fileName?.trim()}`
                );
                setImage(response.default);
            } catch (err) {
                setLoading(false);
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        if (fileName?.includes(".png")) {
            fetchImage();
        }
    }, [brandName, fileName]);

    if (!fileName || !fileName?.includes(".png"))
        return { loading: false, error: null, image: null };
    return {
        loading,
        error,
        image,
    };
};

export default useTyreImage;
