import React, { useState, useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { API } from "../../Utility/ApiUrlPath";

// Importing components
import OrderDone from "../CheckoutProcess/OrderDone/OrderDone";
// import MinestarLoader from "../../Assests/Animations/Minestar-Loader.gif";
import MinestarLoader from "../../Assests/Animations/Minestar-Loader-Small.gif";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../SEO/SEONoIndex";

function OzowRedirectComponent({ status = "cancel" }) {
    let [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(true);
    const [currentStatus, setCurrentStatus] = useState(status); // Indicates the current status from the url and after receiving a response from the server.

    const guid = searchParams.get("guid");
    const transactionStatus = searchParams.get("transactionStatus");
    const referenceNumber = searchParams.get("referenceNumber");

    useEffect(() => {
        const abortController = new AbortController();

        (async () => {
            try {
                const response = await fetch(
                    `${API.QA_URL}api/v1/order/get_payment_response?guid=${guid}&transactionStatus=${transactionStatus}`,
                    {
                        method: "GET",
                        mode: Request.mode,
                        headers: {
                            "Content-Type": "application/json",
                            "minestar-api-token": "xyz",
                        },
                        signal: abortController.signal,
                    }
                );

                const json = await response.json();
                if (json !== undefined && json !== null) {
                    if (json === 10) {
                        setCurrentStatus("success");
                    } else {
                        setCurrentStatus("error");
                    }
                    setIsLoading(false);
                }
            } catch (error) {
                console.error(error);
                setCurrentStatus("error");
                setIsLoading(false);
            }
        })();

        return () => abortController.abort();
    }, []);

    /**
     * Renders the correct component based on the status prop.
     */
    function handlePaymentStatus() {
        switch (currentStatus) {
            case "success":
                return (
                    <OrderDone
                        referenceNumber={referenceNumber}
                        orderState={currentStatus}
                    />
                );
            case "error":
                return (
                    <OrderDone
                        referenceNumber={referenceNumber}
                        orderState={currentStatus}
                    />
                );
            case "cancel":
                return (
                    <OrderDone
                        referenceNumber={referenceNumber}
                        orderState={currentStatus}
                    />
                );
            default:
                <Navigate to="/" replace />;
        }
    }

    return (
        <div>
            <SEONoIndex title="Redirecting | Minestar South Africa" />
            {guid === null || guid === "" ? (
                <Navigate to="/" replace />
            ) : isLoading ? (
                <div className="notFoundContent my-2 my-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="my-5"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={200}
                            width={200}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Please stay on this page while we process your
                            order...
                        </p>
                    </div>
                </div>
            ) : (
                handlePaymentStatus()
            )}
        </div>
    );
}

export default OzowRedirectComponent;
