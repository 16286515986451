import { combineReducers } from "redux";
import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import thunk from "redux-thunk";
import cartReducer from "./Features/Cart/cartSlice";

const persistConfig = {
    key: "root",
    storage,
};

// Add all reducers, that need to persist data, here:
const reducers = combineReducers({
    cart: cartReducer,
});

const persistedRootReducer = persistReducer(persistConfig, reducers);

export const store = configureStore(
    {
        reducer: persistedRootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                    ],
                },
            }),
        // To disable devtools in production
        devTools: process.env.NODE_ENV !== "production",
    },
    applyMiddleware(thunk)
);

export const persistor = persistStore(store);
