import React, { useState, useEffect } from "react";
import { API } from "../../../Utility/ApiUrlPath";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestForm from "../../Forms/RequestForm/RequestForm";
import useTyreImage from "../../../Utility/hooks/useTyreImage";
import defaultTyre from "../../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";
import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";
import { isObjEmpty } from "../../../Utility/Utils";
import RenderPdfDownloads from "../Downloads/RenderPdfDownloads";

// Import Custom Functions
import RemoveBrandFromProductName from "../../../Utility/Functions/RemoveBrandFromProductName";
import GetAllProducts from "../../../Utility/Functions/GetAllProducts";
import PriceSplitter from "../../../Utility/Functions/PriceSplitter";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
    selectCart,
    addItem,
    changeQty,
} from "../../../Redux/Features/Cart/cartSlice";

// Modal
import ProductQuantityControl from "../../ProductQuantityControl/ProductQuantityControl";

function SlimResellerProduct({ product, onCloseExternal, onCloseProduct }) {
    const { loading, error, image } = useTyreImage(
        product?.brand,
        product?.imageName
    );
    const [bidModalVisibility, setBidModalVisibility] = useState(false);

    const [extarnalRadioOption, setExternalRadioOption] = useState(
        "external-specifications"
    ); // Other options is "downloads"
    const handleExternalRadioChange = (event) => {
        setExternalRadioOption(event.target.value);
    };

    const cart = useSelector(selectCart);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleGoToCart = () => {
        navigate("/cart");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setBidModalVisibility(false);
    };

    const [productDetails, setProductDetails] = useState({});
    const [mainProduct, setMainProduct] = useState({});
    const [imageBase64, setImageBase64] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingProducts, setHasErrorFetchingProducts] =
        useState(false);

    /**
     * Fetch Product details from API.
     */
    useEffect(() => {
        const abortController = new AbortController();
        setIsLoading(true);
        setHasErrorFetchingProducts(false);
        if (
            product &&
            !isObjEmpty(product) &&
            product.iProductResellerId &&
            product.productGuid
        ) {
            (async () => {
                try {
                    const slimProduct = await GetAllProducts(
                        `${API.QA_URL}api/v1/products/get_slim_reseller_product?productGuid=${product.productGuid}&iProductResellerId=${product.iProductResellerId}`,
                        abortController
                    );

                    if (
                        slimProduct !== null &&
                        slimProduct?.status !== 400 &&
                        !isObjEmpty(slimProduct)
                    ) {
                        if (!slimProduct.bIsConditionNew) {
                            try {
                                const response = await fetch(
                                    `${API.QA_URL}api/v1/product_reseller/product_reseller_image?brandName=${slimProduct.brand}&treadPattern=${slimProduct.treadPattern}&iProductResellerId=${slimProduct.iProductResellerId}`,
                                    {
                                        method: "GET",
                                        mode: Request.mode,
                                        headers: {
                                            "Content-Type": "application/json",
                                            "minestar-api-token": "xyz",
                                        },
                                    }
                                );

                                if (
                                    response &&
                                    response.status >= 200 &&
                                    response.status < 300
                                ) {
                                    const image = await response.json();
                                    if (
                                        image?.productResellerImage64 !==
                                            null &&
                                        image.productResellerImage64 !== ""
                                    ) {
                                        setImageBase64(
                                            image.productResellerImage64
                                        );
                                    }
                                }
                            } catch (error) {}
                        }

                        slimProduct && setProductDetails(slimProduct);
                        slimProduct && setMainProduct(slimProduct);

                        setIsLoading(false);
                        setHasErrorFetchingProducts(false);
                    } else {
                        setProductDetails({});
                        setIsLoading(false);
                        setHasErrorFetchingProducts(true);
                    }
                } catch (error) {
                    setProductDetails({});
                    setIsLoading(false);
                    setHasErrorFetchingProducts(true);
                }
            })();
        }

        setIsLoading(false);

        return () => abortController.abort();
    }, [product]);

    // Product quantity controls
    const [qty, setQty] = useState(0); // Start off with 1 item to add to cart.

    const handleIncrement = (currentProduct) => {
        if (qty + 1 <= currentProduct.quantity) {
            setQty(qty + 1);
            dispatch(changeQty(currentProduct.iProductResellerId, qty + 1));
        }
    };
    const handleDecrement = (currentProduct) => {
        if (qty > 0) {
            setQty(qty - 1);
            dispatch(changeQty(currentProduct.iProductResellerId, qty - 1));
        }
    };
    const handleChange = (e, currentProduct) => {
        if (e.target.value <= currentProduct.quantity) {
            setQty(e.target.value);
            dispatch(
                changeQty(currentProduct.iProductResellerId, e.target.value)
            );
        }
    };

    const displayCategories = (categories) => {
        // categories is a list of objects: [({name: "Mining"})]
        return (
            <div>
                {categories?.map((category, index) => {
                    return (
                        <div key={index}>
                            {category.name} <br />
                        </div>
                    );
                })}
            </div>
        );
    };

    const AddToCart = (e, currentCartItem) => {
        e.preventDefault();

        if (qty > 0) {
            if (cart.cartList?.length > 0) {
                const alreadyInCart = cart.cartList.filter(
                    (x) =>
                        x.iProductResellerId ===
                        currentCartItem.iProductResellerId
                );
                if (alreadyInCart?.length === 0) {
                    dispatch(
                        addItem(
                            currentCartItem,
                            qty,
                            currentCartItem?.imageName
                        )
                    );
                    onCloseExternal();
                    onCloseProduct();
                    toast.success(
                        ({ closeToast }) => (
                            <div
                                className="go-to-cart-btn"
                                onClick={handleGoToCart}
                            >
                                Go to Cart
                            </div>
                        )
                        // {
                        // autoClose: toastrTime,
                        // }
                    );
                } else {
                    toast.error(
                        ({ closeToast }) => (
                            <div
                                className="go-to-cart-btn"
                                onClick={handleGoToCart}
                            >
                                Already in Cart
                            </div>
                        ),
                        {
                            autoClose: 1000,
                        }
                    );
                }
            } else {
                dispatch(
                    addItem(currentCartItem, qty, currentCartItem?.imageName)
                );
                onCloseExternal();
                onCloseProduct();
                toast.success(
                    ({ closeToast }) => (
                        <div
                            className="go-to-cart-btn"
                            onClick={handleGoToCart}
                        >
                            Go to Cart
                        </div>
                    ),
                    {
                        // autoClose: toastrTime,
                    }
                );
            }
        } else {
            toast.error(({ closeToast }) => <>Quantity cannot be zero</>, {
                autoClose: 2000,
            });
        }
    };

    return !isLoading &&
        !hasErrorFetchingProducts &&
        productDetails &&
        !isObjEmpty(productDetails) ? (
        <>
            <div className="productDetails d-flex justify-content-center align-items-center">
                <div className="detailsContent">
                    <div className="productPricing d-flex flex-column align-items-center justify-content-center px-sm-3 py-3 py-md-5">
                        <div className="productDetailsContainer">
                            {!mainProduct || isObjEmpty(mainProduct) ? (
                                <>
                                    <div className="productTitle row mx-0">
                                        <span className="col-12 px-0">
                                            {productDetails?.tyreSize +
                                                " " +
                                                productDetails?.treadPattern}
                                        </span>
                                    </div>

                                    <span className="productBrand">
                                        {productDetails?.brand}
                                    </span>

                                    <span className="productStock">
                                        0 IN STOCK
                                    </span>
                                </>
                            ) : (
                                <>
                                    <div className="productTitle row mx-0">
                                        <h2 className="col-12 px-0">
                                            {RemoveBrandFromProductName(
                                                mainProduct?.productName
                                            )}
                                        </h2>
                                    </div>

                                    <h1 className="productBrand">
                                        {mainProduct?.brand}
                                    </h1>

                                    <span className="productStock m-1 d-flex align-items-center">
                                        {mainProduct?.quantity} IN STOCK
                                    </span>

                                    <h3 className="productPrice m-1">
                                        R{" "}
                                        {PriceSplitter(
                                            mainProduct?.sellingPrice
                                        )}
                                    </h3>

                                    <div className="cart-item-qty-control">
                                        <ProductQuantityControl
                                            qty={qty}
                                            handleDecrement={() =>
                                                handleDecrement(mainProduct)
                                            }
                                            handleIncrement={() =>
                                                handleIncrement(mainProduct)
                                            }
                                            handleChange={(e) =>
                                                handleChange(e, mainProduct)
                                            }
                                            cartItem={mainProduct}
                                        />
                                    </div>

                                    <button
                                        key={mainProduct?.iProductResellerId}
                                        className="addToCartBtn m-sm-1"
                                        onClick={(e) => {
                                            AddToCart(e, mainProduct);
                                        }}
                                    >
                                        ADD TO CART
                                    </button>
                                    <button
                                        className="bidOfferBtn m-sm-1"
                                        onClick={() =>
                                            setBidModalVisibility(
                                                !bidModalVisibility
                                            )
                                        }
                                    >
                                        {!bidModalVisibility
                                            ? "MAKE AN OFFER"
                                            : "CANCEL OFFER"}
                                    </button>
                                </>
                            )}
                            <div className="productImage">
                                <img
                                    className="productDetailImage"
                                    src={
                                        loading || error !== null
                                            ? defaultTyre
                                            : !isObjEmpty(imageBase64) &&
                                              imageBase64 !== null &&
                                              imageBase64 !== ""
                                            ? "data:image/png;base64, " +
                                              imageBase64
                                            : image !== null
                                            ? image
                                            : defaultTyre
                                    }
                                    alt="Tyre"
                                />
                            </div>
                        </div>
                        <div>
                            {bidModalVisibility && (
                                <div className="modal">
                                    <form
                                        className="row biddingFormInputs mt-3 mb-3"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="col-12 col-md-7">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter amount"
                                            />
                                        </div>
                                        <div className="col-12 col-md-5 row mx-0 mt-2 mt-md-0">
                                            <button
                                                className="bidNowBtn add-address-btn col-12"
                                                type="submit"
                                            >
                                                Make Offer
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="productSpecification">
                        <div className="tabs mb-0">
                            <div className="tab-2">
                                <label
                                    htmlFor="external-tab2-1"
                                    className={
                                        extarnalRadioOption ===
                                        "external-specifications"
                                            ? "tabNav active-radio-btn"
                                            : "tabNav"
                                    }
                                >
                                    SPECIFICATIONS
                                </label>
                                <input
                                    id="external-tab2-1"
                                    name="external-tabs-two"
                                    type="radio"
                                    value="external-specifications"
                                    checked={
                                        extarnalRadioOption ===
                                        "external-specifications"
                                    }
                                    onChange={handleExternalRadioChange}
                                />
                                <div className="tyreSpecifications p-1 px-md-4">
                                    {productDetails?.brand !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Brand</p>
                                            <span>{productDetails?.brand}</span>
                                        </div>
                                    )}

                                    {productDetails?.tyreSize !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Size</p>
                                            <span>
                                                {productDetails?.tyreSize}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.treadPattern !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Tread pattern</p>
                                            <span>
                                                {productDetails?.treadPattern}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.sectionalWidth !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Sectional Width</p>
                                            <span>
                                                {productDetails?.sectionalWidth +
                                                    " mm"}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.overallDiameter !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Overall Diameter</p>
                                            <span>
                                                {productDetails?.overallDiameter +
                                                    " mm"}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.treadDepth !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Tread depth</p>
                                            <span>
                                                {productDetails?.treadDepth +
                                                    " mm"}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.weight !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Weight</p>
                                            <span>
                                                {productDetails?.weight}
                                                {" kg"}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.TKPH !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>TKPH</p>
                                            <span>{productDetails?.TKPH}</span>
                                        </div>
                                    )}

                                    {productDetails?.bIsConditionNew !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Condition</p>
                                            <span>
                                                {productDetails?.bIsConditionNew
                                                    ? "New"
                                                    : "2nd Hand"}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.categories !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Application</p>
                                            <span className="category-list">
                                                {productDetails?.categories &&
                                                    displayCategories(
                                                        productDetails?.categories
                                                    )}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.compound !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Compound</p>
                                            <span>
                                                {productDetails?.compound}
                                            </span>
                                        </div>
                                    )}

                                    {productDetails?.plyRating !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Ply Rating/Star Rating</p>
                                            <span>
                                                {productDetails?.plyRating}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tab-2">
                                <label
                                    htmlFor="external-tab2-2"
                                    className={
                                        extarnalRadioOption === "downloads2"
                                            ? "tabNav active-radio-btn"
                                            : "tabNav"
                                    }
                                >
                                    DOWNLOADS
                                </label>
                                <input
                                    id="external-tab2-2"
                                    name="external-tabs-two"
                                    type="radio"
                                    value="downloads2"
                                    checked={
                                        extarnalRadioOption === "downloads2"
                                    }
                                    onChange={handleExternalRadioChange}
                                />
                                <RenderPdfDownloads
                                    brandName={productDetails.brand}
                                    brandId={productDetails.brandId}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="banner"></div>
                    <div className="request-form px-2 px-md-4">
                        {/* Request More Information */}
                        <RequestForm
                            productName={`${productDetails?.brand} ${productDetails?.tyreSize} ${productDetails?.treadPattern}`}
                        />
                    </div>
                </div>
            </div>
        </>
    ) : isLoading ? (
        <div className="notFoundContent my-3 my-md-5">
            <div className="d-flex flex-column align-items-center">
                <img
                    className="my-2"
                    src={MinestarLoader}
                    alt="Loading..."
                    height={200}
                    width={200}
                />
                <p className="not-found-text" style={{ color: "#e50119" }}>
                    Loading External Product...
                </p>
            </div>
        </div>
    ) : (
        hasErrorFetchingProducts && (
            <div className="notFoundContent my-2 my-md-5 d-flex align-items-center justify-content-center">
                <p className="not-found-text mx-4" style={{ color: "#e50119" }}>
                    Sorry, we could not retrieve this external product's details
                    at this time.
                    <br />
                    Please try again later.
                </p>
            </div>
        )
    );
}

export default SlimResellerProduct;
