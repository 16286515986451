import React, { useState, useEffect, useContext, useRef } from "react";
import { API } from "../../Utility/ApiUrlPath";
import { UserContext } from "../../Contexts/user.context";
import { toast } from "react-toastify";
import { toastrTime } from "../../Utility/Constants/ToastrTime";

import MinestarLoader from "../../Assests/Animations/Minestar-Loader-Small.gif";

// Import CSS
import "./Checkout.scss";

import { Navigate, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import DeliveryOptions from "./DeliveryOptions/DeliveryOptions";
import DeliverAddress from "./Delivery/DeliveryAddress/DeliverAddress";
import CollectAddress from "./Delivery/CollectAddress/CollectAddress";
import Payment from "./Payment/Payment";
import Review from "./Review/Review";
import TermsConditionsToggle from "./Review/TermsConditionsToggle";
import TermsAndConditions from "../LegalDocs/TermsAndConditions";

// Modal Component
import CommonDialog from "../Dialog/CommonDialog";

// Import Utils
import { scrollToSection } from "../../Utility/Utils";

import {
    DELIVER_OPTION,
    COLLECT_OPTION,
} from "../../Utility/Constants/DeliveryOptions";

import { OZOW_PAYMENT } from "../../Utility/PaymentOptions";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
    selectCart,
    setActiveAddress,
    setDeliveryMethod,
    setPaymentMethod,
    resetCart,
} from "../../Redux/Features/Cart/cartSlice";

import { isObjEmpty } from "../../Utility/Utils";

import Decimal from "decimal.js-light"; // http://mikemcl.github.io/decimal.js-light/
Decimal.set({
    rounding: Decimal.ROUND_HALF_UP,
});

const steps = [
    "Delivery Method",
    "Select Address",
    "Payment Options",
    "Summary",
];

function getStepContent(
    step,
    finalAddressState,
    finalPaymentOptionState,
    activeStepState,
    deliveryOptionState,
    isOrderProcessCompleteState,
    isSummaryDisplayedState
) {
    // eslint-disable-next-line default-case
    switch (step) {
        case 0: {
            // Delivery Option/Method
            isSummaryDisplayedState.setIsSummaryDisplayed(false);
            return (
                <DeliveryOptions
                    activeStepState={activeStepState}
                    deliveryOptionState={deliveryOptionState}
                />
            );
        }
        case 1: {
            // Address
            isSummaryDisplayedState.setIsSummaryDisplayed(false);
            return (
                <div>
                    {deliveryOptionState.deliveryOption === DELIVER_OPTION ? (
                        <DeliverAddress
                            setFinalAddress={
                                finalAddressState.setCurrentAddress
                            }
                        />
                    ) : deliveryOptionState.deliveryOption ===
                      COLLECT_OPTION ? (
                        <CollectAddress
                            setFinalAddress={
                                finalAddressState.setCurrentAddress
                            }
                        />
                    ) : (
                        <>Something went wrong. Please try again later.</>
                    )}
                </div>
            );
        }
        case 2: {
            // Payment Option
            isSummaryDisplayedState.setIsSummaryDisplayed(false);
            return (
                <Payment
                    setFinalPaymentOption={
                        finalPaymentOptionState.setCurrentSelectedPaymentOption
                    }
                />
            );
        }
        case 3: {
            // Summary
            isSummaryDisplayedState.setIsSummaryDisplayed(true);
            return (
                <>
                    <Review
                        finalAddress={finalAddressState.currentAddress}
                        // setIsPlacingOrder={setIsPlacingOrder}
                        deliveryOption={deliveryOptionState.deliveryOption}
                        isOrderProcessCompleteState={
                            isOrderProcessCompleteState
                        }
                        isSummaryDisplayedState={isSummaryDisplayedState}
                    />
                </>
            );
        }
    }
}

const theme = createTheme({
    typography: {
        fontSize: 26,
        fontFamily: ["'Montserrat'", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#e50019",
        },
    },
});

export default function Checkout({
    setActive,
    deliveryOption,
    setDeliveryOption,
    isOrderProcessCompleteState,
    isSummaryDisplayedState,
}) {
    const cart = useSelector(selectCart);
    const dispatch = useDispatch();

    const currentSection = useRef(null);

    const { user } = useContext(UserContext);

    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [hasCheckboxError, setHasCheckboxError] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);
    const handleClickOpenTerms = () => {
        setOpenTerms(true);
    };
    const handleClickCloseTerms = () => {
        setOpenTerms(false);
    };

    const [currentAddress, setCurrentAddress] = useState(null);
    const [currentSelectedPaymentOption, setCurrentSelectedPaymentOption] =
        useState("");

    const [activeStep, setActiveStep] = useState(0);

    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const [orderStatus, setOrderStatus] = useState(false);

    const handleNext = () => {
        switch (activeStep) {
            // Choose Delivery/Collection (no validation needed)
            case 0:
                setActiveStep(activeStep + 1);
                break;

            // Choose specific address (currentAddress can't be null)
            case 1:
                if (currentAddress !== null) {
                    setActiveStep(activeStep + 1);
                } else {
                    toast.error("Please choose an address.", {
                        autoClose: toastrTime,
                    });
                }
                break;

            // Choose specific payment method
            case 2:
                if (!isObjEmpty(currentSelectedPaymentOption)) {
                    setActiveStep(activeStep + 1);
                } else {
                    toast.error("Please choose a payment option.", {
                        autoClose: toastrTime,
                    });
                }
                break;
            default:
                setActiveStep(0);
        }
    };

    const handleBack = () => {
        const step = activeStep - 1;
        setActiveStep(step);
        switch (step) {
            case 0: // "Delivery"
                // Moved from Select Address Back to Delivery.
                // Reset address chosen.
                setCurrentAddress(null);
                dispatch(setActiveAddress(null));
                dispatch(setDeliveryMethod(null));
                break;
            case 1: // "Select Address"
                // Moved from Payment Options back to Select Address.
                // Reset payment option
                setCurrentSelectedPaymentOption([]);
                dispatch(setPaymentMethod(null));
                break;
            case 2: //"Payment"
                break;
            default:
                return null;
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setActiveAddress(currentAddress));
    }, [currentAddress, dispatch]);

    useEffect(() => {
        dispatch(setPaymentMethod(currentSelectedPaymentOption));
    }, [currentSelectedPaymentOption, dispatch]);

    // Redirects to Ozow to handle the payment.
    function handleExternalRedirect(url) {
        window.location.replace(url);
    }

    async function handleOrder(setIsPlacingOrder) {
        if (user && cart?.activeAddress) {
            // Populate the orderItems that are going to be sent to the backend
            const items = cart.cartList.map((cartItem) => ({
                iProductId: cartItem.iProductId,
                iProductResellerId: cartItem.iProductResellerId,
                iBuyerQuantity: cartItem.iBuyerQuantity,
            }));

            let totalCost = new Decimal(cart.total);
            totalCost = totalCost.plus(cart.transactionFee).toNumber();

            const order = {
                order: {
                    iOrderStatus: 0,
                    userGuid: user.userGuid,
                    iUserId: parseInt(user.id),
                    iUserInformationId: parseInt(
                        cart.activeAddress.iUserInformationId
                    ),
                    strTrackingLink: "no link provided.",
                    dblTotalCost: totalCost,
                    bIsDeliver:
                        cart.deliveryMethod === DELIVER_OPTION ? true : false,
                    strPaymentMethod: cart.paymentOption,
                    bIsPaymentAccepted: false,
                    orderItems: [...items],
                },
            };

            try {
                setIsPlacingOrder(true);

                const response = await fetch(
                    `${API.QA_URL}api/v1/order/create`,
                    {
                        method: "POST",
                        body: JSON.stringify(order),
                        mode: Request.mode,
                        headers: {
                            "Content-Type": "application/json",
                            "minestar-api-token": "xyz",
                        },
                    }
                );

                debugger;

                if (response.status >= 400 && response.status <= 499) {
                    setOrderStatus(false);
                    setIsPlacingOrder(false);
                    setActive("cart");
                    toast.error(
                        "Order could not be processed successfully. Please try again.",
                        {
                            autoClose: toastrTime,
                        }
                    );
                } else if (response.status >= 200 && response.status <= 299) {
                    const responseJson = await response.json();

                    if (responseJson.responseMessage === 1) {
                        if (responseJson.paymentResponse !== null) {
                            dispatch(resetCart());
                            setOrderStatus(false);
                            if (cart.paymentOption === OZOW_PAYMENT) {
                                handleExternalRedirect(
                                    responseJson.paymentResponse
                                );
                            } else {
                                navigate(
                                    `/payment/ozow/success?guid=${responseJson.orderGuid}&transactionStatus=0&referenceNumber=${responseJson.iOrderNumber}`
                                );
                            }
                            setActive("cart");
                        }
                    } else {
                        setOrderStatus(false);
                        setIsPlacingOrder(false);
                        setActive("cart");

                        toast.error(
                            "Order could not be processed successfully. Please try again.",
                            {
                                autoClose: toastrTime,
                            }
                        );
                    }
                    setActiveStep((prev) => prev + 1);
                    isOrderProcessCompleteState.setIsOrderProcessComplete(true);
                    setIsPlacingOrder(false);
                }
            } catch (error) {
                setOrderStatus(false);
                setIsPlacingOrder(false);
                setActive("cart");
                setActiveStep((prev) => prev + 1);
                isOrderProcessCompleteState.setIsOrderProcessComplete(false);

                toast.error(
                    "Order could not be processed successfully. Please try again.",
                    {
                        autoClose: toastrTime,
                    }
                );
            }
        } else {
            navigate("/login");
            setIsPlacingOrder(false);
        }
    }

    useEffect(() => {
        scrollToSection(currentSection);
    }, [activeStep]);

    useEffect(() => {
        if (isCheckboxSelected) {
            setHasCheckboxError(false);
        }
    }, [isCheckboxSelected]);

    return (
        <ThemeProvider theme={theme}>
            <CommonDialog
                title={"Minestar Terms & Conditions"}
                open={openTerms}
                onClose={handleClickCloseTerms}
            >
                <TermsAndConditions />
            </CommonDialog>

            <Container
                ref={currentSection}
                component="main"
                maxWidth="xl"
                sx={{ px: { xs: 0 } }}
            >
                <Paper
                    variant=""
                    sx={{
                        mb: { xs: 0, md: 3 },
                        py: { xs: 2, md: 3 },
                        px: { xs: 1 },
                    }}
                >
                    <Stepper
                        activeStep={activeStep}
                        sx={{ pb: 1, fontSize: "20px" }}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {isPlacingOrder ? (
                            <div className="d-flex align-items-center justify-content-center mt-2 mt-md-4">
                                <div className="notFoundContent my-2 my-md-5">
                                    <div className="d-flex flex-column align-items-center">
                                        <img
                                            className="my-5"
                                            src={MinestarLoader}
                                            alt="Loading..."
                                            height={200}
                                            width={200}
                                        />
                                        <p
                                            className="not-found-text"
                                            style={{ color: "#e50119" }}
                                        >
                                            Please stay on this page while we
                                            process your order...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div ref={currentSection}>
                                    {getStepContent(
                                        activeStep,
                                        { currentAddress, setCurrentAddress },
                                        {
                                            currentSelectedPaymentOption,
                                            setCurrentSelectedPaymentOption,
                                        },
                                        {
                                            activeStep,
                                            setActiveStep,
                                        },
                                        {
                                            deliveryOption,
                                            setDeliveryOption,
                                        },
                                        isOrderProcessCompleteState,
                                        isSummaryDisplayedState
                                    )}
                                </div>
                                {activeStep === steps.length - 1 && (
                                    <div className="row mx-0 justify-content-end mb-3 mb-md-0">
                                        <TermsConditionsToggle
                                            checkboxState={{
                                                isCheckboxSelected,
                                                setIsCheckboxSelected,
                                            }}
                                            handleClickOpenTerms={
                                                handleClickOpenTerms
                                            }
                                        />
                                        {hasCheckboxError && (
                                            <p className="text-error mr-2">
                                                Please confirm that you have
                                                read the terms & conditions
                                                above.
                                            </p>
                                        )}
                                    </div>
                                )}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    {activeStep === steps.length ? (
                                        <>
                                            {orderStatus === true ? (
                                                <div className="d-flex justify-content-start align-items-center m-4 p-3">
                                                    Please wait to be
                                                    redirected...
                                                </div>
                                            ) : (
                                                <Navigate to="/cart" replace />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {activeStep !== 0 && (
                                                <>
                                                    <Button
                                                        onClick={handleBack}
                                                        color="primary"
                                                        sx={{
                                                            mt: {
                                                                sm: 1,
                                                                md: 3,
                                                            },
                                                            ml: 1,
                                                            fontSize: {
                                                                sm: "16px",
                                                                md: "18px",
                                                            },
                                                        }}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        className="add-address-btn"
                                                        variant="contained"
                                                        onClick={() => {
                                                            if (
                                                                activeStep ===
                                                                steps.length - 1
                                                            ) {
                                                                if (
                                                                    isCheckboxSelected
                                                                ) {
                                                                    handleOrder(
                                                                        setIsPlacingOrder
                                                                    );
                                                                } else {
                                                                    setHasCheckboxError(
                                                                        true
                                                                    );
                                                                }
                                                            } else {
                                                                handleNext();
                                                            }
                                                        }}
                                                        sx={{
                                                            mt: {
                                                                sm: 1,
                                                                md: 3,
                                                            },
                                                            ml: 1,
                                                            fontSize: {
                                                                sm: "16px",
                                                                md: "18px",
                                                            },
                                                            color: "white",
                                                            backgroundColor:
                                                                "#e50019",
                                                        }}
                                                    >
                                                        {activeStep ===
                                                        steps.length - 1
                                                            ? "Place Order"
                                                            : "Next"}
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}
