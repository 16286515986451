import { useState, useEffect, useContext } from "react";
import { API } from "../../../Utility/ApiUrlPath";
import { UserContext } from "../../../Contexts/user.context";

import { isObjEmpty } from "../../../Utility/Utils";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { toast } from "react-toastify";

const ProfileUseForm = (validate, profileValues, updateSuccessStatus) => {
    const { user } = useContext(UserContext);
    const [values, setValues] = useState({
        firstname: profileValues?.firstname,
        lastname: profileValues?.lastname,
        email: profileValues?.email,
        number: profileValues?.number,
        strCompanyName: profileValues?.company,
        strCompanyRegNumber: profileValues?.companyRegNumber,
        strVatNumber: profileValues?.vatNumber,
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setValues(profileValues);
    }, [profileValues]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errorList = validate(values); // This is actually an object though.
        setErrors(errorList);
        if (isObjEmpty(errorList)) {
            var profileInfo = {
                gGuid: user.userGuid,
                strFirstName: values.firstname,
                strLastName: values.lastname,
                strEmailAddress: values.email,
                strContactNumber: values.number,
                strCompanyName: values.company,
                strCompanyRegNumber: values.companyRegNumber,
                strVatNumber: values.vatNumber,
            };

            fetch(`${API.QA_URL}api/v1/users/update`, {
                method: "POST",
                body: JSON.stringify(profileInfo),
                mode: Request.mode,
                headers: {
                    "Content-Type": "application/json",
                    "minestar-api-token": "xyz",
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((responseJson) => {
                    if (responseJson === 2) {
                        updateSuccessStatus();
                        toast.success("Profile updated successfully.", {
                            autoClose: toastrTime,
                        });
                    } else if (responseJson === 5) {
                        toast.error(
                            "Your profile could not be updated at this time.",
                            {
                                autoClose: toastrTime,
                            }
                        );

                        // set edited state
                    } else {
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Profile could not be updated.", {
                        autoClose: toastrTime,
                    });
                });
        }
    };

    return { handleChange, handleSubmit, values, errors };
};

export default ProfileUseForm;
