import { useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../../Utility/ApiUrlPath";
import { isObjEmpty } from "../../../Utility/Utils";

const useForm = (validate, setSentSuccessfully, setIsLoading) => {
    const [values, setValues] = useState({
        name: "",
        email: "",
        company: "",
        phonenumber: "",
        message: "",
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const v = validate(values);
        setErrors(v);
        if (isObjEmpty(v)) {
            const subscriberInfo = {
                ProductName: null,
                ContactName: values?.name,
                Email: values?.email,
                CellNo: values?.phonenumber,
                Message: values?.message,
            };

            setIsLoading(true);
            fetch(`${API.QA_URL}api/v1/products/post_quote`, {
                method: "POST",
                body: JSON.stringify(subscriberInfo),
                headers: {
                    "Content-Type": "application/json",
                    "minestar-api-token": "xyz",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    setIsLoading(false);
                    if (responseJson === 1) {
                        setSentSuccessfully(true);
                        setValues({});
                    } else {
                        setSentSuccessfully(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setSentSuccessfully(false);
                });
        }

        setErrors(validate(values));
    };

    return { handleChange, handleSubmit, values, errors };
};

export default useForm;
