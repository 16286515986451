import React from "react";
import { Link } from "react-router-dom";

import StockStatus from "./StockStatus";
import useTyreImage from "../../Utility/hooks/useTyreImage";
import defaultTyre from "../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";
import { removeSlashFromTyreSize, removeFileExtension } from "../../Utility/Utils";

function SlimProductCard({ product }) {
    const { loading, error, image } = useTyreImage(
        product?.brand,
        product?.imageName
    );

    return (
            <Link to={`/product/${product?.brand}/${removeSlashFromTyreSize(product?.tyreSize)}/${removeFileExtension(product?.imageName)}/${product?.productGuid}`}
            className="tyre-cards mx-auto col-12 col-sm-6 col-md-4 px-lg-4 col-lg-3 mb-3 mb-md-5 d-flex flex-column align-items-center">
            <img
                className="img-fluid product-images"
                src={
                    loading || error !== null
                        ? defaultTyre
                        : image === null
                            ? defaultTyre
                            : image
                }
                alt={
                    loading || error !== null
                        ? ""
                        : image === null
                            ? ""
                            : product?.brand + " " + product?.tyreSize + " " + product?.treadPattern
                }
            />
            <div className="tyre-text my-2 ml-1">
                <h2 className="text-left">
                    <span className="tyre-brand">
                        {product?.brand + " "} {/* space added so the heading tag has a space between the brand and tyre size */}
                    </span>
                    <br />
                    <span className="tyre-size mt-1">
                        {product?.tyreSize} <br /> {product?.treadPattern}
                    </span>
                </h2>
                <div className="stock-price">
                    {product.quantity && product.quantity > 0
                        ? "R" + product.sellingPrice
                        : "R -"}
                </div>
            </div>
            <div className="stock-status">
                <StockStatus quantity={product.quantity} />
            </div>
            <button className="product-more-details-btn">BUY NOW</button>
            </Link>
        // </div>
    );
}

export default SlimProductCard;
