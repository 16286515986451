import React, { useState, useEffect } from "react";
import "./CollectAddressListMobile.scss";
import CollectAddressItemMobile from "./CollectAddressItemMobile";

function CollectAddressListMobile({
    addressList,
    currentSelectedAddressState,
    setFinalAddress,
}) {
    const [currentActiveAddress, setCurrentActiveAddress] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const addressChangeHandler = (address) => {
        setCurrentActiveAddress(address);
        currentSelectedAddressState.setCurrentSelectedAddress(address);
        setFinalAddress(address);
        setIsLoading(false);
    };

    return (
        <div>
            {addressList.map((address, index) => (
                <CollectAddressItemMobile
                    key={index}
                    addressChangeHandler={addressChangeHandler}
                    address={address}
                    currentActiveAddress={currentActiveAddress}
                    isLoading={isLoading}
                />
            ))}
        </div>
    );
}

export default CollectAddressListMobile;
