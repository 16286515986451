import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { API } from "../../../../Utility/ApiUrlPath";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { isObjEmpty } from "../../../../Utility/Utils";

toast.configure();

const useForm = (callback, validate, setIsLoading, setSuccess) => {
    const [values, setValues] = useState({ email: "" });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errorsObj = validate(values);
        setErrors(errorsObj);

        if (isObjEmpty(errorsObj)) {
            setIsLoading(true);
            var userEmail = values.email.trim();

            fetch(`${API.QA_URL}api/v1/users/send_password_reset`, {
                method: "POST",
                body: JSON.stringify(userEmail),
                headers: {
                    "Content-Type": "application/json",
                    "minestar-api-token": "xyz",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    setIsLoading(false);
                    // if (responseJson.bHasEmailBeenSent) {
                    setSuccess(true);
                    toast.success(
                        "If an account exists, an email has been sent."
                    );
                    // }
                })
                .catch((error) => {
                    toast.error(
                        "Something went wrong, please try again later."
                    );
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [callback, errors, isSubmitting]);

    return { handleChange, handleSubmit, values, errors };
};

export default useForm;
