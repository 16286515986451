import React, { useState, useRef } from "react";
import "./DeliveryAddressList.scss";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import AddressRadioButton from "../../../../../RadioButtons/AddressRadioButtons/AddressRadioButton";

const DeliveryAddressList = ({
    addressList,
    currentSelectedAddressState,
    setFinalAddress,
}) => {
    const currectSection = useRef(null);

    const [currentActiveAddress, setCurrentActiveAddress] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const radioChangeHandler = (address) => {
        setCurrentActiveAddress(address);
        currentSelectedAddressState.setCurrentSelectedAddress(address);
        setFinalAddress(address);
        setIsLoading(false);
    };

    return (
        <div className="px-4">
            <div id="collect-address-list" ref={currectSection}>
                {addressList
                    // .sort((a, b) => {
                    //     return isLoading && a.bIsDefault
                    //         ? -1
                    //         : a.iUserInformationId ===
                    //           currentActiveAddress.iUserInformationId
                    //         ? -1
                    //         : 1;
                    // })
                    .map((address, index) => (
                        <AddressRadioButton
                            key={index}
                            id={index}
                            changed={() => {
                                radioChangeHandler(address);
                            }}
                            isSelected={
                                isLoading && address.bIsDefault
                                    ? address.bIsDefault
                                    : address.iUserInformationId ===
                                      currentActiveAddress.iUserInformationId
                            }
                            value={address.iUserInformationId}
                            address={address}
                        />
                    ))}
            </div>
        </div>
    );
};

export default DeliveryAddressList;
