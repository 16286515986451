import { createSlice } from "@reduxjs/toolkit";
import {
    OZOW_PAYMENT,
    // MANUAL_PAYMENT,
    // CREDIT_PAYMENT,
} from "../../../Utility/PaymentOptions";
import Decimal from "decimal.js-light"; // http://mikemcl.github.io/decimal.js-light/
Decimal.set({
    rounding: Decimal.ROUND_HALF_UP,
});

const tax = new Decimal(0.15);

const initialState = {
    count: 0,
    subTotal: new Decimal(0).toFixed(2),
    taxAmount: new Decimal(0).toFixed(2),
    transactionFee: new Decimal(0).toFixed(2),
    total: new Decimal(0).toFixed(2),
    activeAddress: null,
    deliveryMethod: null,
    paymentOption: null,
    cartList: [],
};

function calcSummaryValues(state) {
    let tot = new Decimal(0);
    if (state.cartList?.length > 1) {
        tot = state.cartList.reduce((accumulator, current) => {
            if (current) {
                let currentBuyerQuantity = new Decimal(current.iBuyerQuantity);
                let subtotal = currentBuyerQuantity.times(current.sellingPrice);
                return (
                    // accumulator.plus() + current.iBuyerQuantity * current.sellingPrice
                    accumulator.plus(subtotal)
                );
            } else {
                return new Decimal(current);
            }
        }, new Decimal(0));
    } else if (state.cartList?.length === 1) {
        let currentBuyerQuantity = new Decimal(
            state.cartList[0].iBuyerQuantity
        );
        let subtotal = currentBuyerQuantity.times(
            state.cartList[0].sellingPrice
        );

        tot = subtotal;
    }

    if (!tot.equals(0)) {
        let taxAmount = tot.times(tax);
        const fullAmount = tot.plus(taxAmount);

        state.subTotal = tot.toFixed(2);
        state.taxAmount = taxAmount.toFixed(2);
        state.total = fullAmount.toFixed(2);
    } else {
        state.subTotal = new Decimal(0).toFixed(2);
        state.taxAmount = new Decimal(0).toFixed(2);
        state.total = new Decimal(0).toFixed(2);
    }
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addItem: {
            reducer(state, action) {
                state.cartList.push(action.payload);
                state.count = state.cartList.length;

                calcSummaryValues(state);
            },
            prepare(cartItem, iBuyerQuantity, imageName) {
                return {
                    payload: {
                        ...cartItem,
                        iBuyerQuantity,
                        imageName,
                    },
                };
            },
        },
        removeItem: {
            reducer: (state, action) => {
                state.cartList = state.cartList.filter(
                    (i) =>
                        i.iProductResellerId !==
                        action.payload.iProductResellerId
                );
                state.count = state.cartList.length;
                if (state.cartList.length === 0) {
                    cartSlice.caseReducers.resetCart(state, action);
                    calcSummaryValues(state);
                } else {
                    calcSummaryValues(state);
                }
            },
            prepare(iProductResellerId) {
                return {
                    payload: {
                        iProductResellerId,
                    },
                };
            },
        },
        changeQty: {
            reducer: (state, action) => {
                const { iProductResellerId, qty } = action.payload;
                state.cartList = state.cartList.map((i) => {
                    if (i.iProductResellerId === iProductResellerId) {
                        i.iBuyerQuantity = qty;
                    }
                    return i;
                });

                calcSummaryValues(state);
            },
            prepare(iProductResellerId, qty) {
                return {
                    payload: {
                        iProductResellerId,
                        qty,
                    },
                };
            },
        },
        setActiveAddress: {
            reducer: (state, action) => {
                state.activeAddress = action.payload.address;
            },
            prepare: (address) => {
                return {
                    payload: {
                        address,
                    },
                };
            },
        },
        setDeliveryMethod: {
            reducer: (state, action) => {
                state.deliveryMethod = action.payload.deliveryMethod;
            },
            prepare: (deliveryMethod) => {
                return {
                    payload: {
                        deliveryMethod,
                    },
                };
            },
        },

        /***
         * Sets payment method.
         * If method is ozow, the transaction fee is calculated and stored.
         */
        setPaymentMethod: {
            reducer: (state, action) => {
                if (action.payload.paymentOption === OZOW_PAYMENT) {
                    // Add OZOW transaction fee.
                    let ozowFeePercentage = new Decimal(0.015);

                    let tempTotal = new Decimal(state.total);
                    const fee = tempTotal
                        .times(ozowFeePercentage.toPrecision(2))
                        .toFixed(2);
                    state.transactionFee = fee;
                } else {
                    // Reset transaction fee.
                    state.transactionFee = new Decimal(0).toFixed(2);
                }

                state.paymentOption = action.payload.paymentOption;
            },
            prepare: (paymentOption) => {
                return {
                    payload: {
                        paymentOption,
                    },
                };
            },
        },
        resetCart: () => {
            return initialState;
        },
    },
});

// Entire Cart
export const selectCart = (state) => state.cart;

// Actions
export const {
    changeQty,
    addItem,
    removeItem,
    setActiveAddress,
    setDeliveryMethod,
    setPaymentMethod,
    resetCart,
} = cartSlice.actions;

// Reducer
export default cartSlice.reducer;
