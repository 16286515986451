export default function validateProfileInfo(values) {
    let errors = {};

    if (!values.firstname) {
        errors.firstname = "First name is required.";
    } else if (values.firstname.trim().length < 2) {
        errors.firstname = "Name is a little too short.";
    }

    if (!values.lastname) {
        errors.lastname = "Last name is required.";
    } else if (values.lastname.trim().length < 2) {
        errors.lastname = "Name is a little too short.";
    }

    if (!values.email) {
        errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(values.email.trim())) {
        errors.email = "Email address is invalid.";
    }

    if (!values.number) {
        errors.number = "Phone number number is required.";
    } else if (
        !/^[0-9\b]+$/.test(values.number.trim()) &&
        values.number.trim().length !== 10
    ) {
        errors.number = "Phone number must be 10 digits long.";
    }

    if (values.company) {
        if (values.company.trim().length < 2) {
            errors.company = "Company name needs to be 2 characters or more.";
        }
    }

    if (values.companyRegNumber) {
        if (
            !/[0-9\b]{4}\/[0-9\b]{7}\/[0-9\b]{2}/.test(
                values.companyRegNumber.trim()
            ) ||
            values.companyRegNumber.trim().length !== 15
        ) {
            errors.companyRegNumber =
                "Example of expected format is '12/1234567/12'.";
        }
    }

    if (values.vatNumber) {
        if (values.vatNumber.trim().length !== 10) {
            errors.vatNumber = "Vat number must be 10 digits long.";
        }
    }

    return errors;
}
