/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";

// import components
import validate from "./ValidateLoginInfo";
import useForm from "./LoginuseForm";

// import css
import "./Form.scss";

import "../../../Components/Errors/Errors.scss";
import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";

const FormSignup = ({ isFromCartPage = false, handleCartFunc = null }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { handleChange, handleSubmit, values, errors } = useForm(
        validate,
        isFromCartPage,
        handleCartFunc,
        setIsLoading
    );

    return (
        <>
            {isLoading ? (
                <>
                    <div className="notFoundContent d-flex align-items-center justify-content-center my-3 my-md-5">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img
                                className="my-2"
                                src={MinestarLoader}
                                alt="Loading..."
                                height={200}
                                width={200}
                            />
                            <p
                                className="not-found-text"
                                style={{ color: "#e50119" }}
                            >
                                Logging you in...
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <div
                    className={
                        isFromCartPage
                            ? "form-content-right d-flex align-items-start align-items-lg-center justify-content-center p-1 p-md-3"
                            : "form-content-right d-flex align-items-start align-items-lg-center justify-content-center"
                    }
                >
                    <div className="d-flex flex-column align-items-center">
                        <form
                            onSubmit={handleSubmit}
                            className="loginForm"
                            noValidate
                        >
                            <h1>Welcome to Minestar</h1>
                            <p className="formSubtext">
                                Please sign-in to your account:
                            </p>
                            <div className="form-inputs mt-2">
                                <label className="heading-small mb-1 ml-1">
                                    Email
                                </label>
                                <input
                                    className="form-control loginInput"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    tabIndex={1}
                                />
                                {errors.email && (
                                    <p className="text-errors">
                                        {errors.email}
                                    </p>
                                )}
                            </div>
                            <div className="form-inputs mt-3">
                                <label className="heading-small mb-1 ml-1">
                                    Password
                                </label>
                                <Link tabIndex={-1} to="/forgotpassword">
                                    <span className="forgotPassword" href="#">
                                        Forgot Password?
                                    </span>
                                </Link>
                                <input
                                    className="form-control loginInput"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={values.password}
                                    onChange={handleChange}
                                    tabIndex={2}
                                />

                                {errors.password && (
                                    <p className="text-errors">
                                        {errors.password}
                                    </p>
                                )}
                            </div>
                            {/* <div className="form-inputs mt-2">
                                <div className="form-check">
                                    <input
                                        className="ml-1"
                                        type="checkbox"
                                        name="selectCheckbox"
                                        id="selectCheckbox"
                                        tabIndex={3}
                                        style={{ backgroundColor: "red" }}
                                    ></input>
                                    <label
                                        htmlFor="selectCheckbox"
                                        className="form-check-label pl-1"
                                        value={values.rememberUser}
                                        onChange={handleChange}
                                    >
                                        Remember me
                                    </label>
                                </div>
                            </div> */}
                            <button
                                tabIndex={4}
                                // className="loginBtn mt-0"
                                className="loginBtn mt-3"
                                type="submit"
                            >
                                Sign in
                            </button>
                            <p className="mt-3 " id="registrationLink">
                                Not yet registered?
                                <Link tabIndex={-1} to="/register">
                                    <span href="#" className="pl-2">
                                        Register Here
                                    </span>
                                </Link>
                            </p>
                        </form>
                        {isFromCartPage ? null : (
                            <h3 className="go-back-btn mt-3">
                                <Link tabIndex={-1} to="/">
                                    <a href="#">Go Back To Site</a>
                                </Link>
                            </h3>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default FormSignup;
