import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { isObjEmpty } from "../../../Utility/Utils";
import { useNavigate } from "react-router-dom";
import { API } from "../../../Utility/ApiUrlPath";

import { UserContext } from "../../../Contexts/user.context";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const useForm = (validate, isFromCartPage, handleCartFunc, setIsLoading) => {
    const [values, setValues] = useState({
        email: "",
        password: "",
        rememberUser: "",
    });
    const [errors, setErrors] = useState({});
    let { user, setUser } = useContext(UserContext);

    const navigate = useNavigate();

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            const { name, checked } = e.target;
            setValues({ ...values, [name]: checked });
        } else {
            const { name, value } = e.target;
            setValues({ ...values, [name]: value });
        }
    };

    const fetchLogin = async (userInfo) => {
        setIsLoading(true);

        try {
            let response = await fetch(`${API.QA_URL}api/v1/users/login`, {
                method: "POST",
                body: JSON.stringify(userInfo),
                headers: {
                    "Content-Type": "application/json",
                    "minestar-api-token": "xyz",
                },
            });
            const responseJson = await response.json();

            setIsLoading(false);
            if (responseJson.responseMessage === "Login Successful") {
                const currentUser = {
                    firstName: responseJson.firstName,
                    lastName: responseJson.lastName,
                    emailAddress: responseJson.emailAddress,
                    id: responseJson.id,
                    userGuid: responseJson.userGuid,
                    resellerGuid: responseJson.resellerGuid,
                    role: responseJson.role,
                    token: responseJson.token,
                    hasAccount: responseJson.hasAccount,
                };
                setUser(currentUser);
                localStorage.setItem(
                    "currentUser",
                    JSON.stringify(currentUser)
                );

                if (isFromCartPage) {
                    handleCartFunc();
                } else {
                    navigate("/accountsettings");
                }
            } else if (responseJson.message === "login failed") {
                toast.error("Invalid credentials.", {
                    autoClose: toastrTime,
                });
            } else {
                toast.error("Your credentials are invalid. Please try again.", {
                    autoClose: toastrTime,
                });
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            toast.error("Something went wrong. Please try again.", {
                autoClose: toastrTime,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var userInfo = {
            strEmailAddress: values.email.trim(),
            strPassword: values.password.trim(),
        };
        setErrors(validate(values));

        if (isObjEmpty(errors)) {
            fetchLogin(userInfo);
        }
    };

    return { handleChange, handleSubmit, values, errors };
};

export default useForm;
