import React, { createContext, useState, useMemo } from "react";

// actual value you want to access
export const ProductListContext = createContext({
    productList: null,
    setProductList: () => null,
});

export const ProductListProvider = ({ children }) => {
    const [productList, setProductList] = useState([]);
    const providerValue = useMemo(() => ({ productList, setProductList }), [productList, setProductList]);

    return <ProductListContext.Provider value={providerValue}>{children}</ProductListContext.Provider>;
};
