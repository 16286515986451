import React from "react";
import { Button } from "semantic-ui-react";
import validate from "./ValidatePasswordInfo";
import useForm from "./PassworduseForm";
import "./PasswordForm.scss";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../../SEO/SEONoIndex";

const PasswordForm = () => {
    const { handleChange, handleSubmit, values, errors } = useForm(validate);

    return (
        <div>
            <SEONoIndex title="Password | Minestar South Africa" />
            <h4 className="password-title col-12 col-sm-9 px-0 my-1 ml-2 ml-md-0">
                Password
            </h4>
            <form
                onSubmit={handleSubmit}
                className="p-3 p-md-4"
                id="passwordForm"
                noValidate
            >
                <div className="row">
                    <div className="col-12 col-md-6 px-0 px-sm-1">
                        <div className="form-group mt-2">
                            <label className="heading-small mb-1 ml-1">
                                Old Password
                            </label>
                            <input
                                className="form-control"
                                type="password"
                                name="oldPassword"
                                placeholder="******"
                                value={values.oldPassword}
                                onChange={handleChange}
                            />
                            {errors.oldPassword && (
                                <p className="text-error">
                                    {errors.oldPassword}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 px-0 px-sm-1">
                        <div className="form-group mt-2">
                            <label className="heading-small mb-1 ml-1">
                                New Password
                            </label>
                            <input
                                className="form-control"
                                type="password"
                                name="newPassword"
                                placeholder="******"
                                value={values.newPassword}
                                onChange={handleChange}
                            />
                            {errors.newPassword && (
                                <p className="text-error">
                                    {errors.newPassword}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 px-0 px-sm-1">
                        <div className="form-group mt-2">
                            <label className="heading-small mb-1 ml-1">
                                Confirm Password
                            </label>
                            <input
                                className="form-control"
                                type="password"
                                name="confirmPassword"
                                placeholder="******"
                                value={values.confirmPassword}
                                onChange={handleChange}
                            />
                            {errors.confirmPassword && (
                                <p className="text-error">
                                    {errors.confirmPassword}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <Button
                        className="mt-2 mt-md-3 add-address-btn"
                        id="submit-password"
                        type="submit"
                    >
                        Save Changes
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default PasswordForm;
