import React from "react";
import { Card, CardBody } from "reactstrap";
import { formatDate, isObjEmpty } from "../../../Utility/Utils";

import "./Review.scss";

import OrderSummary from "../../Order/OrderSummary/OrderSummary";
import SummaryItem from "./SummaryItem";

import {
    DELIVER_OPTION,
    COLLECT_OPTION,
} from "../../../Utility/Constants/DeliveryOptions";

// Redux
import { useSelector } from "react-redux";
import { selectCart } from "../../../Redux/Features/Cart/cartSlice";

export default function Review({
    finalAddress,
    deliveryOption,
    isOrderProcessCompleteState,
    isSummaryDisplayedState,
}) {
    const cart = useSelector(selectCart);

    function generateAddressString() {
        let address = [];

        address.push(finalAddress.strAddressLineOne);
        finalAddress.strAddressLineTwo !== "" &&
            address.push(finalAddress.strAddressLineTwo);
        address.push(finalAddress.strCity);
        address.push(finalAddress.strProvince);
        address.push(finalAddress.strPostalCode);
        return address.join(", ");
    }

    return (
        <>
            <div className="cart-summary col-12 px-0 mt-4 mb-3">
                <h2 className="summary-heading ml-md-4 mb-3">Order Summary</h2>
                <div className="row mx-0 justify-content-around align-items-start">
                    <div
                        className={
                            isOrderProcessCompleteState.isOrderProcessComplete
                                ? "summary-list-container row flex-column mx-0 p-2 p-md-4 col-12"
                                : isSummaryDisplayedState.isSummaryDisplayed
                                ? "summary-list-container row flex-column mx-0 p-2 p-md-4 col-lg-7"
                                : "summary-list-container row flex-column mx-0 p-2 p-md-4 col-12"
                        }
                    >
                        <div className="row flex-column mx-0">
                            <div>
                                <span className="text-bold">
                                    Order Date:&nbsp;
                                </span>
                                {formatDate(new Date())}
                            </div>
                            <div>
                                {deliveryOption === DELIVER_OPTION ? (
                                    <>
                                        <span className="text-bold d-block">
                                            Delivery Address:&nbsp;
                                        </span>
                                        {!isObjEmpty(finalAddress) &&
                                            generateAddressString()}
                                    </>
                                ) : (
                                    deliveryOption === COLLECT_OPTION && (
                                        <>
                                            <span className="text-bold d-block">
                                                Collection Address:&nbsp;
                                            </span>
                                            {!isObjEmpty(finalAddress) &&
                                                generateAddressString()}
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                        {cart.cartList.map((item, index) => {
                            return (
                                <Card
                                    key={index}
                                    className="summary-item-container my-2 my-md-4"
                                >
                                    <CardBody>
                                        <div className="row mx-0 align-items-center my-2">
                                            <div className="row mx-0 col-12 px-2 px-md-0 py-1 py-md-0 align-items-center justify-content-start">
                                                <div className="summary-item row mx-0 col-12 px-0 align-items-center justify-content-around">
                                                    <SummaryItem item={item} />
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            );
                        })}
                    </div>
                    <div className="order-summary-mobile mt-md-3">
                        <OrderSummary
                            key={2}
                            isOrderProcessComplete={
                                isOrderProcessCompleteState.isOrderProcessComplete
                            }
                        />
                    </div>
                    <div className="order-summary-md col-12 col-lg-4 px-1 px-md-4 px-lg-0 mt-3 mt-lg-0">
                        <OrderSummary
                            key={2}
                            deliveryOption={deliveryOption}
                            isOrderProcessComplete={
                                isOrderProcessCompleteState.isOrderProcessComplete
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
