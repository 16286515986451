import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { isObjEmpty } from "../../../Utility/Utils";
import { API } from "../../../Utility/ApiUrlPath";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import { UserContext } from "../../../Contexts/user.context";

const RegisterAddressUseForm = (
    validate,
    userDetails,
    userAddressState,
    userType,
    setActive,
    setIsLoading
) => {
    toast.configure();
    let { setUser } = useContext(UserContext);

    const navigate = useNavigate();

    // const [values, setValues] = useState({
    //     id: 0,
    //     strCountry: "",
    //     strAddressLineOne: "",
    //     strAddressLineTwo: "",
    //     strProvince: "",
    //     strCity: "",
    //     strPostalCode: "",
    //     bAcceptTsAndCs: false,
    // });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            const { name, checked } = e.target;
            // setValues({ ...values, [name]: checked });
            userAddressState.setUserAddress({
                ...userAddressState.userAddress,
                [name]: checked,
            });
        } else {
            const { name, value } = e.target;
            // setValues({ ...values, [name]: value });
            userAddressState.setUserAddress({
                ...userAddressState.userAddress,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const errorList = validate(values); // This is an object, not an array.
        const errorList = validate(userAddressState.userAddress); // This is an object, not an array.
        setErrors(errorList);
        setIsLoading(true);

        if (isObjEmpty(errorList)) {
            var registrationInfo = {
                strEmailAddress: userDetails?.email,
                strFirstName: userDetails?.firstname,
                strLastName: userDetails?.lastname,
                strPassword: userDetails?.password,
                IsReseller: userType === "Reseller" ? true : false,
                CompanyName: null,
                CompanyRegNumber: null,
                ContactNumber: userDetails?.phonenumber,
                VatNumber: null,
                UserInfo: {
                    addressTitle: "",
                    country: userAddressState.userAddress.strCountry,
                    addressLineOne:
                        userAddressState.userAddress.strAddressLineOne,
                    addressLineTwo:
                        userAddressState.userAddress.strAddressLineTwo,
                    province: userAddressState.userAddress.strProvince,
                    city: userAddressState.userAddress.strCity,
                    postalCode: userAddressState.userAddress.strPostalCode,
                    isDefault: true,
                },
            };

            try {
                let response = null;
                response = await fetch(`${API.QA_URL}api/v1/users/create`, {
                    method: "POST",
                    body: JSON.stringify(registrationInfo),
                    headers: {
                        "Content-Type": "application/json",
                        "minestar-api-token": "xyz",
                    },
                });

                if (response.status >= 200 && response.status < 300) {
                    const responseJson = await response?.json();

                    if (responseJson?.responseHandler === 1) {
                        const currentUser = {
                            firstName: responseJson.firstName,
                            lastName: responseJson.lastName,
                            emailAddress: responseJson.emailAddress,
                            id: responseJson.id,
                            userGuid: responseJson.userGuid,
                            resellerGuid: responseJson.resellerGuid,
                            role: responseJson.role,
                            token: responseJson.token,
                        };

                        setUser(currentUser);
                        localStorage.setItem(
                            "currentUser",
                            JSON.stringify(currentUser)
                        );

                        navigate("/accountsettings");
                    } else if (responseJson.responseHandler === 3) {
                        toast.error("Account already exists.", {
                            autoClose: toastrTime,
                        });
                    } else {
                        toast.error("Something went wrong. Please try again.", {
                            autoClose: toastrTime,
                        });
                    }
                    setActive("Register");
                }
                return;
            } catch (error) {
                setIsLoading(false);
                setActive("Register");
                toast.error("Something went wrong. Please try again.", {
                    autoClose: toastrTime,
                });
                return;
            }
        } else {
            setIsLoading(false);
            return;
        }
        // toast.error("Something went wrong. Please try again.", {
        //     autoClose: toastrTime,
        // });
        // setActive("Register");
    };

    return { handleChange, handleSubmit, errors };
};

export default RegisterAddressUseForm;
