import React, { useState } from "react";
import "./Payment.scss";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import PaymentList from "./PaymentList";
import PaymentListMobile from "./PaymentMobile/PaymentListMobile";
import {
    OZOW_PAYMENT,
    MANUAL_PAYMENT,
    ACCOUNT_PAYMENT,
} from "../../../Utility/PaymentOptions";

const paymentOptions = [OZOW_PAYMENT, MANUAL_PAYMENT, ACCOUNT_PAYMENT];

const Payment = ({ setFinalPaymentOption }) => {
    const [currentSelectedPaymentOption, setCurrentSelectedPaymentOption] =
        useState(null);

    return (
        <div id="payment-options-list-container" className="mt-2 mt-md-4 mb-3">
            <h2 className="payment-heading ml-md-4">Payment Options</h2>
            <div className="payment-list">
                <PaymentList
                    paymentOptions={paymentOptions}
                    currentSelectedPaymentOptionState={{
                        currentSelectedPaymentOption,
                        setCurrentSelectedPaymentOption,
                    }}
                    setFinalPaymentOption={setFinalPaymentOption}
                />
            </div>
            <div className="payment-list-mobile">
                <PaymentListMobile
                    paymentOptions={paymentOptions}
                    currentSelectedPaymentOptionState={{
                        currentSelectedPaymentOption,
                        setCurrentSelectedPaymentOption,
                    }}
                    setFinalPaymentOption={setFinalPaymentOption}
                />
            </div>
        </div>
    );
};

export default Payment;
