import React from "react";

// Import functions
import RemoveBrandFromProductName from "../../../Utility/Functions/RemoveBrandFromProductName";

import defaultTyre from "../../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";

import useTyreImage from "../../../Utility/hooks/useTyreImage";

function SummaryItem({ item }) {
    const { loading, error, image } = useTyreImage(
        item?.brand,
        item?.imageName
    );

    return (
        <>
            <div className="col-12 col-md-5 row align-items-center justify-content-center">
                <img
                    id="summary-image"
                    className="tyreImage "
                    src={
                        loading || error !== null
                            ? defaultTyre
                            : image === null
                            ? defaultTyre
                            : image
                    }
                    alt="tyre"
                />
            </div>
            <div className="summary-tyre-details col-12 col-md-7 px-0 px-md-auto">
                <span className="summary-tyre-brand-name d-flex flex-wrap align-items-center">
                    {item?.brand}
                </span>
                <h4 className="summary-tyre-name row mx-0">
                    <span>
                        {RemoveBrandFromProductName(item?.productName)}{" "}
                        {item?.bIsMinestar && " (Minestar)"}
                    </span>
                </h4>
                <div className="summary-tyre-qty-section">
                    <span className="d-flex d-md-block justify-content-between">
                        <span className="summary-title">Qty:</span>
                        <span className="summary-text">
                            {" " + item.iBuyerQuantity}
                        </span>
                    </span>
                </div>
                <div className="summary-tyre-total-price">
                    <span className="d-flex d-md-block justify-content-between">
                        <span className="summary-title">Price:</span>
                        <span className="summary-text">
                            {" R"}
                            {item.sellingPrice * item.iBuyerQuantity}
                        </span>
                    </span>
                </div>
                <div className="summary-tyre-condition">
                    <span className="d-flex d-md-block justify-content-between">
                        <span className="summary-title">Condition: </span>
                        <span className="summary-text">
                            {item.bIsConditionNew ? "New" : "2nd Hand"}
                        </span>
                    </span>
                </div>
            </div>
        </>
    );
}

export default SummaryItem;
