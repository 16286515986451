/**
 * Remove the brand from the product name.
 * @param {*} productName
 * @returns Tyre Size and Tyre Tread Pattern.
 */
export default function RemoveBrandFromProductName(productName) {
    const productPieces = productName.replace("  ", " ").split(" "); // Removes extra whitespace from product name.
    const brandWithTreadPattern = productPieces.slice(1).join(" "); // Removes brand at index 0.
    return brandWithTreadPattern;
}
