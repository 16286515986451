import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import validate from "./ValidateForgotPassword";
import useForm from "./ForgotPassworduseForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import "../../../Errors/Errors.scss";
import MinestarLoader from "../../../../Assests/Animations/Minestar-Loader-Small.gif";

const FormSignup = ({ submitForm }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const { handleChange, handleSubmit, values, errors } = useForm(
        submitForm,
        validate,
        setIsLoading,
        setSuccess
    );

    return (
        <div className="forgotPasswordContent">
            {isLoading ? (
                <div className="notFoundContent my-1">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <img
                            className="my-2 minestar-loader"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={200}
                            width={200}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Emailing Reset Password Link...
                        </p>
                    </div>
                </div>
            ) : success ? (
                <div className="reset-success-container">
                    <FontAwesomeIcon
                        className="menu-icon reset-success-icon"
                        icon={faCheck}
                    />
                    <h1>
                        Link Sent Successfully! Please allow a few minutes for
                        the email to reach your inbox.
                    </h1>
                    <p className="mt-3" id="registrationLink">
                        <button
                            onClick={() => navigate("/login")}
                            className="mt-3"
                            id="forgotPasswordbtn"
                            type="button"
                        >
                            Go To Login
                        </button>
                    </p>
                </div>
            ) : (
                <form
                    onSubmit={handleSubmit}
                    className="loginForm p-2"
                    noValidate
                >
                    <p className="formTitle">
                        Enter the email address associated with your account and
                        we'll send you a link to reset your password.
                        <br />
                        <strong>Note</strong> - Please allow a few minutes for
                        the email to reach your inbox before retrying.
                    </p>
                    <div className="form-inputs mt-3">
                        <p className="formLabel">Email Address</p>
                        <input
                            className="form-control mt-2"
                            id="forgotPasswordInput"
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={values.email}
                            onChange={handleChange}
                        />
                        {errors.email && (
                            <p className="text-errors">{errors.email}</p>
                        )}
                    </div>
                    <button
                        // id="forgotPasswordbtn"
                        className="mt-3 main-btn"
                        style={{ width: "100%" }}
                        type="submit"
                    >
                        Send Link
                    </button>
                    <p className="mt-3 " id="registrationLink">
                        Don't have an account?
                        <Link to="/register">
                            <span className="pl-2">Signup &nbsp;</span>
                        </Link>
                    </p>
                </form>
            )}
        </div>
    );
};

export default FormSignup;
