import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { isObjEmpty } from "../../../Utility/Utils";
import { API } from "../../../Utility/ApiUrlPath";

import { UserContext } from "../../../Contexts/user.context";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const useForm = (
    validate,
    activeStockItem,
    handleResetActiveStockItem,
    setIsLoading
) => {
    const [values, setValues] = useState({
        resellerGuid: null,
        productResellerLists: [
            {
                productGuid: null,
                quantity: null,
                price: null,
                hasBeenUsed: false,
                treadDepth: null,
                imageName: null,
            },
        ],
    });
    const [errors, setErrors] = useState({});
    let { user } = useContext(UserContext);

    useEffect(() => {
        const v = {
            resellerGuid: user?.resellerGuid ?? null,
            productResellerLists: [
                {
                    productGuid: activeStockItem.productGuid,
                    quantity: null,
                    price: null,
                    hasBeenUsed: false,
                    treadDepth: null,
                    imageName: null,
                },
            ],
        };

        setValues(v);
    }, [activeStockItem]);

    const handleChange = (e) => {
        let productResellerLists = [];
        if (e.target.type === "checkbox") {
            const { name, checked } = e.target;
            if (name === "hasBeenUsed") {
                if (values.productResellerLists[0].imageName !== null) {
                    values.imageName = null;
                }

                if (values.productResellerLists[0].treadDepth !== null) {
                    values.treadDepth = null;
                }

                // If it's a used tyre then the quantity should be forced to 1.
                productResellerLists = [
                    {
                        ...values.productResellerLists[0],
                        quantity: 1,
                        [name]: checked,
                    },
                ];
            } else {
                productResellerLists = [
                    { ...values.productResellerLists[0], [name]: checked },
                ];
            }

            const newValues = {
                resellerGuid: values.resellerGuid,
                productResellerLists: productResellerLists,
            };
            setValues(newValues);
        } else {
            const { name, value } = e.target;

            if (name === "quantity") {
                // If it's a used tyre then the quantity should be forced to 1.
                if (values.productResellerLists[0].hasBeenUsed) {
                    productResellerLists = [
                        {
                            ...values.productResellerLists[0],
                            [name]: 1,
                        },
                    ];
                } else {
                    productResellerLists = [
                        {
                            ...values.productResellerLists[0],
                            [name]: value,
                        },
                    ];
                }
                const newValues = {
                    resellerGuid: values.resellerGuid,
                    productResellerLists: productResellerLists,
                };
                setValues(newValues);
            } else {
                const newValues = {
                    resellerGuid: values.resellerGuid,
                    productResellerLists: [
                        { ...values.productResellerLists[0], [name]: value },
                    ],
                };
                setValues(newValues);
            }
        }
    };

    async function handleImageChange(e) {
        try {
            var image = URL.createObjectURL(e.target.files[0]);
            const { name } = e.target;

            const dataUrl = await toDataURL(image);

            const newValues = {
                resellerGuid: values.resellerGuid,
                productResellerLists: [
                    { ...values.productResellerLists[0], [name]: dataUrl },
                ],
            };
            setValues(newValues);
        } catch (error) {
            console.error(error);
        }
    }

    const toDataURL = async (url) => {
        const response = await fetch(url);
        if (response) {
            let blob = await response.blob();
            if (blob) {
                let promise = new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                });
                return promise;
            }
        }
    };

    const fetchCreateResellerStockItem = async (resellerStockItem) => {
        try {
            setIsLoading(true);
            let response = await fetch(
                `${API.QA_URL}api/v1/product_reseller/create`,
                {
                    method: "POST",
                    body: JSON.stringify(resellerStockItem),
                    headers: {
                        "Content-Type": "application/json",
                        "minestar-api-token": "xyz",
                    },
                }
            );
            const responseJson = await response.json();
            if (responseJson.status >= 200 && responseJson.status <= 299) {
                toast.success("Added stock item successfully.", {
                    autoClose: toastrTime,
                });
            }
            if (responseJson.status >= 400 && responseJson.status <= 499) {
                toast.error("Something went wrong. Please try again.", {
                    autoClose: toastrTime,
                });
            }
            setIsLoading(false);
            handleResetActiveStockItem();
        } catch (error) {
            setIsLoading(false);
            handleResetActiveStockItem();
            console.error(error);
            toast.error("Something went wrong. Please try again", {
                autoClose: toastrTime,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var resellerStockItem = {
            resellerGuid: user?.resellerGuid ?? null,
            productResellerLists: [
                {
                    productGuid: activeStockItem.productGuid,
                    quantity: parseInt(values.productResellerLists[0].quantity),
                    price: parseFloat(values.productResellerLists[0].price),
                    bIsConditionNew: values.productResellerLists[0].hasBeenUsed
                        ? false
                        : true,
                    treadDepth: values.productResellerLists[0].treadDepth,
                    imageName: values.productResellerLists[0].imageName,
                },
            ],
        };

        const errors = validate(values);
        setErrors(errors);
        let err = isObjEmpty(errors);

        if (err) {
            fetchCreateResellerStockItem(resellerStockItem);
        }
    };

    return { handleChange, handleImageChange, handleSubmit, values, errors };
};

export default useForm;
