import { useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../../Utility/ApiUrlPath";
import { isObjEmpty } from "../../../Utility/Utils";

const MakeAnOfferuseForm = (
    validate,
    productName,
    setIsLoading,
    setSentSuccess
) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const v = validate(values);
        setErrors(v);
        if (isObjEmpty(v)) {
            const makeAnOfferObj = {
                ContactName: values?.name,
                ContactEmail: values?.email,
                ContactNumber: values?.phonenumber,
                ProductName: productName,
                OfferAmount: values?.amount,
                OfferQuantity: values?.quantity,
                Message: values?.message,
            };

            setIsLoading(true);
            fetch(`${API.QA_URL}api/v1/order/make_an_offer`, {
                method: "POST",
                body: JSON.stringify(makeAnOfferObj),
                headers: {
                    "Content-Type": "application/json",
                    "minestar-api-token": "xyz",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson === 1) {
                        setSentSuccess(true);
                        toast.success(
                            "Your offer has been sent successfully.",
                            { autoClose: 1000 }
                        );
                        setValues({});
                    } else {
                        toast.error("Your offer could not be sent.", {
                            autoClose: 1000,
                        });
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    toast.error(
                        "Could not send your offer. Please try again later.",
                        { autoClose: 1000 }
                    );
                });
        }
    };

    return { handleChange, handleSubmit, values, errors };
};

export default MakeAnOfferuseForm;
