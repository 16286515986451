import React, { useState, useContext } from "react";

import PaymentRadioButton from "../../RadioButtons/PaymentRadioButtons/PaymentRadioButton";

import { ACCOUNT_PAYMENT } from "../../../Utility/PaymentOptions";

import { UserContext } from "../../../Contexts/user.context";

function PaymentList({
    paymentOptions,
    currentSelectedPaymentOptionState,
    setFinalPaymentOption,
}) {
    const [currentActivePaymentOption, setCurrentActivePaymentOption] =
        useState("");

    const { user } = useContext(UserContext);

    const radioChangeHandler = (paymentOption) => {
        setCurrentActivePaymentOption(paymentOption);
        currentSelectedPaymentOptionState.setCurrentSelectedPaymentOption(
            paymentOption
        );
        setFinalPaymentOption(paymentOption);
    };

    return (
        <div className="px-0 px-md-4">
            <div id="payment-list" className="px-0 px-lg-4">
                {paymentOptions
                    .filter((paymentOption) => {
                        if (
                            paymentOption === ACCOUNT_PAYMENT &&
                            user.hasAccount
                        ) {
                            return true;
                        }
                        if (paymentOption !== ACCOUNT_PAYMENT) {
                            return true;
                        }
                        return false;
                    })
                    .map((paymentOption, index) => {
                        return (
                            <PaymentRadioButton
                                key={index}
                                id={index}
                                changed={() => {
                                    radioChangeHandler(paymentOption);
                                }}
                                value={paymentOption}
                                isSelected={
                                    paymentOption === currentActivePaymentOption
                                }
                                paymentOption={paymentOption}
                            />
                        );
                    })}
            </div>
        </div>
    );
}

export default PaymentList;
