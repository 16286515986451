import React from "react";
import "./OrderDone.scss";
import OrderComplete from "../../../Assests/Icons/orderComplete.png";
import OrderFailed from "../../../Assests/Icons/orderFailed.png";

import { Link } from "react-router-dom";

function OrderDone({ referenceNumber, orderState = "cancel" }) {
    return (
        <div style={{ width: "100%" }}>
            {orderState === "success" ? (
                <div className="main-completed-order-container d-flex flex-column align-items-center justify-content-center mt-4 p-4 ml-4">
                    <img
                        className="main-complete-icon"
                        src={OrderComplete}
                        alt=""
                    />

                    <h2 className="order-complete-heading">
                        Thank you for your order.
                    </h2>
                    <div className="order-complete-paragraph text-center">
                        Your reference number is #{referenceNumber}. We have
                        emailed your order confirmation, and will send you an
                        update when your order status changes.
                    </div>
                    <Link
                        className="order-complete-btn mt-3"
                        to="/accountsettings/orders"
                    >
                        <div className="main-btn">My Orders</div>
                    </Link>
                </div>
            ) : (
                <div className="main-completed-order-container d-flex flex-column align-items-center justify-content-center mt-4 p-4 ml-4">
                    <img
                        className="main-complete-icon"
                        src={OrderFailed}
                        alt=""
                    />

                    <h2 className="order-complete-heading">
                        Order was not successful.
                    </h2>
                    <div className="order-complete-paragraph text-center">
                        Please try to redo your order and make sure the details
                        provided is correct.
                    </div>
                    <Link
                        className="order-complete-btn mt-3"
                        to="/accountsettings/orders"
                    >
                        <div className="main-btn">My Orders</div>
                    </Link>
                </div>
            )}
        </div>
    );
}

export default OrderDone;
