import React, { useContext } from "react";
import { UserContext } from "../../Contexts/user.context";
import { Navigate } from "react-router-dom";

function RequireAuth({ children, redirectTo }) {
    const { user } = useContext(UserContext);

    let isAuthenticated = user === null ? false : true;

    return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export default RequireAuth;
