import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { API } from "../../../Utility/ApiUrlPath";
import "./AddressList.scss";
import AddressItem from "../AddressItem/AddressItem";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import "../../Errors/Errors.scss";
import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";

import { UserContext } from "../../../Contexts/user.context";
import AddressForm from "../../Forms/AddressForm/AddressForm";
import EditAddressForm from "../../Forms/AddressForm/EditAddressForm";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../../SEO/SEONoIndex";

const AddressList = () => {
    const { user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingAddresses, setHasErrorFetchingAddresses] =
        useState(false);

    const [active, setActive] = useState("addressList");

    const [currentSelectedAddress, setCurrentSelectedAddress] = useState({
        id: 0,
        strCountry: "",
        strAddressLineOne: "",
        strAddressLineTwo: "",
        strProvince: "",
        strCity: "",
        strPostalCode: "",
        bIsDefault: false,
    });
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [addressList, setAddressList] = useState([
        {
            id: 0,
            strCountry: "",
            strAddressLineOne: "",
            strAddressLineTwo: "",
            strProvince: "",
            strCity: "",
            strPostalCode: "",
            bIsDefault: false,
        },
    ]);

    /**
     * Fetch all user addresses.
     * @returns List of the associated user's addresses.
     */
    const fetchUserAddresses = async () => {
        const abortController = new AbortController();
        try {
            setIsLoading(true);
            setHasErrorFetchingAddresses(false);
            const data = await fetch(
                `${API.QA_URL}api/v1/users/get_single_userinformation?gGuid=${user?.userGuid}`,
                {
                    method: "GET",
                    mode: Request.mode,
                    headers: {
                        "Content-Type": "application/json",
                        "minestar-api-token": "xyz",
                    },
                    signal: abortController.signal,
                }
            );

            const responseJson = await data.json();
            if (responseJson.userInformationList?.$values.length > 0) {
                const addressList = responseJson.userInformationList.$values;

                const populatedList = addressList.map((address) => {
                    return {
                        id: address.$id,
                        iUserInformationId: address.iUserInformationId,
                        iUserId: address.iUserId,
                        country: address.strCountry ?? "",
                        strAddressLineOne: address.strAddressLineOne ?? "",
                        strAddressLineTwo: address.strAddressLineTwo ?? "",
                        province: address.strProvince ?? "",
                        city: address.strCity ?? "",
                        postalCode: address.strPostalCode ?? "",
                        bIsDefault: address.bIsDefault ?? "",
                    };
                });
                setAddressList(populatedList);
            }
            setIsLoading(false);
            setHasErrorFetchingAddresses(false);
        } catch (error) {
            setIsLoading(false);
            setHasErrorFetchingAddresses(true);
            console.error(error);
        }

        return () => abortController.abort();
    };

    // get address info for user:
    useEffect(() => {
        if (active === "addressList") fetchUserAddresses();
    }, [active]);

    return ( 
    <div>
        <SEONoIndex title="Addresses | Minestar South Africa" />
        {active === "addressList" ? (
        <>
            {!isLoading &&
            !hasErrorFetchingAddresses &&
            addressList.length > 0 ? (
                <>
                    <div className="row mx-0 mb-3">
                        <h4 className="address-title col-12 col-sm-7 col-md-9 px-0 my-1 ml-0">
                            Delivery Addresses
                        </h4>
                        <button
                            className="col-12 col-sm-5 col-md-3 px-0 add-address-btn"
                            onClick={() => {
                                setIsNewAddress(true);
                                setCurrentSelectedAddress({
                                    id: 0,
                                    strCountry: "",
                                    strAddressLineOne: "",
                                    strAddressLineTwo: "",
                                    strProvince: "",
                                    strCity: "",
                                    strPostalCode: "",
                                    bIsDefault: false,
                                });
                                setActive("addAddress");
                            }}
                        >
                            New Address
                        </button>
                    </div>
                    <div id="address-list" className="p-3 p-md-4">
                        <ul className="p-md-2 mx-md-2">
                            {addressList?.map((address, index) => (
                                <AddressItem
                                    key={index}
                                    address={address}
                                    setIsNewAddress={setIsNewAddress}
                                    setCurrentSelectedAddress={
                                        setCurrentSelectedAddress
                                    }
                                    setActive={setActive}
                                />
                            ))}
                        </ul>
                    </div>
                </>
            ) : isLoading ? (
                <div className="notFoundContent my-3 my-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="my-2"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={200}
                            width={200}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Loading Addresses...
                        </p>
                    </div>
                </div>
            ) : (
                hasErrorFetchingAddresses && (
                    <div className="notFoundContent my-2 my-md-5 d-flex align-items-center justify-content-center">
                        <p
                            className="not-found-text mx-4"
                            style={{ color: "#e50119" }}
                        >
                            Sorry, we could not retrieve your addresses at this
                            time.
                            <br />
                            Please try again later.
                        </p>
                    </div>
                )
            )}
        </>
    ) : active === "editAddress" ? (
        <EditAddressForm
            currentSelectedAddress={currentSelectedAddress}
            setActive={setActive}
        />
    ) : (
        active === "addAddress" && (
            <AddressForm
                currentSelectedAddress={currentSelectedAddress}
                setActive={setActive}
            />
        )
    )
    }
</div>
)
};

export default AddressList;
