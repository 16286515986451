import React, { useState } from "react";
import { isObjEmpty } from "../../Utility/Utils";
import "./ProductView.scss";
import CommonDialog from "../Dialog/CommonDialog";
import SlimResellerProduct from "../ProductDetails/ResellerProduct/SlimResellerProduct";

/**
 *
 * @param {product} must have productName, brand, ImageName, and productGuid
 * @returns
 */
function ProductView({ product, fetchAllMyStock }) {
    const [productDetails, setProductDetails] = useState({});
    const [isViewingProduct, setIsViewingProduct] = useState(false);

    const handleViewProduct = (product) => {
        setProductDetails({
            bIsConditionNew: product.bIsConditionNew,
            bIsMinestar: false,
            brand: product.brand,
            iProductId: product.iProductId,
            iProductResellerId: product.iProductResellerId,
            imageName: product.imageName,
            productGuid: product.productGuid,
            productName: product.productName,
            quantity: product.Quantity,
            sellingPrice: product.sellerPrice,
            treadPattern: product.treadPattern,
        });

        setIsViewingProduct(true);
    };

    const handleCloseProduct = () => {
        setProductDetails({});
        setIsViewingProduct(false);
    };

    const handleClickCloseModal = () => {
        setIsViewingProduct(false);
        setProductDetails({});
        const abortController = new AbortController();
        fetchAllMyStock(abortController);
    };

    return (
        <>
            <CommonDialog
                title={"Product Details"}
                open={isViewingProduct}
                onClose={handleCloseProduct}
            >
                {!isObjEmpty(productDetails) ? (
                    <SlimResellerProduct
                        key={1}
                        product={productDetails}
                        onClose={handleClickCloseModal}
                    />
                ) : (
                    <>
                        Sorry, we could not retrieve this product's details at
                        this time. Please try again later.
                    </>
                )}
            </CommonDialog>
            <div
                className="product-name"
                onClick={() => handleViewProduct(product)}
            >
                {product?.productName}
            </div>
        </>
    );
}

export default ProductView;
