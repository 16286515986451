import { useState } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { useNavigate } from "react-router";
import { API } from "../../../Utility/ApiUrlPath";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { isObjEmpty } from "../../../Utility/Utils";

toast.configure();

const useForm = (validate, setIsLoading) => {
    const navigate = useNavigate();

    const [values, setValues] = useState({
        email: "",
        consentApproval: false,
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            const { name, checked } = e.target;
            setValues({ ...values, [name]: checked });
        } else {
            const { name, value } = e.target;
            setValues({ ...values, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = validate(values);
        setErrors(errors);

        if (isObjEmpty(errors)) {
            var subscriberInfo = {
                strEmailAddress: values.email.trim(),
            };

            setIsLoading(true);
            //send subscriber data to the server
            fetch(`${API.QA_URL}api/v1/users/subscribe`, {
                method: "POST",
                body: JSON.stringify(subscriberInfo),
                headers: {
                    "Content-Type": "application/json",
                    "minestar-api-token": "xyz",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    setIsLoading(false);
                    if (responseJson.bIsSubscribed) {
                        setValues({
                            email: "",
                            consentApproval: false,
                        });

                        if (responseJson.bIsAlreadySubscribed) {
                            toast.success("You have already subscribed.", {
                                autoClose: toastrTime,
                            });
                        } else {
                            toast.success("You have successfully subscribed.", {
                                autoClose: toastrTime,
                            });
                        }
                        setErrors({});
                        setValues({ email: "", consentApproval: false });
                        navigate(0);
                    } else {
                        if (!responseJson.bIsEmailSent) {
                            toast.error(
                                "We could not send you an email verification. Please try again later.",
                                { autoClose: toastrTime }
                            );
                        } else {
                            toast.error(
                                "Please try again, make sure your email is valid.",
                                { autoClose: toastrTime }
                            );
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    toast.error(
                        "Something went wrong. Please try again later.",
                        { autoClose: toastrTime }
                    );
                });
        }
    };

    return { handleChange, handleSubmit, values, errors };
};

export default useForm;
