import React from "react";

// Importing components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontSize: 18,
        fontFamily: "Montserrat",
        fontWeight: 600,
    },
});

function FAQ() {
    return (
        <div className="d-flex flex-column align-items-center bg-grey py-3">
            <h2 className="cart-faq py-md-4 mx-3 mx-md-0 my-3 title-xl">
                Frequently Asked Questions
            </h2>
            <ThemeProvider theme={theme}>
                <div className="pb-md-5">
                    <div className="d-flex py-md-4 col-12 col-md-10 m-auto flex-wrap px-0 px-md-2">
                        <div className="col-12 col-md-6">
                            <Accordion
                                sx={{
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Can I return my order?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Order can be returned in new condition
                                        within 30 days for a 15% restocking fee.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="col-12 col-md-6">
                            <Accordion
                                sx={{
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                        }}
                                    >
                                        How long does it take for orders to
                                        arrive?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Our team will contact you regarding
                                        delivery details.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div className="d-flex py-md-4 col-12 col-md-10 m-auto flex-wrap px-0 px-md-2">
                        <div className="col-12 col-md-6">
                            <Accordion
                                sx={{
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                        }}
                                    >
                                        How much does shipping cost?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Our team will contact you regarding
                                        shipping details.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default FAQ;
