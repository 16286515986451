import React from "react";
import { Link } from "react-router-dom";

import "./Errors.scss";

import truck from "../../Assests/404/truck_404.png";

const PageNotFound = () => {
    return (
        <>
            <div id="not-found-container">
                <div
                    id="not-found-content"
                    className="d-flex flex-column align-items-center justify-content-start"
                >
                    <span id="not-found-header" className="my-0 py-0">
                        404
                    </span>

                    <span id="not-found-text" className="text-center">
                        We can't seem to find the page you're looking for.
                    </span>

                    <Link to="/" style={{ textDecoration: "none" }}>
                        <button className="my-4" id="not-found-redirect-btn">
                            Home
                        </button>
                    </Link>

                    <img
                        className="not-found-truck mt-2"
                        src={truck}
                        alt="Truck Stuck"
                    />
                </div>
            </div>
        </>
    );
};

export default PageNotFound;
