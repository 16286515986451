/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function PostFooter({ handleClickOpenTerms, handleClickOpenPrivacy }) {
    return (
        <div className="post-footer py-2 px-4">
            <div className="row mx-1 mx-md-3">
                <div>
                    <a
                        className="post-footer_links hoverable"
                        onClick={handleClickOpenTerms}
                    >
                        &nbsp;&nbsp;Terms & Conditions&nbsp;&nbsp;
                    </a>
                </div>
                {" | "}
                <div>
                    <a
                        className="post-footer_links hoverable"
                        onClick={handleClickOpenPrivacy}
                    >
                        &nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;
                    </a>
                </div>
                {" | "}
                <div>
                    <span className="post-footer_links">
                        &nbsp;&nbsp;Minestar ©&nbsp;&nbsp;
                    </span>
                </div>
                {" | "}
                <div>
                    <span className="post-footer_links hoverable">
                        &nbsp;&nbsp;
                        <a
                            href="https://softserve.co.za/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Developed by Softserve
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default PostFooter;
