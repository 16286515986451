import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestForm from "../Forms/RequestForm/RequestForm";
import "../../Pages/ProductsPage/ProductsPage.scss";
import "./ProductDetails.scss";
import { isObjEmpty } from "../../Utility/Utils";
import ResellerProduct from "./ResellerProduct/ResellerProduct";
import defaultTyre from "../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import RemoveBrandFromProductName from "../../Utility/Functions/RemoveBrandFromProductName";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
    selectCart,
    addItem,
    changeQty,
} from "../../Redux/Features/Cart/cartSlice";

// Modal
import CommonDialog from "../Dialog/CommonDialog";
import ProductQuantityControl from "../ProductQuantityControl/ProductQuantityControl";

const ProductDetails = (props) => {
    const { resellerProducts } = props;
    const { onClose } = props;
    const [modalVisibility, setModalVisibility] = useState(false);

    const [radioOption, setRadioOption] = useState("specifications"); // Other options is "downloads"
    const handleRadioChange = (event) => {
        setRadioOption(event.target.value);
    };

    const cart = useSelector(selectCart);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleGoToCart = () => {
        navigate("/cart");
    };

    const AddToCart = (e, currentCartItem) => {
        e.preventDefault();

        if (qty > 0) {
            if (cart.cartList?.length > 0) {
                const alreadyInCart = cart.cartList.filter(
                    (x) =>
                        x.iProductResellerId ===
                        currentCartItem.iProductResellerId
                );
                if (alreadyInCart?.length === 0) {
                    dispatch(
                        addItem(
                            currentCartItem,
                            qty,
                            resellerProducts?.strFilePathForImage
                        )
                    );
                    onClose();
                    toast.success(({ closeToast }) => (
                        <div
                            className="go-to-cart-btn"
                            onClick={handleGoToCart}
                        >
                            Go to Cart
                        </div>
                    ));
                } else {
                    toast.error(
                        ({ closeToast }) => (
                            <div
                                className="go-to-cart-btn"
                                onClick={handleGoToCart}
                            >
                                Already in Cart
                            </div>
                        ),
                        {
                            autoClose: 1000,
                        }
                    );
                }
            } else {
                dispatch(
                    addItem(
                        currentCartItem,
                        qty,
                        resellerProducts?.strFilePathForImage
                    )
                );
                onClose();
                toast.success(({ closeToast }) => (
                    <div className="go-to-cart-btn" onClick={handleGoToCart}>
                        Go to Cart
                    </div>
                ));
            }
        } else {
            toast.error(({ closeToast }) => <>Quantity cannot be zero</>, {
                autoClose: 2000,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setModalVisibility(false);
    };

    // Modal
    const [open, setOpen] = useState(false);
    const [reseller, setReseller] = useState({});

    const handleClickOpenModal = (e, reseller) => {
        setOpen(true);
        setReseller(reseller);
    };

    const handleClickCloseModal = () => {
        setOpen(false);
    };

    const [mainProduct, setMainProduct] = useState({});
    const [sortedProductsList, setSortedProductsList] = useState([]);
    useLayoutEffect(() => {
        const productList = resellerProducts?.productResellersList?.filter(
            (x) => x.iProductQuantity > 0 && x.dblItemPrice > 0
        );

        if (productList.length > 0) {
            setMainProduct(productList[0]);
            setSortedProductsList(productList.slice(1));
        }
    }, []);

    // Product quantity controls
    const [qty, setQty] = useState(0); // Start off with 1 item to add to cart.

    const handleIncrement = (currentProduct) => {
        if (qty + 1 <= currentProduct.iProductQuantity) {
            setQty(qty + 1);
            dispatch(changeQty(currentProduct.iProductResellerId, qty + 1));
        }
    };
    const handleDecrement = (currentProduct) => {
        if (qty > 0) {
            setQty(qty - 1);
            dispatch(changeQty(currentProduct.iProductResellerId, qty - 1));
        }
    };
    const handleChange = (e, currentProduct) => {
        if (e.target.value <= currentProduct.iProductQuantity) {
            setQty(e.target.value);
            dispatch(
                changeQty(currentProduct.iProductResellerId, e.target.value)
            );
        }
    };

    const displayCategories = (categories) => {
        // categories is a list of objects: [({name: "Mining"})]
        const mappedCategories = categories.map((category) =>
            category.name.trim()
        );

        const uniqueCategories = mappedCategories
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((category) => (
                <>
                    {category} <br />
                </>
            ));
        return uniqueCategories;
    };

    return (
        <>
            {/* Modal for showing reseller product details */}
            <CommonDialog
                title={"External Offer"}
                open={open}
                onClose={handleClickCloseModal}
            >
                <ResellerProduct
                    reseller={reseller}
                    newProductImage={resellerProducts?.strFilePathForImage}
                    onClose={handleClickCloseModal}
                />
            </CommonDialog>

            <div className="productDetails d-flex justify-content-center align-items-center">
                <div className="detailsContent">
                    <div className="productPricing d-flex flex-column align-items-center justify-content-center px-sm-3">
                        <div className="productDetailsContainer">
                            {isObjEmpty(mainProduct) ? (
                                <>
                                    <div className="productTitle row mx-0">
                                        <span className="col-12 px-0">
                                            {resellerProducts.brand +
                                                " " +
                                                resellerProducts.strTyreSize +
                                                " " +
                                                resellerProducts?.strTreadPattern}
                                        </span>
                                    </div>

                                    <span className="productBrand">
                                        {resellerProducts?.brand}
                                    </span>

                                    <span className="productStock">
                                        0 IN STOCK
                                    </span>
                                </>
                            ) : (
                                <>
                                    <div className="productTitle row mx-0">
                                        <h2 className="col-12 px-0">
                                            {RemoveBrandFromProductName(
                                                mainProduct?.productName
                                            )}
                                        </h2>
                                        <p className="col-12 px-0">
                                            {mainProduct?.strStockNumber}
                                        </p>
                                    </div>

                                    <h1 className="productBrand">
                                        {mainProduct?.brand}
                                    </h1>

                                    <span className="productStock m-1 d-flex align-items-center">
                                        {mainProduct?.iProductQuantity} IN STOCK
                                    </span>

                                    <h3 className="productPrice m-1">
                                        R {mainProduct?.dblItemPrice}
                                    </h3>

                                    <div className="cart-item-qty-control">
                                        <ProductQuantityControl
                                            qty={qty}
                                            handleDecrement={() =>
                                                handleDecrement(mainProduct)
                                            }
                                            handleIncrement={() =>
                                                handleIncrement(mainProduct)
                                            }
                                            handleChange={(e) =>
                                                handleChange(e, mainProduct)
                                            }
                                            cartItem={mainProduct}
                                        />
                                    </div>

                                    <button
                                        key={mainProduct?.iProductResellerId}
                                        className="addToCartBtn m-sm-1"
                                        onClick={(e) => {
                                            AddToCart(e, mainProduct);
                                        }}
                                    >
                                        ADD TO CART
                                    </button>
                                    <button
                                        className="bidOfferBtn m-sm-1"
                                        onClick={() =>
                                            setModalVisibility(!modalVisibility)
                                        }
                                    >
                                        {!modalVisibility
                                            ? "MAKE AN OFFER"
                                            : "CANCEL OFFER"}
                                    </button>
                                </>
                            )}
                            <div className="productImage">
                                <img
                                    className="productDetailImage"
                                    src={
                                        resellerProducts?.strFilePathForImage ===
                                            null ||
                                        resellerProducts?.strFilePathForImage ===
                                            ""
                                            ? defaultTyre
                                            : `data:image/png;base64,${resellerProducts?.strFilePathForImage}`
                                    }
                                    alt="Tyre"
                                />
                            </div>
                        </div>
                        <div>
                            {modalVisibility && (
                                <div className="modal">
                                    <form
                                        className="row biddingFormInputs mt-3 mb-3"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="col-12 col-md-7">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter amount"
                                            />
                                        </div>
                                        <div className="col-12 col-md-5 row mx-0 mt-2 mt-md-0">
                                            <button
                                                className="bidNowBtn add-address-btn col-12"
                                                type="submit"
                                            >
                                                Make Offer
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                        {sortedProductsList.length > 0 ? (
                            <div className="otherOffers mt-3 mt-md-4 p-2 p-sm-3">
                                <h3 className="col-12 py-2 pl-0">
                                    From External Suppliers
                                </h3>
                                <div className="d-flex other-offers-header">
                                    <h3 className="col-4 px-0 px-md-1 col-md-3">
                                        Price
                                    </h3>
                                    <h3 className="col-4 px-0 px-md-1 col-md-3 text-nowrap">
                                        In Stock
                                    </h3>
                                    <h3 className="col-4 col-md-3 reseller-condition">
                                        Condition
                                    </h3>
                                    {/*For the View Button*/}
                                    <h3 className="col-4 col-md-3"> </h3>
                                </div>

                                {sortedProductsList?.map((reseller) => (
                                    <div
                                        key={reseller.iProductResellerId}
                                        className="mb-1 mb-md-3"
                                    >
                                        <div className="otherOfferItem row mx-0 align-items-center mt-1">
                                            <p className="col-4 px-0 px-md-1 col-md-3">
                                                {reseller.dblItemPrice &&
                                                    "R" + reseller.dblItemPrice}
                                            </p>
                                            <p className="col-4 px-0 px-md-1 col-md-3 text-nowrap">
                                                {reseller.iProductQuantity}
                                            </p>
                                            <p className="col-4 col-md-3 text-nowrap reseller-condition">
                                                {reseller?.bIsConditionNew
                                                    ? "New"
                                                    : "2nd Hand" ?? "-"}
                                            </p>
                                            <div className="col-4 col-md-3 d-flex justify-content-center">
                                                <button
                                                    className="view-reseller-btn"
                                                    type="submit"
                                                    onClick={(e) => {
                                                        handleClickOpenModal(
                                                            e,
                                                            reseller
                                                        );
                                                    }}
                                                >
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                    <div className="productSpecification">
                        <div className="tabs mb-0">
                            <div className="tab-2">
                                <label
                                    htmlFor="tab2-1"
                                    className={
                                        radioOption === "specifications"
                                            ? "tabNav active-radio-btn"
                                            : "tabNav"
                                    }
                                >
                                    SPECIFICATIONS
                                </label>
                                <input
                                    id="tab2-1"
                                    name="tabs-two"
                                    type="radio"
                                    value="specifications"
                                    checked={radioOption === "specifications"}
                                    onChange={handleRadioChange}
                                />
                                <div className="tyreSpecifications p-1 px-md-4">
                                    {resellerProducts?.brand !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Brand</p>
                                            <span>
                                                {resellerProducts?.brand}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strTyreSize !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Size</p>
                                            <span>
                                                {resellerProducts?.strTyreSize}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strTreadPattern !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Tread pattern</p>
                                            <span>
                                                {
                                                    resellerProducts?.strTreadPattern
                                                }
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strSectional !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Sectional Width</p>
                                            <span>
                                                {resellerProducts?.strSectional +
                                                    " mm"}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strOverallDiameter !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Overall Diameter</p>
                                            <span>
                                                {resellerProducts?.strOverallDiameter +
                                                    " mm"}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strTreadDepth !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Tread depth</p>
                                            <span>
                                                {resellerProducts?.strTreadDepth +
                                                    " mm"}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strWeight !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Weight</p>
                                            <span>
                                                {resellerProducts?.strWeight}
                                                {" kg"}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strTKPH !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>TKPH</p>
                                            <span>
                                                {resellerProducts?.strTKPH}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.bIsConditionNew !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Condition</p>
                                            <span>
                                                {resellerProducts?.bIsConditionNew
                                                    ? "New"
                                                    : "2nd Hand"}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strCategory !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Application</p>
                                            <span className="category-list">
                                                {displayCategories(
                                                    resellerProducts?.Categories
                                                )}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strCompound !== null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Compound</p>
                                            <span>
                                                {resellerProducts?.strCompound}
                                            </span>
                                        </div>
                                    )}

                                    {resellerProducts?.strPlyRating !==
                                        null && (
                                        <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                            <p>Ply Rating/Star Rating</p>
                                            <span>
                                                {resellerProducts?.strPlyRating}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tab-2">
                                <label
                                    htmlFor="tab2-2"
                                    className={
                                        radioOption === "downloads"
                                            ? "tabNav active-radio-btn"
                                            : "tabNav"
                                    }
                                >
                                    DOWNLOADS
                                </label>
                                <input
                                    id="tab2-2"
                                    name="tabs-two"
                                    type="radio"
                                    value="downloads"
                                    checked={radioOption === "downloads"}
                                    onChange={handleRadioChange}
                                />
                                <div className="p-4">Downloads</div>
                            </div>
                        </div>
                    </div>
                    <div className="banner"></div>
                    <div className="request-form px-2 px-md-4">
                        {/* Request More Information */}
                        <RequestForm
                            onClose={onClose}
                            productName={`${resellerProducts.brand.trim()} ${resellerProducts.strTyreSize.trim()} ${resellerProducts.strTreadPattern.trim()}`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetails;
