import React, { createContext, useState, useMemo } from "react";

// actual value you want to access
export const UserContext = createContext({
    user: JSON.parse(localStorage.getItem("currentUser")),
    setUser: () => null,
});

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("currentUser")));
    const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);

    return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
};
