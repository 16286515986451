import React, { useEffect, useState } from "react";
import "./CookieConsent.scss";
import PrivacyPolicy from "../LegalDocs/PrivacyPolicy";
import CommonDialog from "../Dialog/CommonDialog";

const CoookieConsent = () => {
    const [hasCookies, setHasCookies] = useState(false);
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);

    useEffect(() => {
        checkForCookies();
    }, []);

    useEffect(() => {}, [hasCookies]);

    const checkForCookies = () => {
        var allCookies = document.cookie;
        var cookiesArray = allCookies.split("; ");
        if (cookiesArray.includes("CookieConsent=true")) {
            setHasCookies(true);
        } else {
            setHasCookies(false);
        }
    };

    const setCookies = () => {
        document.cookie = "CookieConsent=true";
        setHasCookies(true);
    };

    const togglePrivacyPolicy = () => {
        setOpenPrivacyPolicy(!openPrivacyPolicy);
    };

    return (
        <>
            {!hasCookies ? (
                <div className="cookies-container p-3">
                    <div className="title-xl my-2 text-bold">
                        This site uses cookies.
                    </div>
                    <div className="cookie-buttons">
                        <button
                            className="okay-button p-2"
                            onClick={setCookies}
                        >
                            Okay
                        </button>
                        <button
                            className="privacy-policy-button p-2"
                            onClick={togglePrivacyPolicy}
                        >
                            Privacy Policy
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <CommonDialog
                title={"Minestar Privacy Policy"}
                open={openPrivacyPolicy}
                onClose={togglePrivacyPolicy}
            >
                <PrivacyPolicy />
            </CommonDialog>
        </>
    );
};

export default CoookieConsent;
