/**
 * Gets all the products from the API.
 * @endpoint1 api/v1/products/get_all_products - gets all products that customers can buy.
 * @endpoint2 api/v1/products/get_all - gets products that can be added to reseller stock.
 * @endpoint3 api/v1/products/get_all_featured_products - gets all featured products.
 * @endpoint4 api/v1/products/get_all_product_filters - gets all filter options.
 * @param abortController - AbortController to use for the signal of the fetch API.
 * @returns Array of products.
 */
export default async function GetAllProducts(url, abortController) {
    let products = [];

    let response = await fetch(url, {
        method: "GET",
        mode: Request.mode,
        headers: {
            "Content-Type": "application/json",
            "minestar-api-token": "xyz",
        },
        signal: abortController.signal,
    });
    products = await response.json();

    return products;
}
