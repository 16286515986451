/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { NavLink, Outlet } from "react-router-dom";

import { UserContext } from "../../Contexts/user.context";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// Importing the css files
import "./AccountSettings.scss"; //styles exclusive to this page only

// Importing components
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../SEO/SEONoIndex";

function AccountSettings({ currentState = "profile" }) {
    const [active, setActive] = useState(() => currentState);
    const { user } = useContext(UserContext);

    // useEffect(() => {
    //     if (active !== "Profile") {
    //         // 👇️ scroll to top on page load
    //         window.scrollTo({ top: 320, left: 0, behavior: "smooth" });
    //     }
    // }, [active]);

    return (
        <div className="container">
            <SEONoIndex title="Account | Minestar South Africa" />
            <div className="account-settings-header">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="AccountSettingsPage" />
                    {/* End: The main navigation component */}

                    {/* account settings hero section  */}
                    <section className="homepage-hero">
                        <div className="row mx-0">
                            <div className="common-header ml-3 ml-md-5 pl-md-4">
                                <h1>Account Settings</h1>
                            </div>
                        </div>
                    </section>
                    {/* end of account settings hero section */}
                </header>
            </div>

            <div
                id="account-settings-page"
                className="row mx-0 mt-4 mt-lg-5 px-2 px-md-4 mb-sm-5 justify-content-center justify-content-md-center"
            >
                <div className="col-12 col-md-11 col-lg-3 p-2 sideMenuRound">
                    <div className="menu-items">
                        <NavLink
                            to="orders"
                            className={({ isActive }) =>
                                "web-nav-link" + (isActive ? " is-active" : "")
                            }
                        >
                            Orders
                        </NavLink>

                        {(user.role === "Reseller" ||
                            user.role === "Administrator") && (
                            <>
                                <NavLink
                                    to="mystock"
                                    className={({ isActive }) =>
                                        "web-nav-link" +
                                        (isActive ? " is-active" : "")
                                    }
                                >
                                    My Stock
                                </NavLink>

                                <NavLink
                                    id="dashboard-soldstock-btn"
                                    to="soldstock"
                                    className={({ isActive }) =>
                                        "web-nav-link" +
                                        (isActive ? " is-active" : "")
                                    }
                                >
                                    Sold Stock
                                </NavLink>

                                <NavLink
                                    to="addstock"
                                    className={({ isActive }) =>
                                        "web-nav-link" +
                                        (isActive ? " is-active" : "")
                                    }
                                >
                                    Add Stock
                                </NavLink>

                                <NavLink
                                    to="address"
                                    id="dashboard-address-btn"
                                    className={({ isActive }) =>
                                        "web-nav-link" +
                                        (isActive ? " is-active" : "")
                                    }
                                >
                                    Address
                                </NavLink>

                                <NavLink
                                    to="profile"
                                    id="dashboard-profile-btn"
                                    className={({ isActive }) =>
                                        "web-nav-link" +
                                        (isActive ? " is-active" : "")
                                    }
                                >
                                    Profile
                                </NavLink>
                            </>
                        )}

                        {user.role === "Customer" && (
                            <>
                                <NavLink
                                    to="profile"
                                    className={({ isActive }) =>
                                        "web-nav-link" +
                                        (isActive ? " is-active" : "")
                                    }
                                >
                                    Profile
                                </NavLink>

                                <NavLink
                                    to="address"
                                    className={({ isActive }) =>
                                        "web-nav-link" +
                                        (isActive ? " is-active" : "")
                                    }
                                >
                                    Address
                                </NavLink>
                            </>
                        )}

                        <NavLink
                            to="password"
                            id="dashboard-password-btn"
                            className={({ isActive }) =>
                                "web-nav-link" + (isActive ? " is-active" : "")
                            }
                        >
                            Password
                        </NavLink>
                    </div>
                </div>

                <div
                    className="col-12 col-lg-9 px-0 px-md-3 px-lg-5 mt-3 mt-lg-0"
                    id="form-section"
                >
                    {active === "profile" && <Outlet />}

                    {active === "password" && <Outlet />}

                    {active === "address" && <Outlet context={setActive} />}

                    {active === "orders" && <Outlet />}

                    {(user.role === "Administrator" ||
                        user.role === "Reseller") &&
                        active === "mystock" && <Outlet />}

                    {(user.role === "Administrator" ||
                        user.role === "Reseller") &&
                        active === "soldstock" && <Outlet />}

                    {(user.role === "Administrator" ||
                        user.role === "Reseller") &&
                        active === "addstock" && <Outlet />}
                </div>
            </div>
            {/* End: Account Settings */}

            {/* Beginning: footer */}
            <Footer />
            {/* End: footer */}
        </div>
    );
}

export default AccountSettings;
