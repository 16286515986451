/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { API } from "../../Utility/ApiUrlPath";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import RequestForm from "../Forms/RequestForm/RequestForm";
import "../../Pages/ProductsPage/ProductsPage.scss";
import "./ProductDetails.scss";
import { isObjEmpty } from "../../Utility/Utils";
import SlimResellerProduct from "./ResellerProduct/SlimResellerProduct";
import "../../Components/Errors/Errors.scss";
import MinestarLoader from "../../Assests/Animations/Minestar-Loader-Small.gif";

// Import Custom Functions
import RemoveBrandFromProductName from "../../Utility/Functions/RemoveBrandFromProductName";
import GetAllProducts from "../../Utility/Functions/GetAllProducts";
import PriceSplitter from "../../Utility/Functions/PriceSplitter";
import { scrollToSection } from "../../Utility/Utils";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
    selectCart,
    addItem,
    changeQty,
} from "../../Redux/Features/Cart/cartSlice";

// Components
import Navigation from "../../Components/Navigation/Navigation";
import Footer from "../../Components/Footer/Footer";

// Modal
import CommonDialog from "../Dialog/CommonDialog";
import ProductQuantityControl from "../ProductQuantityControl/ProductQuantityControl";

import useTyreImage from "../../Utility/hooks/useTyreImage";
import defaultTyre from "../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";
import MakeAnOfferForm from "../Forms/MakeAnOfferForm/MakeAnOfferForm";
import RenderPdfDownloads from "./Downloads/RenderPdfDownloads";

// Importing SEO component
import SEONoIndex from "../../Components/SEO/SEONoIndex";

function SlimProductDetails() {
    const {brand, name, tyre, id} = useParams();

    const { loading, error, image } = useTyreImage(
        brand,
        tyre + ".png"
    );

    const currentSection = useRef(null);

    // const [bidModalVisibility, setBidModalVisibility] = useState(false);

    const [radioOption, setRadioOption] = useState("specifications"); // Other options is "downloads"
    const handleRadioChange = (event) => {
        setRadioOption(event.target.value);
    };

    const cart = useSelector(selectCart);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleGoToCart = () => {
        navigate("/cart");
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setBidModalVisibility(false);
    // };

    // Modal
    const [open, setOpen] = useState(false);
    const [reseller, setReseller] = useState({});

    const [offerOpen, setOfferOpen] = useState(false);

    const handleClickOpenModal = (e, reseller) => {
        setOpen(true);
        setReseller(reseller);
    };

    const handleClickCloseModal = () => {
        setOpen(false);
        setReseller({});
        const abortController = new AbortController();
        fetchProductDetails(abortController);
    };

    const handleClickOpenOfferModal = (e) => {
        setOfferOpen(true);
    };

    const handleClickCloseOfferModal = () => {
        setOfferOpen(false);
    };

    const [productDetails, setProductDetails] = useState({});
    const [sortedProductsList, setSortedProductsList] = useState([]);
    const [mainProduct, setMainProduct] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingProducts, setHasErrorFetchingProducts] =
        useState(false);

    /**
     * Fetch Product details from API.
     */
    useEffect(() => {
        // 👇️ scroll to top on page load
        scrollToSection(currentSection);

        const abortController = new AbortController();
        fetchProductDetails(abortController);
        return () => abortController.abort();
    }, []);

    // 👇️ scroll to top on page load
    // useEffect(() => {
    //     scrollToSection(currentSection);
    // }, []);

    const fetchProductDetails = async (abortController) => {
        try {
            setIsLoading(true);
            setHasErrorFetchingProducts(false);

            const slimProduct = await GetAllProducts(
                `${API.QA_URL}api/v1/products/get_slim_product?productGuid=${id}`,
                abortController
            );

            if (
                slimProduct !== null &&
                slimProduct?.status !== 400 &&
                !isObjEmpty(slimProduct)
            ) {
                setProductDetails(slimProduct);
                const resellers = slimProduct.productResellersList;

                if (resellers !== null && resellers.length > 0) {
                    const mainProduct = resellers[0];
                    
                    if (!mainProduct.bIsConditionNew) {
                        try {
                            const response = await fetch(
                                `${API.QA_URL}api/v1/product_reseller/product_reseller_image?brandName=${mainProduct.brand}&treadPattern=${mainProduct.treadPattern}&iProductResellerId=${mainProduct.iProductResellerId}`,
                                {
                                    method: "GET",
                                    mode: Request.mode,
                                    headers: {
                                        "Content-Type": "application/json",
                                        "minestar-api-token": "xyz",
                                    },
                                }
                            );

                            if (
                                response &&
                                response.status >= 200 &&
                                response.status < 300
                            ) {
                                const image = await response.json();
                                
                                if (
                                    image?.productResellerImage64 !== null &&
                                    image.productResellerImage64 !== ""
                                ) {
                                    mainProduct.imageName = image.productResellerImage64;
                                }
                            }
                        } catch (error) {}
                    }
                    setMainProduct(mainProduct);
                }

                resellers.length > 1 &&
                    setSortedProductsList(
                        resellers.slice(1)
                        // .sort((reseller) =>
                        //     reseller.bIsConditionNew ? -1 : 1
                        // )
                        // .sort((reseller) => (reseller.bIsMinestar ? -1 : 1))
                    );

                setIsLoading(false);
                setHasErrorFetchingProducts(false);
            } else {
                setProductDetails({});
                setIsLoading(false);
                setHasErrorFetchingProducts(true);
            }
        } catch (error) {
            setProductDetails({});
            setIsLoading(false);
            setHasErrorFetchingProducts(true);
        }
    };

    // Product quantity controls
    const [qty, setQty] = useState(1);

    const handleIncrement = (currentProduct) => {
        if (qty + 1 <= currentProduct.quantity) {
            setQty(qty + 1);
            dispatch(changeQty(currentProduct.iProductResellerId, qty + 1));
        }
    };
    const handleDecrement = (currentProduct) => {
        if (qty > 0) {
            setQty(qty - 1);
            dispatch(changeQty(currentProduct.iProductResellerId, qty - 1));
        }
    };
    const handleChange = (e, currentProduct) => {
        if (e.target.value <= currentProduct.quantity) {
            setQty(e.target.value);
            dispatch(
                changeQty(currentProduct.iProductResellerId, e.target.value)
            );
        }
    };

    const displayCategories = (categories) => {
        // categories is a list of objects: [({name: "Mining"})]
        return (
            <div>
                {categories?.map((category, index) => {
                    return (
                        <div key={index}>
                            {category.name} <br />
                        </div>
                    );
                })}
            </div>
        );
    };

    const AddToCart = (e, currentCartItem) => {
        e.preventDefault();

        if (qty > 0) {
            if (cart.cartList?.length > 0) {
                const alreadyInCart = cart.cartList.filter(
                    (x) =>
                        x.iProductResellerId ===
                        currentCartItem.iProductResellerId
                );
                if (alreadyInCart?.length === 0) {
                    dispatch(
                        addItem(
                            currentCartItem,
                            qty,
                            currentCartItem?.imageName
                        )
                    );
                    //onClose();
                    toast.success(
                        ({ closeToast }) => (
                            <div
                                className="go-to-cart-btn"
                                onClick={handleGoToCart}
                            >
                                Go to Cart
                            </div>
                        ),
                        {
                            // autoClose: toastrTime,
                        }
                    );
                } else {
                    toast.error(
                        ({ closeToast }) => (
                            <div
                                className="go-to-cart-btn"
                                onClick={handleGoToCart}
                            >
                                Already in Cart
                            </div>
                        ),
                        {
                            autoClose: 1000,
                        }
                    );
                }
            } else {
                dispatch(
                    addItem(currentCartItem, qty, currentCartItem?.imageName)
                );
                //onClose();
                toast.success(
                    ({ closeToast }) => (
                        <div
                            className="go-to-cart-btn"
                            onClick={handleGoToCart}
                        >
                            Go to Cart
                        </div>
                    ),
                    {
                        // autoClose: toastrTime,
                    }
                );
            }
        } else {
            toast.error(({ closeToast }) => <>Quantity cannot be zero</>, {
                autoClose: 2000,
            });
        }
    };

    return (
    <div>
        <SEONoIndex title={"Tyre"}/>
        <div className="productsPageHeader">
                <header ref={currentSection} className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="ProductsPage" />
                    {/* End: The main navigation component */}
                </header>
        </div>
        <div>
        {!isLoading &&
        !hasErrorFetchingProducts &&
        productDetails &&
        !isObjEmpty(productDetails) ? (
            <>
            {/* Modal for showing reseller product details */}
            {open && (
                <CommonDialog
                    title={"External Offer"}
                    open={open}
                    onClose={handleClickCloseModal}
                >
                    <SlimResellerProduct
                        key={1}
                        product={reseller}
                        onCloseExternal={handleClickCloseModal}
                        //onCloseProduct={onClose}
                    />
                </CommonDialog>
            )}

            {offerOpen && (
                <CommonDialog
                    title={"Make An Offer"}
                    open={offerOpen}
                    onClose={handleClickCloseOfferModal}
                >
                    <MakeAnOfferForm
                        productName={mainProduct?.productName}
                        onClose={handleClickCloseOfferModal}
                    />
                </CommonDialog>
            )}

            <div className="productDetails d-flex justify-content-center align-items-center">
                <div className="detailsContent">
                    <div className="productPricing d-flex flex-column align-items-center justify-content-center px-sm-3 py-3 py-md-5">
                        <div className="productDetailsContainer">
                        {!mainProduct || isObjEmpty(mainProduct) ? (
                            <>
                                <div className="productTitle row mx-0">
                                    <h1 className="col-12 px-0 text-bold m-0">
                                        {productDetails?.tyreSize +
                                            " " +
                                            productDetails?.treadPattern}
                                    </h1>
                                </div>

                                <h2 className="productBrand">
                                    {productDetails?.brand}
                                </h2>

                                <span className="productStock">
                                    0 IN STOCK
                                </span>
                            </>
                        ) : (
                            <>
                                <div className="productTitle row mx-0">
                                    <h1 className="col-12 px-0 text-bold m-0">
                                        {RemoveBrandFromProductName(
                                            mainProduct?.productName
                                        )}
                                    </h1>
                                </div>

                                <h2 className="productBrand">
                                    {mainProduct?.brand}
                                </h2>

                                <span className="productStock m-1 d-flex align-items-center">
                                    {mainProduct?.quantity} IN STOCK
                                </span>

                                <p className="productPrice m-1">
                                    R{" "}
                                    {PriceSplitter(
                                        mainProduct?.sellingPrice
                                    )}
                                </p>

                                <div className="cart-item-qty-control">
                                    <ProductQuantityControl
                                        qty={qty}
                                        handleDecrement={() =>
                                            handleDecrement(mainProduct)
                                        }
                                        handleIncrement={() =>
                                            handleIncrement(mainProduct)
                                        }
                                        handleChange={(e) =>
                                            handleChange(e, mainProduct)
                                        }
                                        cartItem={mainProduct}
                                    />
                                </div>

                                <button
                                    key={mainProduct?.iProductResellerId}
                                    className="addToCartBtn m-sm-1"
                                    onClick={(e) => {
                                        AddToCart(e, mainProduct);
                                    }}
                                >
                                    ADD TO CART
                                </button>
                                <button
                                    className="bidOfferBtn m-sm-1"
                                    onClick={() =>
                                        // setBidModalVisibility(
                                        //     !bidModalVisibility
                                        // )
                                        handleClickOpenOfferModal()
                                    }
                                >
                                    {/* {!bidModalVisibility
                                        ? "MAKE AN OFFER"
                                        : "CANCEL OFFER"} */}
                                    MAKE AN OFFER
                                </button>
                            </>
                        )}
                        <div className="productImage">
                            <img
                                className="productDetailImage"
                                src={
                                    loading || error !== null
                                        ? defaultTyre
                                        : !isObjEmpty(mainProduct) 
                                        && !mainProduct.bIsConditionNew 
                                        && mainProduct.imageName !== ""
                                        && mainProduct.imageName !== null
                                            ? "data:image/png;base64, " 
                                            + mainProduct.imageName
                                            : image !== null
                                                ? image
                                                : defaultTyre
                                }
                                alt={
                                    loading || error !== null
                                        ? ""
                                        : !isObjEmpty(mainProduct) 
                                        && !mainProduct.bIsConditionNew 
                                        && mainProduct.imageName !== ""
                                        && mainProduct.imageName !== null
                                            ? mainProduct?.productName
                                            : image !== null
                                                ? mainProduct?.productName
                                                : ""
                                }
                            />
                        </div>
                    </div>
                    {/* <div>
                        {bidModalVisibility && (
                            <div className="modal">
                                <form
                                    className="row biddingFormInputs mt-3 mb-3"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="col-12 col-md-7">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter amount"
                                        />
                                    </div>
                                    <div className="col-12 col-md-5 row mx-0 mt-2 mt-md-0">
                                        <button
                                            className="bidNowBtn add-address-btn col-12"
                                            type="submit"
                                        >
                                            Make Offer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div> */}
                    {sortedProductsList.length > 0 ? (
                        <div className="otherOffers mt-3 mt-md-4 p-2 p-sm-3">
                            <h3 className="col-12 py-2 pl-0">
                                From External Suppliers
                            </h3>
                            <div className="d-flex other-offers-header">
                                <h3 className="col-4 px-0 px-md-1 col-md-3">
                                    Price
                                </h3>
                                <h3 className="col-4 px-0 px-md-1 col-md-3 text-nowrap">
                                    In Stock
                                </h3>
                                <h3 className="col-4 col-md-3 reseller-condition">
                                    Condition
                                </h3>
                                {/*For the View Button*/}
                                <h3 className="col-4 col-md-3"> </h3>
                            </div>

                            {sortedProductsList?.map((reseller) => (
                                <div
                                    key={reseller.iProductResellerId}
                                    className="mb-1 mb-md-3"
                                >
                                    <div className="otherOfferItem row mx-0 align-items-center mt-1">
                                        <p className="col-4 px-0 px-md-1 col-md-3">
                                            {reseller.sellingPrice &&
                                                "R" +
                                                    PriceSplitter(
                                                        reseller.sellingPrice
                                                    )}
                                        </p>
                                        <p className="col-4 px-0 px-md-1 col-md-3 text-nowrap">
                                            {reseller.quantity}
                                        </p>
                                        <p className="col-4 col-md-3 text-nowrap reseller-condition">
                                            {reseller?.bIsConditionNew
                                                ? "New"
                                                : "2nd Hand" ?? "-"}
                                        </p>
                                        <div className="col-4 col-md-3 d-flex justify-content-center">
                                            <button
                                                className="view-reseller-btn"
                                                type="submit"
                                                onClick={(e) => {
                                                    handleClickOpenModal(
                                                        e,
                                                        reseller
                                                    );
                                                }}
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className="productSpecification">
                    <div className="tabs mb-0">
                        <div className="tab-2">
                            <label
                                htmlFor="tab2-1"
                                className={
                                    radioOption === "specifications"
                                        ? "tabNav active-radio-btn"
                                        : "tabNav"
                                }
                            >
                                SPECIFICATIONS
                            </label>
                            <input
                                id="tab2-1"
                                name="tabs-two"
                                type="radio"
                                value="specifications"
                                checked={radioOption === "specifications"}
                                onChange={handleRadioChange}
                            />
                            <div className="tyreSpecifications p-1 px-md-4">
                                {productDetails?.brand !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Brand</p>
                                        <span>{productDetails?.brand}</span>
                                    </div>
                                )}

                                {productDetails?.tyreSize !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Size</p>
                                        <span>
                                            {productDetails?.tyreSize}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.treadPattern !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Tread pattern</p>
                                        <span>
                                            {productDetails?.treadPattern}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.sectionalWidth !==
                                    null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Sectional Width</p>
                                        <span>
                                            {productDetails?.sectionalWidth +
                                                " mm"}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.overallDiameter !==
                                    null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Overall Diameter</p>
                                        <span>
                                            {productDetails?.overallDiameter +
                                                " mm"}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.treadDepth !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Tread Depth</p>
                                        <span>
                                            {productDetails?.treadDepth +
                                                " mm"}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.weight !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Weight</p>
                                        <span>
                                            {productDetails?.weight}
                                            {" kg"}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.TKPH !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>TKPH</p>
                                        <span>{productDetails?.TKPH}</span>
                                    </div>
                                )}

                                {productDetails?.bIsConditionNew !==
                                    null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Condition</p>
                                        <span>
                                            {productDetails?.bIsConditionNew
                                                ? "New"
                                                : "2nd Hand"}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.categories !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Application</p>
                                        <span className="category-list">
                                            {productDetails?.categories &&
                                                displayCategories(
                                                    productDetails?.categories
                                                )}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.compound !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Compound</p>
                                        <span>
                                            {productDetails?.compound}
                                        </span>
                                    </div>
                                )}

                                {productDetails?.plyRating !== null && (
                                    <div className="specItem d-flex px-2 justify-content-between align-items-content py-3">
                                        <p>Ply Rating/Star Rating</p>
                                        <span>
                                            {productDetails?.plyRating}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-2">
                            <label
                                htmlFor="tab2-2"
                                className={
                                    radioOption === "downloads"
                                        ? "tabNav active-radio-btn"
                                        : "tabNav"
                                }
                            >
                                DOWNLOADS
                            </label>
                            <input
                                id="tab2-2"
                                name="tabs-two"
                                type="radio"
                                value="downloads"
                                checked={radioOption === "downloads"}
                                onChange={handleRadioChange}
                            />
                            <RenderPdfDownloads
                                brandName={productDetails.brand}
                                brandId={productDetails.brandId}
                            />
                            {/* {productDetails} */}
                        </div>
                    </div>
                </div>
                <div className="banner"></div>
                <div className="request-form px-2 px-md-4">
                    {/* Request More Information */}
                    <RequestForm
                        productName={`${productDetails?.brand} ${productDetails?.tyreSize} ${productDetails?.treadPattern}`}
                    />
                </div>
            </div>
        </div>
    </>
    ) : isLoading ? (
        <div className="notFoundContent my-3 my-md-5">
            <div className="d-flex flex-column align-items-center">
                <img
                    className="my-2"
                    src={MinestarLoader}
                    alt="Loading..."
                    height={200}
                    width={200}
                />
                <p className="not-found-text" style={{ color: "#e50119" }}>
                    Loading Product Details...
                </p>
            </div>
        </div>
    ) : (
        hasErrorFetchingProducts && (
            <div className="notFoundContent my-2 my-md-5 d-flex align-items-center justify-content-center">
                <p className="not-found-text mx-4" style={{ color: "#e50119" }}>
                    Sorry, we could not retrieve this product's details at this
                    time.
                    <br />
                    Please try again later.
                </p>
            </div>
        )
    )};
    </div>
    {/* footer */}
    <Footer />
    {/* End: footer */}
    </div>
    )
}

export default SlimProductDetails;