import React, { useState } from "react";
import "./Subscribe.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

//import components
import validate from "./ValidateSubscriberInfo";
import useForm from "./SubscribeuseForm";

const SubscribeForm = ({ handleClickOpenTerms }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { handleChange, handleSubmit, values, errors } = useForm(
        validate,
        setIsLoading
    );

    return (
        <>
            <div>
                <form onSubmit={handleSubmit} noValidate>
                    <div className="newsletter-input d-block d-md-flex align-items-center my-1">
                        <div className="input-field">
                            <input
                                className="input_newsletter"
                                id="newsletter"
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                value={values.email}
                                onChange={handleChange}
                            />
                            {errors.email && (
                                <p className="text-errors">{errors.email}</p>
                            )}
                        </div>
                        <div className="newsletter-submit-btn">
                            <button
                                disabled={isLoading ? true : false}
                                className="btn_newsletter mt-1 mt-md-0"
                                type="submit"
                            >
                                {isLoading ? (
                                    <>
                                        <FontAwesomeIcon
                                            className="btn-loader"
                                            icon={faSpinner}
                                        />
                                    </>
                                ) : (
                                    <>Subscribe</>
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="form-inputs ml-2">
                        <div className="consentField d-flex align-items-center">
                            <input
                                type="checkbox"
                                name="consentApproval"
                                id="consentApproval"
                                value={values.consentApproval}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="consentApproval"
                                className="agreement mb-0 ml-2"
                                onChange={handleChange}
                            >
                                I agree to the
                            </label>
                            <span
                                className="terms-and-conditions"
                                onClick={handleClickOpenTerms}
                            >
                                &nbsp;terms & conditions.
                            </span>{" "}
                        </div>
                        {errors.consentApproval && (
                            <p className="text-errors">
                                {errors.consentApproval}
                            </p>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default SubscribeForm;
