import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { isObjEmpty, keyExistsInObject } from "../../../Utility/Utils";
import { API } from "../../../Utility/ApiUrlPath";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const useForm = (validate, activeStockItem, onClose, setIsLoading) => {
    const [values, setValues] = useState({
        ProductResellerGuid: activeStockItem.ProductResellerGuid,
        AskingPrice: activeStockItem.AskingPrice,
        Quantity: activeStockItem.Quantity,
        bIsUsed: activeStockItem.bIsUsed,
        TreadDepth: activeStockItem.TreadDepth,
        ProductImage: activeStockItem?.ProductImage ?? null,
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let imageToUse = null;
        if (activeStockItem.ProductImage) {
            imageToUse =
                "data:image/jpeg;base64, " + activeStockItem.ProductImage;
        }

        setValues({
            ProductResellerGuid: activeStockItem.ProductResellerGuid,
            AskingPrice: activeStockItem.AskingPrice,
            Quantity: activeStockItem.Quantity,
            bIsUsed: activeStockItem.bIsUsed,
            TreadDepth: activeStockItem.TreadDepth,
            ProductImage: imageToUse,
        });
    }, [activeStockItem]);

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            const { name, checked } = e.target;
            if (name === "bIsUsed") {
                if (keyExistsInObject(values, "ProductImage")) {
                    // values.ProductImage = null;
                }
                if (keyExistsInObject(values, "TreadDepth")) {
                    values.TreadDepth = null;
                }
                setValues({ ...values, Quantity: 1, [name]: checked });
            } else {
                setValues({ ...values, [name]: checked });
            }
        } else {
            const { name, value } = e.target;

            if (name === "Quantity") {
                if (values.bIsUsed) {
                    setValues({ ...values, [name]: 1 });
                } else {
                    setValues({ ...values, [name]: value });
                }
            } else {
                setValues({ ...values, [name]: value });
            }
        }
    };

    async function handleImageChange(e) {
        try {
            var image = URL.createObjectURL(e.target.files[0]);
            const { name } = e.target;

            const dataUrl = await toDataURL(image);
            setValues({ ...values, [name]: dataUrl });
        } catch (error) {
            console.error(error);
        }
    }

    const toDataURL = async (url) => {
        const response = await fetch(url);
        if (response) {
            let blob = await response.blob();
            if (blob) {
                let promise = new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                });
                return promise;
            }
        }
    };

    const fetchCreateResellerStockItem = async (resellerStockItem) => {
        try {
            setIsLoading(true);
            let response = await fetch(
                `${API.QA_URL}api/v1/product_reseller/reapply`,
                {
                    method: "POST",
                    body: JSON.stringify(resellerStockItem),
                    headers: {
                        "Content-Type": "application/json",
                        "minestar-api-token": "xyz",
                    },
                }
            );

            const responseJson = await response.json();
            if (
                responseJson.status >= 200 &&
                responseJson.status <= 299 &&
                responseJson.isReapplied
            ) {
                onClose();
                toast.success("Reapplied Successfully.", {
                    autoClose: toastrTime,
                });
            }
            if (responseJson.status >= 400 && responseJson.status <= 499) {
                toast.error("Something went wrong. Please try again.", {
                    autoClose: toastrTime,
                });
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong. Please try again.", {
                autoClose: toastrTime,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var resellerStockItem = {
            ProductResellerGuid: activeStockItem.ProductResellerGuid,
            Quantity: parseInt(values.Quantity),
            AskingPrice: parseFloat(values.AskingPrice) ?? null,
            bIsConditionNew: values.bIsUsed ? false : true,
            TreadDepth: values.TreadDepth,
            ProductImage: values.ProductImage,
        };

        const errors = validate(values);
        setErrors(errors);
        let noErrors = isObjEmpty(errors);

        if (noErrors) {
            fetchCreateResellerStockItem(resellerStockItem);
        }
    };

    return { handleChange, handleImageChange, handleSubmit, values, errors };
};

export default useForm;
