export default function validateInfo(values) {
    let errors = {};
    if (!values.productResellerLists[0].quantity) {
        errors.quantity = "Quantity is required.";
    } else if (values.productResellerLists[0].quantity <= 0) {
        errors.quantity = "Quantity must be a positive number.";
    }

    if (!values.productResellerLists[0].price) {
        errors.price = "Price is required.";
    } else if (values.productResellerLists[0].price <= 0) {
        errors.price = "Price must be a positive number.";
    }

    if (values.productResellerLists[0].hasBeenUsed) {
        if (!values.productResellerLists[0].treadDepth) {
            errors.treadDepth = "Tread depth is required.";
        } else if (values.productResellerLists[0].treadDepth <= 0) {
            errors.treadDepth = "Tread depth must be a positive number.";
        }

        if (!values.productResellerLists[0].imageName) {
            errors.imageName = "Image is required.";
        }
    }

    return errors;
}
