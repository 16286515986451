import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { isObjEmpty } from "../../../Utility/Utils";
import { API } from "../../../Utility/ApiUrlPath";

import { UserContext } from "../../../Contexts/user.context";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

const EditAddressUseForm = (validate, userDetails, setActive) => {
    toast.configure();

    const { user } = useContext(UserContext);

    const [values, setValues] = useState({
        ...userDetails,
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            const { name, checked } = e.target;
            setValues({ ...values, [name]: checked });
        } else {
            const { name, value } = e.target;
            setValues({ ...values, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errorList = validate(values); // This is an object, not an array.
        setErrors(errorList);

        if (isObjEmpty(errorList)) {
            var userAddressInfo = {
                userGuid: user.userGuid,
                iUserInformationId: values.iUserInformationId,
                info: {
                    addressTitle: "", // TODO: Needs the backend to implement address title.
                    country: values.country,
                    addressLineOne: values.strAddressLineOne,
                    addressLineTwo: values.strAddressLineTwo,
                    province: values.province,
                    city: values.city,
                    postalCode: values.postalCode,
                    bIsDefault: values.bIsDefault,
                },
            };

            let response = null;
            response = await fetch(
                `${API.QA_URL}api/v1/user_information/update`,
                {
                    method: "POST",
                    body: JSON.stringify(userAddressInfo),
                    headers: {
                        "Content-Type": "application/json",
                        "minestar-api-token": "xyz",
                    },
                }
            );

            const responseJson = await response?.json();

            // eslint-disable-next-line eqeqeq
            if (responseJson.updated) {
                toast.success("Updated Address.", {
                    autoClose: toastrTime,
                });

                setActive("addressList");

                // eslint-disable-next-line eqeqeq
            } else if (responseJson.message == "Login failed.") {
                toast.error("Invalid credentials.", {
                    autoClose: toastrTime,
                });
            } else {
                toast.error("Something went wrong. Please try again.", {
                    autoClose: toastrTime,
                });
            }
        }
    };

    return { handleChange, handleSubmit, values, errors };
};

export default EditAddressUseForm;
