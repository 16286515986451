import React, { useState } from "react";
import "./CollectAddressList.scss";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import AddressRadioButton from "../../../../RadioButtons/AddressRadioButtons/AddressRadioButton";

const CollectAddressList = ({
    addressList,
    currentSelectedAddressState,
    setFinalAddress,
}) => {
    const [currentActiveAddress, setCurrentActiveAddress] = useState({});

    const radioChangeHandler = (address) => {
        setCurrentActiveAddress(address);
        currentSelectedAddressState.setCurrentSelectedAddress(address);
        setFinalAddress(address);
    };

    return (
        <div id="collect-address-list">
            {addressList.map((address, index) => (
                <AddressRadioButton
                    key={index}
                    id={index}
                    changed={() => {
                        radioChangeHandler(address);
                    }}
                    isSelected={
                        address.strAddressLineOne ===
                        currentActiveAddress.strAddressLineOne
                    }
                    value={address.strAddressLineOne}
                    address={address}
                />
            ))}
        </div>
    );
};

export default CollectAddressList;
