import React from "react";

// Importing components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontSize: 18,
        fontFamily: "Montserrat",
        fontWeight: 600,
    },
});

function ResellerFAQ() {
    return (
        <div className="d-flex flex-column align-items-center bg-grey py-3">
            <h2 className="cart-faq py-md-4 mx-3 mx-md-0 my-3 title-xl">
                Frequently Asked Questions
            </h2>
            <ThemeProvider theme={theme}>
                <div className="pb-md-5">
                    <div className="d-flex py-lg-4 col-12 col-md-10 m-auto flex-wrap px-0 px-md-2">
                        <div className="col-12 col-lg-6">
                            <Accordion
                                sx={{
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                        }}
                                    >
                                        What can I (reseller) do once I've
                                        registered?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Once you've registered on our platform,
                                        you can access features to help you
                                        manage your reselling business. You can
                                        browse through our selection of
                                        products, add your stock to our
                                        database, and view your stock that has
                                        been sold.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Accordion
                                sx={{
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                        }}
                                    >
                                        What happens after I've added my stock?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Our team will review your request and
                                        approve it. Once you are approved, your
                                        stock will be immediately available for
                                        customers to buy.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div className="d-flex py-lg-4 col-12 col-md-10 m-auto flex-wrap px-0 px-md-2">
                        <div className="col-12 col-lg-6">
                            <Accordion
                                sx={{
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                        }}
                                    >
                                        How does payment work?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Once a customer buys your stock you will
                                        be able to track your earnings under the
                                        account section of the website. Once the
                                        order has been processed successfully,
                                        payment for the sale will be transferred
                                        to your account.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default ResellerFAQ;
