import React, { useState, useEffect, useContext } from "react";
import { API } from "../../../../Utility/ApiUrlPath";
import { isObjEmpty } from "../../../../Utility/Utils";
import GetAllProducts from "../../../../Utility/Functions/GetAllProducts";
import MinestarLoader from "../../../../Assests/Animations/Minestar-Loader-Small.gif";
import "./OrderView.scss";
import OrderItemsList from "./OrderItemsList";

// Import Constants
import { OrderStatus } from "../../../../Utility/Constants/OrderStatus";

import { UserContext } from "../../../../Contexts/user.context";

function OrderView({ order = {} }) {
    const [isLoading, setIsLoading] = useState(false);
    const [hasErrorFetchingOrderDetails, setHasErrorFetchingOrderDetails] =
        useState(false);

    const { user } = useContext(UserContext);

    const [orderDetails, setOrderDetails] = useState({});

    /**
     * Fetch Order Details from API.
     */
    useEffect(() => {
        if (!isObjEmpty(order)) {
            const abortController = new AbortController();
            setIsLoading(true);
            setHasErrorFetchingOrderDetails(false);

            (async () => {
                try {
                    const data = await GetAllProducts(
                        `${API.QA_URL}api/v1/order/customer_order_details?gGuid=${order.iOrderGuid}&userGuid=${user.userGuid}`,
                        abortController
                    );

                    if (!isObjEmpty(data)) {
                        setOrderDetails(data);
                    }

                    setIsLoading(false);
                    setHasErrorFetchingOrderDetails(false);
                } catch (error) {
                    setIsLoading(false);
                    setHasErrorFetchingOrderDetails(true);
                }
            })();
            return () => abortController.abort();
        }
    }, []);

    // Order Status:
    // 0 - Processing
    // 1 - Shipped
    // 2 - Delivered
    function handleOrderStatus(status) {
        switch (status) {
            case OrderStatus.PROCESSING:
                return "Processing";
            case OrderStatus.SHIPPING:
                return "Shipped";
            case OrderStatus.DELIVERED:
                return "Delivered";
            default:
                return "";
        }
    }

    function handlePaymentMethod(method) {
        switch (method) {
            case 0:
                return "Paid with Ozow";
            case 1:
                return "Paid with Manual EFT";
            case 2:
                return "Paid on Account";
            default:
                return "";
        }
    }

    return (
        <>
            {!isLoading && !hasErrorFetchingOrderDetails ? (
                <div className="row flex-row justify-content-start mx-2 mx-md-4">
                    <div className="order-details col-12 col-md-6 justify-content-center mt-2 p-4">
                        <div className="row mx-0 justify-content-between mb-md-1">
                            <h2>Order Ref:</h2>
                            <h2>#{order.iOrderId}</h2>
                        </div>
                        <div className="row mx-0 justify-content-between mb-md-1">
                            <h2>Price:</h2>
                            <h2>R {order.dblTotalCost}</h2>
                        </div>
                        {orderDetails.bIsDelivery ? (
                            <div className="row mx-0 justify-content-between mb-md-1">
                                <h2>Order Status:</h2>
                                <h2>{handleOrderStatus(order.iOrderStatus)}</h2>
                            </div>
                        ) : null}
                        <div className="row mx-0 justify-content-between mb-md-1">
                            <h2>Delivery Method:</h2>
                            <h2>
                                {orderDetails.bIsDelivery
                                    ? "Delivery"
                                    : "Collect"}
                            </h2>
                        </div>
                        <div className="row mx-0 justify-content-between">
                            <h2>Payment Method:</h2>
                            <h2>
                                {handlePaymentMethod(
                                    orderDetails.paymentMethod
                                )}
                            </h2>
                        </div>
                    </div>
                    <div className="col-12 px-0">
                        <OrderItemsList
                            orderList={orderDetails.ordersModelList}
                        />
                    </div>
                </div>
            ) : isLoading ? (
                <div className="notFoundContent my-3 my-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="my-2"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={200}
                            width={200}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Loading Order Details...
                        </p>
                    </div>
                </div>
            ) : (
                hasErrorFetchingOrderDetails && (
                    <div className="notFoundContent my-2 my-md-5 d-flex align-items-center justify-content-center">
                        <p
                            className="not-found-text mx-4"
                            style={{ color: "#e50119" }}
                        >
                            Sorry, we could not retrieve your order details at
                            this time.
                            <br />
                            Please try again later.
                        </p>
                    </div>
                )
            )}
        </>
    );
}

export default OrderView;
