import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { API } from "../../../Utility/ApiUrlPath";
import validate from "./ValidateProfileInfo";
import useForm from "./ProfileuseForm";
import { isObjEmpty } from "../../../Utility/Utils";
import { UserContext } from "../../../Contexts/user.context";
// import MinestarLoader from "../../../Assests/Animations/Minestar-Loader.gif";
import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";

// Importing the css files
import "./ProfileForm.scss"; //styles exclusive to this page only

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../../SEO/SEONoIndex";

const ProfileForm = () => {
    const { user } = useContext(UserContext);

    const [disabled, setDisabled] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    const handleClick = (event) => {
        setIsEditing((current) => !current);
    };

    function handleDisable() {
        setDisabled(!disabled);
    }

    function editDetails() {
        handleClick();
        handleDisable();
    }

    function updateSuccessStatus() {
        handleClick();
        handleDisable();
        const abortController = new AbortController();
        fetchUserProfile(abortController);
    }

    const [profileValues, setProfileValues] = useState({
        firstname: "",
        lastname: "",
        email: "",
        number: "",
        strCompanyName: "",
        strCompanyRegNumber: "",
        strVatNumber: "",
    });

    // get user by userGuid:
    useEffect(() => {
        const abortController = new AbortController();
        fetchUserProfile(abortController);

        return () => abortController.abort();
    }, []);

    async function fetchUserProfile(abortController) {
        // get user information
        (async () => {
            try {
                const response = await fetch(
                    `${API.QA_URL}api/v1/users/get_single?gGuid=${user.userGuid}`,
                    {
                        method: "GET",
                        mode: Request.mode,
                        headers: {
                            "Content-Type": "application/json",
                            "minestar-api-token": "xyz",
                        },
                        singal: abortController.signal,
                    }
                );

                const userInformation = await response.json();

                if (!isObjEmpty(userInformation)) {
                    setProfileValues({
                        firstname: userInformation.firstName ?? "",
                        lastname: userInformation.lastName ?? "",
                        email: userInformation.emailAddress ?? "",
                        number: userInformation.contactNumber ?? "",
                        company: userInformation.companyName ?? "",
                        companyRegNumber:
                            userInformation.companyRegNumber ?? "",
                        vatNumber: userInformation.vatNumber ?? "",
                    });
                }
            } catch (error) {
                console.error(error);
                toast.error("Profile could not be loaded.", {
                    autoClose: toastrTime,
                });
            }
        })();
    }

    const { handleChange, handleSubmit, values, errors } = useForm(
        validate,
        profileValues,
        updateSuccessStatus
    );

    return (
        <div>
            <SEONoIndex title="Profile | Minestar South Africa" />
            {profileValues.firstname !== "" ? (
                <form
                    onSubmit={handleSubmit}
                    className="p-3 p-md-4"
                    id="profileForm"
                    noValidate
                >
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-2">
                                <label className="pl-1 mb-1 heading-small">
                                    First Name
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="firstname"
                                    placeholder="Joe"
                                    value={values.firstname || ""}
                                    onChange={handleChange}
                                    disabled={disabled}
                                />
                                {errors.firstname && (
                                    <p className="text-error">
                                        {errors.firstname}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-2">
                                <label className="pl-1 mb-1 heading-small">
                                    Last Name
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="lastname"
                                    placeholder="Soapington"
                                    value={values.lastname || ""}
                                    onChange={handleChange}
                                    disabled={disabled}
                                />
                                {errors.lastname && (
                                    <p className="text-error">
                                        {errors.lastname}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-2">
                                <label className="pl-1 mb-1 heading-small">
                                    Email
                                </label>
                                <input
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    placeholder="Joe@minestar.co.za"
                                    value={values.email || ""}
                                    onChange={handleChange}
                                    disabled={true}
                                />
                                {errors.email && (
                                    <p className="text-error">{errors.email}</p>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-2">
                                <label className="pl-1 mb-1 heading-small">
                                    Phone Number
                                </label>
                                <input
                                    className="form-control"
                                    id="number"
                                    type="number"
                                    name="number"
                                    placeholder="0712345678"
                                    value={values.number || ""}
                                    onChange={handleChange}
                                    disabled={disabled}
                                />
                                {errors.number && (
                                    <p className="text-error">
                                        {errors.number}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-2">
                                <label className="pl-1 mb-1 heading-small">
                                    Company
                                </label>
                                <input
                                    className="form-control"
                                    id="companyName"
                                    type="text"
                                    name="company"
                                    placeholder="Company"
                                    value={values.company || ""}
                                    onChange={handleChange}
                                    disabled={disabled}
                                />
                                {errors.company && (
                                    <p className="text-error">
                                        {errors.company}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-2">
                                <label className="pl-1 mb-1 heading-small">
                                    Company Reg. No
                                </label>
                                <input
                                    className="form-control"
                                    id="companyRegNumber"
                                    type="text"
                                    name="companyRegNumber"
                                    placeholder="2000/0000000/00"
                                    value={values.companyRegNumber || ""}
                                    onChange={handleChange}
                                    disabled={disabled}
                                />
                                {errors.companyRegNumber && (
                                    <p className="text-error">
                                        {errors.companyRegNumber}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-2">
                                <label className="pl-1 mb-1 heading-small">
                                    Vat Number
                                </label>
                                <input
                                    type="number"
                                    placeholder="000000000"
                                    className="form-control"
                                    id="vatNumber"
                                    name="vatNumber"
                                    value={values.vatNumber || ""}
                                    onChange={handleChange}
                                    disabled={disabled}
                                />
                                {errors.vatNumber && (
                                    <p className="text-error">
                                        {errors.vatNumber}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row flex-column flex-sm-row justify-content-sm-end">
                        <button
                            className="mb-2 mb-sm-0 mx-2"
                            id="submit-profile"
                            onClick={editDetails}
                            type="button"
                        >
                            {isEditing ? "Cancel" : "Edit"}
                        </button>
                        <button
                            className={isEditing ? "mx-2" : "d-none"}
                            id="submit-profile"
                            onClick={handleSubmit}
                            type="submit"
                        >
                            Save
                        </button>
                    </div>
                    {/* <div className="row mx-0 justify-content-end">
                        <button className=" " id="submit-profile" type="submit">
                            Save Changes
                        </button>
                    </div> */}
                </form>
            ) : (
                <div className="notFoundContent my-2 my-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="my-3"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={200}
                            width={200}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Loading Address...
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileForm;
