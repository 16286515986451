export default function validateRegisterInfo(values) {
    let errors = {};

    if (values.isSamePassword) {
        errors.newPassword =
            "New password must be different from old password.";
    }
    if (values.isWrongPassword && values.isWrongPassword === true) {
        errors.oldPassword = "Incorrect password.";
    }
    if (!values.oldPassword) {
        errors.oldPassword = "Password is required.";
    } else if (values.oldPassword.length < 6) {
        errors.oldPassword = "Password needs to be 6 characters or more.";
    }

    if (!values.newPassword) {
        errors.newPassword = "Password is required.";
    } else if (values.newPassword.length < 6) {
        errors.newPassword = "Password needs to be 6 characters or more.";
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = "Password confirmation is required.";
    } else if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = "Passwords do not match.";
    }
    return errors;
}
