import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faBox,
    faBoxOpen,
    faPlus,
    faShoppingCart,
    faUser,
    faSignInAlt,
    faSignOutAlt,
    faKey,
    faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../../Assests/Icons/main.png";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";

import { UserContext } from "../../../Contexts/user.context";

import "./MobileNav.scss";

// Redux
import { useSelector } from "react-redux";

const MobileNavigation = () => {
    //first create the navigation
    const Navigation = () => {
        const [showMenu, setShowMenu] = useState(false);
        const cartCount = useSelector((state) => state.cart.count);
        const { user, setUser } = useContext(UserContext);

        let navigate = useNavigate();
        let menu;
        let menuMask;

        function handleLogout() {
            if (user) {
                localStorage.removeItem("currentUser");
                setUser(null);
            }
            toast.success("Logged out", {
                autoClose: toastrTime,
            });
        }
        if (showMenu) {
            menu = (
                <div className="menuDiv">
                    <nav>
                        <img
                            className="mobileLogo"
                            src={Logo}
                            alt="Minestar South Africa"
                            onClick={() => navigate("/")}
                        />
                        <ul>
                            <li>
                                <NavLink
                                    className={({ isActive }) =>
                                        "menuLinks" +
                                        (isActive ? " is-active-nav-item" : "")
                                    }
                                    to="/products"
                                >
                                    Products
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className={({ isActive }) =>
                                        "menuLinks" +
                                        (isActive ? " is-active-nav-item" : "")
                                    }
                                    to="/about"
                                >
                                    About Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/contact"
                                    className={({ isActive }) =>
                                        "menuLinks" +
                                        (isActive ? " is-active-nav-item" : "")
                                    }
                                >
                                    Contact
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/reseller"
                                    className={({ isActive }) =>
                                        "menuLinks" +
                                        (isActive ? " is-active-nav-item" : "")
                                    }
                                >
                                    Reseller
                                </NavLink>
                            </li>
                            <li className="usertabs">
                                <NavLink
                                    className={({ isActive }) =>
                                        "mx-0" +
                                        (isActive ? " is-active-nav-item" : "")
                                    }
                                    to="/cart"
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faShoppingCart}
                                    />
                                    Cart ({cartCount})
                                </NavLink>
                            </li>
                            {user && (
                                <>
                                    <li className="usertabs">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            to="/accountsettings/profile"
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faUser}
                                            />
                                            Profile
                                        </NavLink>
                                    </li>
                                    <li className="usertabs">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            to="/accountsettings/password"
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faKey}
                                            />
                                            Password
                                        </NavLink>
                                    </li>
                                    <li className="usertabs">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            to="/accountsettings/address"
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faMapMarkerAlt}
                                            />
                                            Addresses
                                        </NavLink>
                                    </li>
                                    <li className="usertabs">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            to="/accountsettings/orders"
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faBox}
                                            />
                                            Orders
                                        </NavLink>
                                    </li>
                                    <li className="usertabs">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            to="/accountsettings/mystock"
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faBoxOpen}
                                            />
                                            My Stock
                                        </NavLink>
                                    </li>
                                    <li className="usertabs">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            to="/accountsettings/soldstock"
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faBoxOpen}
                                            />
                                            Sold Stock
                                        </NavLink>
                                    </li>
                                    <li className="usertabs">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            to="/accountsettings/addstock"
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faPlus}
                                            />
                                            Add Stock
                                        </NavLink>
                                    </li>
                                </>
                            )}
                            {user === null ? (
                                <li className="usertabs">
                                    <NavLink
                                        className={({ isActive }) =>
                                            "mx-0" +
                                            (isActive
                                                ? " is-active-nav-item"
                                                : "")
                                        }
                                        to="/login"
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faSignInAlt}
                                        />
                                        Sign in
                                    </NavLink>
                                </li>
                            ) : (
                                <li className="usertabs">
                                    <Link
                                        className="mx-0"
                                        to="/"
                                        onClick={(e) => handleLogout(e)}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faSignOutAlt}
                                        />
                                        Logout
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            );
            menuMask = (
                <div
                    className="menuMask"
                    onClick={() => setShowMenu(false)}
                ></div>
            );
        }

        return (
            <nav>
                <FontAwesomeIcon
                    icon={faBars}
                    className="navBarToggle"
                    onClick={() => setShowMenu(!showMenu)}
                />
                {menuMask}
                {menu}
            </nav>
        );
    };

    const MobileBottomNavigation = () => {
        return (
            <div className="bottom-nav-container">
                <NavLink
                    className={({ isActive }) =>
                        "menu-links home-bottom" +
                        (isActive ? " is-bottom-active-nav-item" : "")
                    }
                    to="/home"
                >
                    {/* <FontAwesomeIcon
                                className="bottom-nav-icons"
                                icon={faHome}
                            /> */}
                    Home
                </NavLink>

                <NavLink
                    id="mobile-bottom-middle"
                    className={({ isActive }) =>
                        "menu-links product-bottom" +
                        (isActive ? " is-bottom-active-nav-item" : "")
                    }
                    to="/products"
                >
                    {/* <FontAwesomeIcon
                                className="bottom-nav-icons"
                                icon={faBox}
                            /> */}
                    Products
                </NavLink>

                <NavLink
                    className={({ isActive }) =>
                        "menu-links cart-bottom" +
                        (isActive ? " is-bottom-active-nav-item" : "")
                    }
                    to="/cart"
                >
                    {/* <FontAwesomeIcon
                                className="bottom-nav-icons"
                                icon={faBox}
                            /> */}
                    Cart
                </NavLink>
            </div>
        );
    };

    //import the navigation intp the header
    const Header = () => {
        let navigate = useNavigate();

        return (
            <div className="mobileHeader">
                <img
                    className="mainLogo"
                    src={Logo}
                    alt="Minestar Logo"
                    onClick={() => navigate("/home")}
                />
                <Navigation />
                <MobileBottomNavigation />
            </div>
        );
    };

    return (
        <div>
            <Header />
        </div>
    );
};

export default MobileNavigation;
