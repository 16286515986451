import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";
import { API } from "../../../Utility/ApiUrlPath";

import { UserContext } from "../../../Contexts/user.context";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

const PasswordUseForm = (validate) => {
    const [values, setValues] = useState({
        isWrongPassword: false,
        isSamePassword: false,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({});

    const { user } = useContext(UserContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Check if the new password is the same as the old password
        if (
            values.strPassword?.trim() !== "" &&
            values.oldPassword?.trim() === values.newPassword?.trim()
        ) {
            setValues({ ...values, isSamePassword: true });
            // setIsSamePassword(true);
        } else if (
            values.newPassword?.trim() !== "" &&
            values.newPassword?.trim() === values.confirmPassword?.trim()
        ) {
            // At this point: new password is different from old password and the new and confirmed passwords are the same.
            var passwordInfo = {
                gGuid: user?.userGuid.trim(),
                strPassword: values.oldPassword?.trim().toString(),
                strNewPassword: values.newPassword?.trim().toString(),
            };

            fetch(`${API.QA_URL}api/v1/users/password_update`, {
                method: "POST",
                body: JSON.stringify(passwordInfo),
                mode: Request.mode,
                headers: {
                    "Content-Type": "application/json",
                    "minestar-api-token": "xyz",
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((responseJson) => {
                    if (responseJson === 2) {
                        setValues({
                            isWrongPassword: false,
                            isSamePassword: false,
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: "",
                        });
                        toast.success("Password updated successfully.", {
                            autoClose: toastrTime,
                        });
                    } else if (responseJson === 5) {
                        setValues({ ...values, isWrongPassword: true });
                    } else {
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Password could not be updated.", {
                        autoClose: toastrTime,
                    });
                });
        }
        // run validation checks and reset inputs to empty because the user submitted the form (successfully or not).
        setErrors(validate(values));
    };
    return { handleChange, handleSubmit, values, errors };
};

export default PasswordUseForm;
