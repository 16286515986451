/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utility/ApiUrlPath";

import "./BrandsAutoScroll.scss";

import GetAllProducts from "../../Utility/Functions/GetAllProducts";
import { isObjEmpty } from "../../Utility/Utils";

import SwiperCore, {
    Pagination,
    Navigation,
    Scrollbar,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

const BrandsAutoScroll = () => {
    const navigate = useNavigate();

    const [brands, setBrands] = useState(null);

    const handleSubmit = (value) => {
        let filterValues = {
            searchBySize: "",
            searchByBrand: value,
            searchByApplication: "",
        };

        // redirect to products page with search filter values
        navigate("/products", { state: filterValues });
    };

    // Fetch all the brands being used.
    useLayoutEffect(() => {
        const abortController = new AbortController();

        (async () => {
            try {
                const data = await GetAllProducts(
                    `${API.QA_URL}api/v1/brands/get_all`,
                    abortController
                );

                if (!isObjEmpty(data)) {
                    filterOutBrandsWithoutImages(data);
                }
            } catch (error) {
                setBrands(null);
            }
        })();
        return () => {
            abortController.abort();
        };
    }, []);

    // Removes brands that don't have images in the Assets/BrandsAutoScroll folder.
    async function filterOutBrandsWithoutImages(data) {
        let existingBrands = [];
        for (let x = 0; x < data.length; x++) {
            const brand = data[x];
            try {
                if (brand.brandName && brand.bHasProducts) {
                    const response = await import(
                        `../../Assests/BrandsAutoScroll/${brand.brandName
                            .trim()
                            .toLowerCase()}.png`
                    );

                    if (response) {
                        existingBrands.push({
                            ...brand,
                            image: response.default,
                        });
                    }
                }
            } catch (error) {
                continue;
            }
        }
        setBrands(existingBrands);
    }

    return (
        <>
            {brands !== null && (
                <Swiper
                    modules={[Pagination, Navigation, Scrollbar, Autoplay]}
                    navigation
                    speed={4000}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    scrollbar={{ draggable: true }}
                    spaceBetween={40}
                    breakpoints={{
                        1620: {
                            slidesPerView: 6,
                        },
                        1024: {
                            slidesPerView: 5,
                            // spaceBetween: 30,
                        },
                        720: {
                            slidesPerView: 3,
                            // spaceBetween: 30,
                        },
                        320: {
                            slidesPerView: 2,
                            // spaceBetween: 40,
                        },
                    }}
                    className="swiper-main-container"
                    observer
                >
                    {brands?.map((brand, index) => {
                        return (
                            <SwiperSlide
                                key={index}
                                className="swiper-slide-brands"
                            >
                                <div className="slide">
                                    <a
                                        onClick={async () => {
                                            handleSubmit(brand.brandName);
                                        }}
                                    >
                                        <img
                                            className="image-slide"
                                            src={brand.image}
                                            alt={brand.brandName}
                                        />
                                    </a>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}
        </>
    );
};

export default BrandsAutoScroll;
