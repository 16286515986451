import React from "react";

function DeclineMessage({
    declineMessage,
    handleStockRetry,
    retryItem,
    handleCloseDeclineMessage,
}) {
    return (
        <div>
            <div className="decline-message p-1 p-md-4">{declineMessage}</div>
            <button
                className="ml-2 ml-md-4 main-btn"
                onClick={() => {
                    handleStockRetry(retryItem);
                    handleCloseDeclineMessage();
                }}
            >
                Reapply
            </button>
        </div>
    );
}

export default DeclineMessage;
