/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useLayoutEffect } from "react";
import { API } from "../../Utility/ApiUrlPath";
import { isObjEmpty } from "../../Utility/Utils";

import "./FeaturedStock.scss";

// Import Custom Functions:
import GetAllProducts from "../../Utility/Functions/GetAllProducts";

// swiperstyles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

// import MinestarLoader from "../../Assests/Animations/Minestar-Loader.gif";
import MinestarLoader from "../../Assests/Animations/Minestar-Loader-Small.gif";
import CommonDialog from "../Dialog/CommonDialog";
import SlimProductDetails from "../ProductDetails/SlimProductDetails";
import FeaturedStockCard from "./FeaturedStockCard";

import SwiperCore, { Pagination, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar]);

const Featuredstock = () => {
    const [featuredStock, setFeaturedStock] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingProducts, setHasErrorFetchingProducts] =
        useState(false);

    const [open, setOpen] = useState(false);
    const [resellerProducts, setResellerProducts] = useState({});

    useLayoutEffect(() => {
        const abortController = new AbortController();
        fetchFeaturedProducts(abortController);
        return () => abortController.abort();
    }, []);

    const fetchFeaturedProducts = async (abortController) => {
        try {
            const data = await GetAllProducts(
                `${API.QA_URL}api/v1/products/get_all_featured_products`,
                abortController
            );

            if (!isObjEmpty(data)) {
                setFeaturedStock(data);
            }
            setIsLoading(false);
            setHasErrorFetchingProducts(false);
        } catch (error) {
            console.error(error);
            setHasErrorFetchingProducts(true);
            setIsLoading(false);
        }
    };

    const handleClickOpenModal = () => {
        setOpen(true);
    };
    const handleClickCloseModal = () => {
        const abortController = new AbortController();
        setOpen(false);
        setResellerProducts({});
        fetchFeaturedProducts(abortController);
    };

    return !isLoading &&
        !hasErrorFetchingProducts &&
        featuredStock.length > 0 ? (
        <div className="featured-stock-container">
            <div className="featuredStockBanner">
                <h2 className="bannerText">Featured Stock</h2>
            </div>
            {/* <CommonDialog
                title={"Featured Stock"}
                open={open}
                onClose={handleClickCloseModal}
            >
                {!isObjEmpty(resellerProducts) ? (
                    <SlimProductDetails
                        key={1}
                        product={resellerProducts}
                        onClose={handleClickCloseModal}
                    />
                ) : (
                    "No product selected"
                )}
            </CommonDialog> */}
            <Swiper
                modules={[Pagination, Navigation, Scrollbar]}
                navigation
                pagination={{
                    clickable: true,
                }}
                scrollbar={{ draggable: true }}
                spaceBetween={30}
                breakpoints={{
                    1620: {
                        slidesPerView: 5,
                    },
                    1450: {
                        slidesPerView: 4,
                    },
                    1024: {
                        slidesPerView: 3,
                        // spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 2,
                        // spaceBetween: 30,
                    },
                    320: {
                        slidesPerView: 1,
                        // spaceBetween: 40,
                    },
                }}
                className="swiper-stock-container"
                observer
            >
                {featuredStock &&
                    featuredStock.length > 0 &&
                    featuredStock.map((item, index) => {
                        return (
                            <SwiperSlide
                                key={index}
                                className="swiper-slide-stock"
                            >
                                <FeaturedStockCard
                                    product={item}
                                    setResellerProducts={setResellerProducts}
                                    handleClickOpenModal={handleClickOpenModal}
                                />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>
    ) : isLoading ? (
        <div className="featured-stock-container">
            <div className="featuredStockBanner">
                <p className="bannerText">Featured Stock</p>
            </div>
            <div className="notFoundContent my-2 my-md-3">
                <div className="d-flex flex-column align-items-center">
                    <img
                        className="my-5"
                        src={MinestarLoader}
                        alt="Loading..."
                        height={200}
                        width={200}
                    />
                    <p className="not-found-text" style={{ color: "#e50119" }}>
                        Loading Featured Stock...
                    </p>
                </div>
            </div>
        </div>
    ) : (
        hasErrorFetchingProducts && (
            <div>
                <div className="featured-stock-container">
                    <div className="featuredStockBanner">
                        <p className="bannerText">Featured Stock</p>
                    </div>
                    <div className="notFoundContent my-2 my-md-3 d-flex align-items-center justify-content-center">
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119", padding: "0em 1.5em" }}
                        >
                            Sorry, could not retrieve featured products at this
                            time.
                            <br /> Please try again later.
                        </p>
                    </div>
                </div>
            </div>
        )
    );
};

export default Featuredstock;
