import React from "react";

function StockStatus({ quantity }) {
    return (
        <>
            {quantity > 0 ? (
                <div className="has-stock">In Stock ({quantity})</div>
            ) : (
                <div className="out-of-stock">Out of Stock</div>
            )}
        </>
    );
}

export default StockStatus;
