/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from "react";
import { API } from "../../../../Utility/ApiUrlPath";
import { isObjEmpty } from "../../../../Utility/Utils";
import CollectAddressList from "./CollectAddressList/CollectAddressList";
import CollectAddressListMobile from "./CollectAddressListMobile/CollectAddressListMobile";
import "./CollectAddress.scss";

import "../../../../Components/Errors/Errors.scss";
// import MinestarLoader from "../../../../Assests/Animations/Minestar-Loader.gif";
import MinestarLoader from "../../../../Assests/Animations/Minestar-Loader-Small.gif";

// Import Function
import GetAllProducts from "../../../../Utility/Functions/GetAllProducts";

const CollectAddress = ({ setFinalAddress }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingProducts, setHasErrorFetchingProducts] =
        useState(false);

    const [currentSelectedAddress, setCurrentSelectedAddress] = useState(null);
    const [addressList, setAddressList] = useState([]);

    /**
     * Fetch All Products from API.
     */
    useLayoutEffect(() => {
        const abortController = new AbortController();
        setIsLoading(true);
        setHasErrorFetchingProducts(false);

        (async () => {
            try {
                const data = await GetAllProducts(
                    `${API.QA_URL}api/v1/order/collection_address`,
                    abortController
                );
                if (!isObjEmpty(data)) {
                    setAddressList(data.$values);
                }

                setIsLoading(false);
                setHasErrorFetchingProducts(false);
            } catch (error) {
                setAddressList([]);
                setIsLoading(false);
                setHasErrorFetchingProducts(true);
            }
        })();

        return () => abortController.abort();
    }, []);

    return (
        <>
            {!isLoading &&
            !hasErrorFetchingProducts &&
            addressList.length > 0 ? (
                <>
                    <div
                        id="collect-address-list-container"
                        className="mt-2 mt-md-4"
                    >
                        <h2 className="ml-1 ml-md-4 collect-heading">
                            Select Collection Address
                        </h2>

                        <div className="collection-address-list">
                            <CollectAddressList
                                addressList={addressList}
                                currentSelectedAddressState={{
                                    currentSelectedAddress,
                                    setCurrentSelectedAddress,
                                }}
                                setFinalAddress={setFinalAddress}
                            />
                        </div>

                        <div className="collection-address-list-mobile">
                            <CollectAddressListMobile
                                addressList={addressList}
                                currentSelectedAddressState={{
                                    currentSelectedAddress,
                                    setCurrentSelectedAddress,
                                }}
                                setFinalAddress={setFinalAddress}
                            />
                        </div>
                    </div>
                </>
            ) : isLoading ? (
                <div className="notFoundContent my-3 my-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="my-2"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={200}
                            width={200}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Loading Products...
                        </p>
                    </div>
                </div>
            ) : (
                hasErrorFetchingProducts && (
                    <div className="notFoundContent my-2 my-md-5 d-flex align-items-center justify-content-center">
                        <p
                            className="not-found-text mx-4"
                            style={{ color: "#e50119" }}
                        >
                            Sorry, we could not retrieve products at this time.
                            <br />
                            Please try again later.
                        </p>
                    </div>
                )
            )}
        </>
    );
};

export default CollectAddress;
