/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Card, CardBody } from "reactstrap";
import "../../../Pages/CartPage/Cart.scss";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// Import Functions
import RemoveBrandFromProductName from "../../../Utility/Functions/RemoveBrandFromProductName";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { selectCart } from "../../../Redux/Features/Cart/cartSlice";
import { changeQty, removeItem } from "../../../Redux/Features/Cart/cartSlice";

import ProductQuantityControl from "../../ProductQuantityControl/ProductQuantityControl";

import useTyreImage from "../../../Utility/hooks/useTyreImage";
import defaultTyre from "../../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";

const CartItem = ({ iProductResellerId }) => {
    const cart = useSelector(selectCart);
    const dispatch = useDispatch();

    let cartItem = cart.cartList?.find(
        (item) => item.iProductResellerId === iProductResellerId
    );

    const { loading, error, image } = useTyreImage(
        cartItem.brand,
        cartItem.imageName
    );

    const [itemTotal, setItemTotal] = useState(
        cartItem.iBuyerQuantity * cartItem.sellingPrice
    );

    const handleRemove = () => {
        dispatch(removeItem(cartItem.iProductResellerId));
        toast.success("Removed item from cart.", { autoClose: toastrTime });
    };
    const [qty, setQty] = useState(0);

    useEffect(() => {
        setQty(cartItem?.iBuyerQuantity);
    }, []);

    useLayoutEffect(() => {
        setItemTotal(cartItem.iBuyerQuantity * cartItem.sellingPrice);
    }, [qty]);

    const handleIncrement = () => {
        if (qty + 1 <= cartItem.quantity) {
            setQty(qty + 1);
            dispatch(changeQty(cartItem.iProductResellerId, qty + 1));
        }
    };
    const handleDecrement = () => {
        if (qty > 0) {
            setQty(qty - 1);
            dispatch(changeQty(cartItem.iProductResellerId, qty - 1));
        }
    };
    const handleChange = (e) => {
        if (e.target.value <= cartItem.quantity) {
            setQty(e.target.value);
            dispatch(changeQty(cartItem.iProductResellerId, e.target.value));
        }
    };

    return (
        <Card className="cart-item-container mb-3 mb-md-4 mx-auto">
            <CardBody>
                <div className="row mx-0 align-items-center justify-content-center py-4 px-1">
                    <div className="row mx-0 col-12 col-lg-7 px-0 align-items-center justify-content-around">
                        <div className="img col-12 col-lg-5 row align-items-center justify-content-center">
                            <img
                                src={
                                    loading || error !== null
                                        ? defaultTyre
                                        : image === null
                                        ? defaultTyre
                                        : image
                                }
                                alt="tyre"
                                className="tyre-image p-1"
                            />
                        </div>
                        <div className="tyre-details col-12 col-lg-7">
                            <span className="price-right ml-auto">
                                {"R" + (itemTotal === null ? "0" : itemTotal)}
                            </span>
                            <h4 className="tyre-name row mx-0">
                                <span>
                                    {RemoveBrandFromProductName(
                                        cartItem?.productName
                                    )}{" "}
                                    {cartItem?.bIsMinestar && " (Minestar)"}
                                </span>
                            </h4>
                            <span className="tyre-brand-name d-flex flex-wrap align-items-center">
                                By {cartItem?.brand}
                            </span>
                            <div className="tyre-qty-section row align-items-center justify-content-center justify-content-lg-start mt-2 mx-0">
                                <span className="mr-2">Qty:</span>
                                <ProductQuantityControl
                                    qty={qty}
                                    handleDecrement={handleDecrement}
                                    handleIncrement={handleIncrement}
                                    handleChange={handleChange}
                                    cartItem={cartItem}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 col-12 col-lg-4 px-2 mt-3 mt-lg-0 remove-cart-item justify-content-center align-content-center">
                        <span className="price-bottom">
                            {"R" + (itemTotal === null ? "0" : itemTotal)}
                        </span>
                        <button className="p-2" onClick={handleRemove}>
                            <span>X</span> Remove
                        </button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default CartItem;
