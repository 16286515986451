import React from "react";

// Import Icons
import deliveryIcon from "../../../Assests/Icons/delivery.png";
import collectIcon from "../../../Assests/Icons/collect.png";

import {
    DELIVER_OPTION,
    COLLECT_OPTION,
} from "../../../Utility/Constants/DeliveryOptions";

// Import CSS
import "./DeliveryOptions.scss";

function DeliveryOptions({ activeStepState, deliveryOptionState }) {
    return (
        <>
            <div
                id="delivery-options-container"
                className="d-flex flex-column align-items-center mt-2 mt-md-4"
            >
                <h2 className="delivery-heading align-self-start ml-1 ml-md-4 mb-3">
                    Choose Delivery Method
                </h2>
                <div
                    key={1}
                    className="delivery-option mb-3 mb-md-4 p-4 p-md-3"
                >
                    <div className="delivery-option-container d-block d-md-flex align-items-stretch">
                        <div className="section-1 d-block d-md-flex align-items-center pt-md-3 py-1 py-md-2 py-xl-5 mx-md-3 mx-lg-4">
                            <img
                                src={collectIcon}
                                alt="collect icon"
                                className="delivery-option-icon mx-auto mx-lg-0 my-2 my-lg-0"
                            />
                            <div className="collect-content ml-md-4">
                                <span>Collect</span>
                                <br />
                                <span className="delivery-option-description">
                                    Collect at branch. Open 7 days a week.
                                    {/* <br />  */}
                                </span>
                            </div>
                        </div>
                        <div className="delivery-option-btn-container d-flex align-items-center justify-content-center py-1 py-sm-2 py-xl-5">
                            <button
                                id="collect-btn"
                                className="section-2 delivery-option-btn mx-3"
                                onClick={() => {
                                    deliveryOptionState.setDeliveryOption(
                                        COLLECT_OPTION
                                    );
                                    activeStepState.setActiveStep(
                                        activeStepState.activeStep + 1
                                    );
                                }}
                            >
                                Collect My Order
                            </button>
                        </div>
                    </div>
                </div>
                <div key={2} className="delivery-option p-4 p-md-3">
                    <div className="delivery-option-container d-block d-md-flex align-items-stretch">
                        <div className="section-1 d-block d-md-flex align-items-center pt-md-3 py-1 py-md-2 py-xl-5 mx-md-3 mx-lg-4">
                            <img
                                src={deliveryIcon}
                                alt="deliver icon"
                                className="delivery-option-icon mx-auto mx-lg-0 my-2 my-lg-0"
                            />
                            <div className="deliver-content ml-md-4">
                                <span>Deliver</span>
                                <br />
                                <span className="delivery-option-description">
                                    Courier delivery to your door.
                                </span>
                            </div>
                        </div>
                        <div className="delivery-option-btn-container d-flex align-items-center justify-content-center py-1 py-sm-2 py-xl-5">
                            <button
                                id="deliver-btn"
                                className="section-2 delivery-option-btn mx-3"
                                onClick={() => {
                                    deliveryOptionState.setDeliveryOption(
                                        DELIVER_OPTION
                                    );
                                    activeStepState.setActiveStep(
                                        activeStepState.activeStep + 1
                                    );
                                }}
                            >
                                Deliver My Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeliveryOptions;
