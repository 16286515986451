import React, { useState, useContext } from "react";

import PaymentOptionMobile from "./PaymentOptionMobile";

import "./PaymentMobile.scss";

import { ACCOUNT_PAYMENT } from "../../../../Utility/PaymentOptions";

import { UserContext } from "../../../../Contexts/user.context";

function PaymentListMobile({
    paymentOptions,
    currentSelectedPaymentOptionState,
    setFinalPaymentOption,
}) {
    const { user } = useContext(UserContext);

    const [currentActivePaymentOption, setCurrentActivePaymentOption] =
        useState("");

    const handlePaymentChange = (paymentOption) => {
        setCurrentActivePaymentOption(paymentOption);
        currentSelectedPaymentOptionState.setCurrentSelectedPaymentOption(
            paymentOption
        );
        setFinalPaymentOption(paymentOption);
    };

    return (
        <div className="px-0">
            <div id="payment-list" className="px-0">
                {paymentOptions
                    .filter((paymentOption) => {
                        if (
                            paymentOption === ACCOUNT_PAYMENT &&
                            user.hasAccount
                        ) {
                            return true;
                        }
                        if (paymentOption !== ACCOUNT_PAYMENT) {
                            return true;
                        }
                        return false;
                    })
                    .map((paymentOption, index) => {
                        return (
                            <PaymentOptionMobile
                                key={index}
                                paymentOption={paymentOption}
                                currentActivePaymentOption={
                                    currentActivePaymentOption
                                }
                                handlePaymentChange={handlePaymentChange}
                            />
                        );
                    })}
            </div>
        </div>
    );
}

export default PaymentListMobile;
