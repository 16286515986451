import React from "react";

function AddressMobileItem({
    addressChangeHandler,
    address,
    currentActiveAddress,
    isLoading,
}) {
    return (
        <div className="delivery-mobile-item py-3 mb-3">
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between px-2">
                <div className="d-flex flex-column">
                    {address?.bIsDefault ? (
                        <div>
                            {address?.strAddressTitle !== null &&
                                address?.strAddressTitle}
                        </div>
                    ) : (
                        <div>
                            {address?.strAddressTitle !== null
                                ? address?.strAddressTitle
                                : ""}
                        </div>
                    )}

                    <span>{address?.strAddressLineOne}</span>
                    <span>{address?.strAddressLineTwo}</span>
                    <span>{address?.strCity}</span>
                </div>
                {isLoading && address.bIsDefault ? (
                    <button
                        onClick={() => {
                            addressChangeHandler(address);
                        }}
                        className="selected-outline-btn mt-1"
                    >
                        Selected
                    </button>
                ) : address.iUserInformationId ===
                  currentActiveAddress.iUserInformationId ? (
                    <button
                        onClick={() => {
                            addressChangeHandler(address);
                        }}
                        className="selected-outline-btn mt-1"
                    >
                        Selected
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            addressChangeHandler(address);
                        }}
                        className="select-option-btn mt-1"
                    >
                        Select
                    </button>
                )}
            </div>
        </div>
    );
}

export default AddressMobileItem;
