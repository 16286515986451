import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useContext,
    useRef,
} from "react";
import { API } from "../../Utility/ApiUrlPath";
import { toast } from "react-toastify";
import ScrollToTop from "react-scroll-to-top";
import { isObjEmpty } from "../../Utility/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

// Import Utils
import { scrollToSection } from "../../Utility/Utils";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// Importing the css files
import "./ProductsPage.scss";

import "../../Components/Errors/Errors.scss";
import MinestarLoader from "../../Assests/Animations/Minestar-Loader-Small.gif";

// Importing components
import Navigation from "../../Components/Navigation/Navigation";
import FilterSection from "./FilterSection";
import SlimProductDetails from "../../Components/ProductDetails/SlimProductDetails";
import Footer from "../../Components/Footer/Footer";

// Import Custom Functions
import GetAllProducts from "../../Utility/Functions/GetAllProducts";

// Import Custom Hooks
import useWindowDimensions from "../../Utility/hooks/useWindowDimensions";

import { ProductListContext } from "../../Contexts/product_list.context";

/**
 * Import Modal Components
 */
import CommonDialog from "../../Components/Dialog/CommonDialog";
import SlimProductCard from "./SlimProductCard";

// Importing SEO component
import SEONoIndex from "../../Components/SEO/SEONoIndex";

//start ProductsPage
const SlimProducts = () => {
    let doFilterFromHomepage = useRef(false);
    const currentSection = useRef(null);
    const usedFilter = useRef(false);

    // getDimensions of the screen
    const MOBILE_WIDTH = 720;
    const { width } = useWindowDimensions();

    const { productList } = useContext(ProductListContext);

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingProducts, setHasErrorFetchingProducts] =
        useState(false);

    //views
    const [displayView, setDisplayView] = useState(true);

    //display data
    const [productsList, setProductsList] = useState(productList); // List of products.
    const [productsToDisplay, setProductsToDisplay] = useState([]); // List of products, could be filtered.

    //props to products details
    const [selectedProduct, setSelectedProduct] = useState(null);

    //handle search values
    const [searchBySize, setSearchBySize] = useState("");
    const [searchByBrand, setSearchByBrand] = useState("");
    const [searchByApplication, setSearchByApplication] = useState("");

    // For Modal display
    const [open, setOpen] = useState(false);

    // Get filter values sent from HomePage
    const location = useLocation();
    let filterValues = location?.state ?? null;
    const isFilterValuesEmpty =
        filterValues !== null
            ? isObjEmpty(filterValues?.searchByBrand) &&
              isObjEmpty(filterValues?.searchBySize) &&
              isObjEmpty(filterValues?.searchByApplication)
            : true;

    /**
     * Fetch All Products from API.
     */
    useLayoutEffect(() => {
        const abortController = new AbortController();
        setIsLoading(true);
        setHasErrorFetchingProducts(false);

        (async () => {
            try {
                const data = await GetAllProducts(
                    `${API.QA_URL}api/v1/products/get_all_slim_products`,
                    abortController
                );

                if (!isObjEmpty(data)) {
                    setProductsList(data);

                    if (!isFilterValuesEmpty && doFilterFromHomepage.current) {
                        filterSearchValues(filterValues, data);
                    } else {
                        setProductsToDisplay(data);
                    }
                }
                setDisplayView(true);
                setIsLoading(false);
                setHasErrorFetchingProducts(false);
            } catch (error) {
                setIsLoading(false);
                setHasErrorFetchingProducts(true);
            }
        })();

        return () => abortController.abort();
    }, []);

    // Filter product list based on values obtained from HomePage
    useLayoutEffect(() => {
        if (isFilterValuesEmpty) {
            doFilterFromHomepage.current = false;
        } else {
            // filter stockData (a.k.a product list) with filterValues given:
            doFilterFromHomepage.current = true;
            setSearchByBrand(filterValues.searchByBrand);
            setSearchBySize(filterValues.searchBySize);
            setSearchByApplication(filterValues.searchByApplication);

            filterSearchValues(filterValues, productsList);
        }
    }, []);

    /**
     * Filters products based on @param.
     * @param {searchByBrand, searchBySize, searchByApplication} searchValues - Values used to filter through products.
     * @param productsToFilter - An array of products.
     */
    function filterSearchValues(
        { searchByBrand, searchBySize, searchByApplication },
        productsToFilter = []
    ) {
        if (productsToFilter.length === 0) return;
        setIsLoading(true);
        let searchResults = [];
        if (
            searchByBrand !== "" ||
            searchBySize !== "" ||
            searchByApplication !== ""
        ) {
            // Search by all 3 filters
            if (
                searchByBrand !== "" &&
                searchBySize !== "" &&
                searchByApplication !== ""
            ) {
                searchResults = productsToFilter.filter(
                    (x) =>
                        (x.brand === searchByBrand) &
                        (x.tyreSize === searchBySize) &
                        x.productCategories.some(
                            (b) => b.name === searchByApplication
                        )
                );
            } else {
                // Search by brand and size
                if (
                    searchByBrand !== "" ||
                    (searchBySize !== "" && searchByApplication === "")
                ) {
                    if (searchByBrand !== "" && searchBySize !== "") {
                        searchResults = productsToFilter.filter(
                            (x) =>
                                (x.brand === searchByBrand) &
                                (x.tyreSize === searchBySize)
                        );
                    } else {
                        searchResults = productsToFilter.filter(
                            (x) =>
                                x.brand === searchByBrand ||
                                x.tyreSize === searchBySize
                        );
                    }
                } else if (
                    searchByBrand === "" &&
                    (searchBySize !== "" || searchByApplication !== "")
                ) {
                    // Search by size and Application
                    if (searchByApplication !== "" && searchBySize !== "") {
                        searchResults = productsToFilter.filter(
                            (x) =>
                                (x.tyreSize === searchBySize) &
                                x.productCategories.some(
                                    (b) => b.name === searchByApplication
                                )
                        );
                    } else {
                        searchResults = productsToFilter.filter(
                            (x) =>
                                x.tyreSize === searchBySize ||
                                x.productCategories.some(
                                    (b) => b.name === searchByApplication
                                )
                        );
                    }
                } else if (
                    searchBySize === "" &&
                    (searchByBrand !== "" || searchByApplication !== "")
                ) {
                    // Search by Brand and Application
                    if (searchByBrand !== "" && searchByApplication !== "") {
                        searchResults = productsToFilter.filter(
                            (x) =>
                                (x.brand === searchByBrand) &
                                x.productCategories.some(
                                    (b) => b.name === searchByApplication
                                )
                        );
                    } else {
                        searchResults = productsToFilter.filter(
                            (x) =>
                                x.brand === searchByBrand ||
                                x.productCategories.some(
                                    (b) => b.name === searchByApplication
                                )
                        );
                    }
                }
            }
            setDisplayView(true);
            setProductsToDisplay(searchResults);
        } else {
            setProductsToDisplay(productsToFilter);
        }
        setIsLoading(false);
    }

    // 👇️ scroll to top on page load
    useEffect(() => {
        scrollToSection(currentSection);
    }, []);

    //dropdown filter
    const handleSubmit = (e) => {
        if (e !== null) {
            e !== null && e.preventDefault();
        }
        filterSearchValues(
            {
                searchBySize,
                searchByBrand,
                searchByApplication,
            },
            productsList
        );
        // window.scrollTo({ top: 250, left: 0, behavior: "smooth" });
        scrollToSection(currentSection);
    };

    function showWhatIsBeingSearched() {
        let result = ["Result:"];
        if (searchByBrand !== "") {
            result.push(searchByBrand.trim());
        }
        if (searchBySize !== "") {
            result.push(searchBySize.trim());
        }

        if (searchByApplication !== "") {
            result.push(searchByApplication.trim());
        }

        return `${result[0]} "${result.splice(1).join(" ")}"`;
    }

    const [isFilterOpen, setIsFilterOpen] = useState(width > MOBILE_WIDTH);
    const handleOpenFilters = () => {
        usedFilter.current = true;
        setIsFilterOpen(true);
    };
    const handleCloseFilters = () => {
        usedFilter.current = true;
        setIsFilterOpen(false);
    };

    toast.configure();

    useEffect(() => {
        if (!usedFilter.current) {
            setIsFilterOpen(width > MOBILE_WIDTH);
        }
    }, [width]);

    return (
        <div className="container">
            <SEONoIndex title={"Products"}/>
            <div className="productsPageHeader">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="ProductsPage" />
                    {/* End: The main navigation component */}
                </header>
            </div>
            <div ref={currentSection} className="banner">
                <h1 className="bannerText pl-2 pl-lg-5 m-0 text-bold">Products</h1>
            </div>

            {!isLoading && !hasErrorFetchingProducts && displayView ? (
                <>
                    <div className="filter-section">
                        {isFilterOpen ? (
                            <FilterSection
                                handleSubmit={handleSubmit}
                                brandState={{ searchByBrand, setSearchByBrand }}
                                sizeState={{ searchBySize, setSearchBySize }}
                                applicationState={{
                                    searchByApplication,
                                    setSearchByApplication,
                                }}
                                stockData={productsList}
                                filterSearchValues={filterSearchValues}
                                handleCloseFilters={handleCloseFilters}
                            />
                        ) : null}
                    </div>
                    <div className="row mx-0 showing-product">
                        {!isFilterOpen && (
                            <div
                                className="filter-container row mx-0 pr-4 justify-content-end"
                                onClick={() => {
                                    handleOpenFilters();
                                }}
                            >
                                <button className="filter-btn d-flex align-items-center mb-lg-4">
                                    <span className="mr-2">filter</span>
                                    <FontAwesomeIcon
                                        className="filter-icon"
                                        icon={faFilter}
                                    />
                                </button>
                            </div>
                        )}
                        {productsList.length !== productsToDisplay.length ? (
                            <div className="search-results-container ml-1 ml-md-5 my-2 my-md-3">
                                <div className="d-flex">
                                    <div>{showWhatIsBeingSearched()}</div>
                                    <div
                                        onClick={() => {
                                            setSearchByBrand("");
                                            setSearchBySize("");
                                            setSearchByApplication("");

                                            // Clears filters
                                            filterSearchValues(
                                                {
                                                    searchByBrand: "",
                                                    searchBySize: "",
                                                    searchByApplication: "",
                                                },
                                                productsList
                                            );
                                        }}
                                        className="ml-2 clear-filter-text"
                                    >
                                        clear
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="card-row mx-0 justify-content-center">
                            {productsToDisplay?.length > 0 ? (
                                productsToDisplay
                                    ?.sort((prev, curr) => {
                                        if (prev.quantity > curr.quantity) {
                                            return -1;
                                        } else {
                                            return 1;
                                        }
                                    })
                                    .map((product, index) => {
                                        return (
                                            <SlimProductCard
                                                key={index}
                                                // handleClickOpenModal={
                                                //     handleClickOpenModal
                                                // }
                                                product={product}
                                            />
                                        );
                                    })
                            ) : (
                                <div
                                    style={{ width: "100%" }}
                                    className="d-flex flex-column align-items-center justify-content-center mb-3"
                                >
                                    <p>No products found.</p>
                                    <button
                                        className="main-btn mt-2"
                                        onClick={() => {
                                            setSearchByBrand("");
                                            setSearchBySize("");
                                            setSearchByApplication("");

                                            // Clears filters
                                            filterSearchValues(
                                                {
                                                    searchByBrand: "",
                                                    searchBySize: "",
                                                    searchByApplication: "",
                                                },
                                                productsList
                                            );
                                            handleSubmit();
                                        }}
                                    >
                                        Clear Filter
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : isLoading ? (
                <div className="notFoundContent my-3 my-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="my-2"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={200}
                            width={200}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Loading Products...
                        </p>
                    </div>
                </div>
            ) : (
                hasErrorFetchingProducts && (
                    <div className="notFoundContent my-2 my-md-5 d-flex align-items-center justify-content-center">
                        <p
                            className="not-found-text mx-4"
                            style={{ color: "#e50119" }}
                        >
                            Sorry, we could not retrieve products at this time.
                            <br />
                            Please try again later.
                        </p>
                    </div>
                )
            )}
            {/* </div> */}
            {/* scroll to top */}
            <ScrollToTop smooth />
            {/* scroll to top */}
            {/* footer */}
            <Footer />
            {/* End: footer */}
        </div>
    );
};
export default SlimProducts;
