import React from "react";
import "./Form.scss";
import FormSignup from "./FormSignup";

const Form = () => {
    return (
        <div className="form-container">
            <FormSignup />
        </div>
    );
};

export default Form;
