import React from "react";
import "./CommonDialog.scss";

/**
 * Import Components from MUI for Modal
 */
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

/**
 * Functions related to MUI Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CommonDialog({ title, open, onClose, children }) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="content-container"
            // maxWidth={"100%"}
            fullScreen
            disableEnforceFocus
            // disablePortal={true}
        >
            <DialogTitle sx={{ fontSize: "30px", padding: "0px" }} component="div">
                <span className="common-dialog-title ml-1 ml-sm-3">
                    {title}
                </span>
                <div
                    className="common-dialog-cancel float-right mt-2 mr-2 mr-md-4 cancel-btn"
                    onClick={onClose}
                >
                    X
                </div>
            </DialogTitle>
            <DialogContent id="content-container" sx={{ padding: "0px" }}>
                {children}
            </DialogContent>
            {/* <DialogActions sx={{ marginRight: { sm: "20px" }, padding: "0px" }}>
                <Button
                    sx={{
                        color: "red",
                        fontSize: { xs: "16px", md: "20px" },
                    }}
                    onClick={onClose}
                >
                    Exit
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}

export default CommonDialog;
