/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";

// import components
import validate from "./ValidateAddStockForm";
import useForm from "./AddStockuseForm";

// import css
import "./AddStockForm.scss";

// Import images
import UploadIcon from "../../../Assests/Icons/upload.png";

const AddStockForm = ({ activeStockItem, handleResetActiveStockItem }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { handleChange, handleImageChange, handleSubmit, values, errors } =
        useForm(
            validate,
            activeStockItem,
            handleResetActiveStockItem,
            setIsLoading
        );

    return (
        <>
            {isLoading ? (
                <div className="featured-stock-container d-flex justify-content-center align-items-center">
                    <div className="notFoundContent">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                className="mb-3"
                                src={MinestarLoader}
                                alt="Loading..."
                                height={200}
                                width={200}
                            />
                            <p
                                className="not-found-text mb-2"
                                style={{
                                    color: "#e50119",
                                }}
                            >
                                Processing stock to add...
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <form
                            onSubmit={handleSubmit}
                            className="p-2"
                            id="reseller-add-stock-form"
                            noValidate
                        >
                            <div className="row">
                                <div className="form-inputs mt-4 col-6">
                                    <label className="pl-1 mb-1 heading-small">
                                        Quantity
                                    </label>
                                    <input
                                        className="form-control loginInput"
                                        type="text"
                                        name="quantity"
                                        placeholder="0"
                                        value={
                                            values.productResellerLists[0]
                                                .hasBeenUsed
                                                ? 1
                                                : values.productResellerLists[0]
                                                      .quantity ?? ""
                                        }
                                        disabled={
                                            values.productResellerLists[0]
                                                .hasBeenUsed
                                        }
                                        onChange={handleChange}
                                        tabIndex={1}
                                    />
                                    {errors.quantity && (
                                        <p className="text-errors">
                                            {errors.quantity}
                                        </p>
                                    )}
                                </div>
                                <div className="form-inputs mt-4 col-6">
                                    <label className="pl-1 mb-1 heading-small">
                                        Price
                                    </label>
                                    <input
                                        className="form-control loginInput"
                                        type="text"
                                        name="price"
                                        placeholder="0"
                                        value={
                                            values.productResellerLists[0]
                                                .price ?? ""
                                        }
                                        onChange={handleChange}
                                        tabIndex={1}
                                    />
                                    {errors.price && (
                                        <p className="text-errors">
                                            {errors.price}
                                        </p>
                                    )}
                                </div>
                                <div className="form-inputs mt-md-2 col-12">
                                    <input
                                        className="ml-1"
                                        type="checkbox"
                                        name="hasBeenUsed"
                                        id="hasBeenUsed"
                                        onChange={handleChange}
                                        tabIndex={3}
                                        style={{ backgroundColor: "red" }}
                                    ></input>
                                    <label
                                        htmlFor="hasBeenUsed"
                                        className="form-check-label pl-1"
                                        value={
                                            values.productResellerLists[0]
                                                .hasBeenUsed ?? null
                                        }
                                    >
                                        Is this tyre used?
                                    </label>
                                </div>
                            </div>
                            {values.productResellerLists[0].hasBeenUsed && (
                                <div className="row">
                                    <div className="form-inputs col-6 mt-1 mt-md-2">
                                        <label className="pl-1 heading-small mb-1">
                                            Tread Depth (mm)
                                        </label>
                                        <input
                                            className="form-control loginInput"
                                            type="number"
                                            name="treadDepth"
                                            placeholder="0"
                                            value={
                                                values.productResellerLists[0]
                                                    .treadDepth ?? ""
                                            }
                                            onChange={handleChange}
                                        />
                                        {errors.treadDepth && (
                                            <p className="text-errors">
                                                {errors.treadDepth}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-inputs upload-img-container col-6 mt-1 mt-md-2">
                                        <label
                                            id="add-img-label"
                                            htmlFor="add-single-img"
                                        >
                                            <img
                                                className="uploadIcon"
                                                src={
                                                    values
                                                        .productResellerLists[0]
                                                        .imageName !== null
                                                        ? values
                                                              .productResellerLists[0]
                                                              .imageName
                                                        : UploadIcon
                                                }
                                                alt=""
                                            />
                                            {values.productResellerLists[0]
                                                .imageName === null && (
                                                <span className="uploadText">
                                                    Upload Image
                                                </span>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            id="add-single-img"
                                            name="imageName"
                                            accept="image/png"
                                            onChange={(e) => {
                                                handleImageChange(e);
                                            }}
                                        />
                                        {errors.imageName && (
                                            <p className="text-errors">
                                                {errors.imageName}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="row d-flex justify-content-between">
                                <button
                                    onClick={() => handleResetActiveStockItem()}
                                    className="reset-active-product mt-2 mt-md-4 mr-3"
                                    type="button"
                                >
                                    Go Back
                                </button>
                                <button
                                    className="add-stock-btn float-right mt-2 mt-md-4"
                                    type="submit"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddStockForm;
