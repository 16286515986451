export default function validateInfo(values) {
    let errors = {};

    if (!values.name) {
        errors.name = "Name is required.";
    } else if (values.name.trim().length < 2) {
        errors.name = "Your name is too short.";
    }

    if (!values.email) {
        errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(values.email.trim())) {
        errors.email = "Email address is invalid.";
    }

    // if (!values.company) {
    //     errors.company = "Company name is required.";
    // } else if (values.company.trim().length < 2) {
    //     errors.company = "Company name needs to be 2 characters or more.";
    // }

    if (!values.phonenumber) {
        errors.phonenumber = "Phone number number is required.";
    } else if (
        !/[0-9\b]+$/.test(values.phonenumber.trim()) ||
        values.phonenumber.trim().length !== 10
    ) {
        errors.phonenumber = "Phone number must be 10 digits long.";
    }

    if (!values.message) {
        errors.message = "A message is required.";
    } else if (values.message.trim().length < 10) {
        errors.message = "Your message is a little too short.";
    }

    return errors;
}
