import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

//import components
import validate from "./ValidateResetPassword";
import useForm from "./ResetPassworduseForm";

import "../../../Errors/Errors.scss";
import MinestarLoader from "../../../../Assests/Animations/Minestar-Loader-Small.gif";

const FormSignup = ({ forgotPasswordGuid }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [hasErrorsFetchingReset, setHasErrorsFetchingReset] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    const { handleChange, handleSubmit, values, errors } = useForm(
        validate,
        forgotPasswordGuid,
        setIsLoading,
        setHasErrorsFetchingReset,
        setResetSuccess
    );

    return isLoading ? (
        <div className="notFoundContent my-1">
            <div className="d-flex flex-column align-items-center">
                <img
                    className="my-2 minestar-loader"
                    src={MinestarLoader}
                    alt="Loading..."
                    height={200}
                    width={200}
                />
                <p className="not-found-text" style={{ color: "#e50119" }}>
                    Please stay on this page while we reset your password...
                </p>
            </div>
        </div>
    ) : hasErrorsFetchingReset ? (
        <div className="notFoundContent my-1 d-flex flex-column align-items-center justify-content-center">
            <p className="not-found-text mx-2" style={{ color: "#e50119" }}>
                Something went wrong with resetting your password.
                <br />
                Please click the button below to retry.
                <br />
                Sorry for any inconvenience this might have caused you.
            </p>
            <button
                onClick={() => navigate("/forgotpassword")}
                className="mt-3"
                id="forgotPasswordbtn"
                type="button"
            >
                Retry
            </button>
        </div>
    ) : resetSuccess ? (
        <div className="reset-success-container">
            <FontAwesomeIcon
                className="menu-icon reset-success-icon"
                icon={faCheck}
            />
            <h1>Password has been reset successfully!</h1>
            <p className="mt-3" id="registrationLink">
                <button
                    onClick={() => navigate("/login")}
                    className="mt-3"
                    id="forgotPasswordbtn"
                    type="button"
                >
                    Go To Login
                </button>
            </p>
        </div>
    ) : (
        <div className="forgotPasswordContent">
            <form onSubmit={handleSubmit} className="loginForm" noValidate>
                <div className="form-inputs">
                    <p className="formLabel heading-small mb-1 ml-1">
                        New Password
                    </p>
                    <input
                        className="form-control reset-password-input"
                        type="password"
                        name="password"
                        placeholder="New Password"
                        value={values.password || ""}
                        onChange={handleChange}
                    />
                    {errors.password && (
                        <p className="text-errors">{errors.password}</p>
                    )}
                </div>
                <div className="form-inputs mt-3">
                    <p className="formLabel heading-small mb-1 ml-1">
                        Confirm Password
                    </p>
                    <input
                        className="form-control reset-password-input"
                        type="password"
                        name="password2"
                        placeholder="Confirm Password"
                        value={values.password2 || ""}
                        onChange={handleChange}
                    />
                    {errors.password2 && (
                        <p className="text-errors">{errors.password2}</p>
                    )}
                </div>
                <button
                    className="mt-3 main-btn change-password-btn"
                    style={{ width: "100%" }}
                    type="submit"
                >
                    Change Password
                </button>
            </form>
        </div>
    );
};

export default FormSignup;
