import React from "react";

import "./ProductQuantityControl.scss";

function ProductQuantityControl({
    qty,
    handleDecrement,
    handleIncrement,
    handleChange,
    cartItem,
}) {
    return (
        <div className="input-section row mx-0 justify-content-center justify-content-lg-start">
            <button
                className="tyre-qty-decrement-btn"
                style={
                    qty === 0
                        ? {
                              visibility: "hidden",
                          }
                        : {
                              visibility: "visible",
                          }
                }
                onClick={handleDecrement}
            >
                -
            </button>
            <input
                type="text"
                className="tyre-qty-input text-center"
                value={qty}
                onChange={handleChange}
            />
            <button
                className="tyre-qty-increment-btn"
                style={
                    qty >= 0 && qty < cartItem.quantity
                        ? {
                              visibility: "visible",
                          }
                        : {
                              visibility: "hidden",
                          }
                }
                onClick={handleIncrement}
            >
                +
            </button>
        </div>
    );
}

export default ProductQuantityControl;
