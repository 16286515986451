import React from "react";
import { Outlet } from "react-router-dom";

import Navigation from "../Navigation/Navigation";
import ScrollToTop from "react-scroll-to-top";
import Footer from "../Footer/Footer";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../SEO/SEONoIndex";

function PaymentFinalLayout() {
    return (
        <div className="container">
            <SEONoIndex title="Payment | Minestar South Africa" />
            <div className="item-1">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="OzowPayment" />
                    {/* End: The main navigation component */}
                </header>
            </div>

            <div className="m-4 d-flex justify-content-center align-items-center">
                <Outlet />
            </div>

            {/* scroll to top */}
            <ScrollToTop smooth />
            {/* scroll to top */}

            {/* Beginning: Application footer */}
            <Footer />
            {/* End: Application footer */}
        </div>
    );
}

export default PaymentFinalLayout;
