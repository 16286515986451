import React from "react";
import "./AddressRadioButton.scss";

const AddressRadioButton = ({
    id,
    changed,
    value = "",
    isSelected,
    address,
}) => {
    return (
        <div
            className={
                isSelected ? "radio-btn radio-btn-selected" : "radio-btn"
            }
        >
            <div className="row align-items-center p-5">
                <input
                    id={id}
                    onChange={changed}
                    value={value}
                    type="radio"
                    checked={isSelected}
                />
                <label htmlFor={id}>
                    {
                        <div className="ml-4 d-flex align-items-center">
                            <div className="d-flex flex-column">
                                {address?.bIsDefault ? (
                                    <div className="delivery-address-heading">
                                        {address?.strAddressTitle !== null &&
                                            address?.strAddressTitle}
                                    </div>
                                ) : (
                                    <div className="delivery-address-heading">
                                        {address?.strAddressTitle !== null
                                            ? address?.strAddressTitle
                                            : ""}
                                    </div>
                                )}

                                <span>{address?.strAddressLineOne}</span>
                                <span>{address?.strAddressLineTwo}</span>
                                <span>{address?.strCity}</span>
                            </div>
                        </div>
                    }
                </label>
            </div>
        </div>
    );
};

export default AddressRadioButton;
