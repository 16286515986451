// import { Button } from "semantic-ui-react";
import React, { useState } from "react";
import validate from "./ValidateRegisterAddressInfo";
import useForm from "./RegisterAddressuseForm";

import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";
import CommonDialog from "../../Dialog/CommonDialog";
import TermsAndConditions from "../../LegalDocs/TermsAndConditions";

const RegisterAddressForm = ({
    setActive,
    userDetails,
    userAddressState,
    userType,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);

    const handleClickOpenTerms = () => {
        setOpenTerms(true);
    };
    const handleClickCloseTerms = () => {
        setOpenTerms(false);
    };

    const { handleChange, handleSubmit, errors } = useForm(
        validate,
        userDetails,
        userAddressState,
        userType,
        setActive,
        setIsLoading
    );

    return (
        <>
            {isLoading ? (
                <>
                    <div className="notFoundContent d-flex align-items-center justify-content-center my-3 my-md-5">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img
                                className="my-2"
                                src={MinestarLoader}
                                alt="Loading..."
                                height={200}
                                width={200}
                            />
                            <p
                                className="not-found-text"
                                style={{ color: "#e50119" }}
                            >
                                Please wait while we register your account...
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {openTerms && (
                        <CommonDialog
                            title={"Minestar Terms & Conditions"}
                            open={openTerms}
                            onClose={handleClickCloseTerms}
                        >
                            <TermsAndConditions />
                        </CommonDialog>
                    )}

                    <form
                        onSubmit={handleSubmit}
                        className="loginForm p-2"
                        noValidate
                    >
                        <h1>Address</h1>
                        <div className="form-group mt-2">
                            <label className="heading-small mb-1 ml-1">
                                Address Line 1
                            </label>
                            <input
                                placeholder=""
                                className="form-control"
                                type="text"
                                name="strAddressLineOne"
                                value={
                                    userAddressState.userAddress
                                        .strAddressLineOne
                                }
                                onChange={handleChange}
                            />
                            {errors.strAddressLineOne && (
                                <p className="text-error mt-1">
                                    {errors.strAddressLineOne}
                                </p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <label className="heading-small mb-1 ml-1">
                                Address Line 2
                            </label>
                            <input
                                placeholder=""
                                className="form-control"
                                type="text"
                                name="strAddressLineTwo"
                                value={
                                    userAddressState.userAddress
                                        .strAddressLineTwo
                                }
                                onChange={handleChange}
                            />
                            {errors.strAddressLineTwo && (
                                <p className="text-error mt-1">
                                    {errors.strAddressLineTwo}
                                </p>
                            )}
                        </div>

                        <div className="form-group mt-3">
                            <label className="heading-small mb-1 ml-1">
                                City
                            </label>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                name="strCity"
                                value={userAddressState.userAddress.strCity}
                                onChange={handleChange}
                            />
                            {errors.strCity && (
                                <p className="text-error mt-1">
                                    {errors.strCity}
                                </p>
                            )}
                        </div>

                        <div className="form-group mt-3">
                            <label className="heading-small mb-1 ml-1">
                                Province
                            </label>
                            <input
                                placeholder=""
                                className="form-control"
                                type="text"
                                name="strProvince"
                                value={userAddressState.userAddress.strProvince}
                                onChange={handleChange}
                            />
                            {errors.strProvince && (
                                <p className="text-error mt-1">
                                    {errors.strProvince}
                                </p>
                            )}
                        </div>

                        <div className="form-group mt-3">
                            <label className="heading-small mb-1 ml-1">
                                Country
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                name="strCountry"
                                placeholder=""
                                value={userAddressState.userAddress.strCountry}
                                onChange={handleChange}
                            />
                            {errors.strCountry && (
                                <p className="text-error mt-1">
                                    {errors.strCountry}
                                </p>
                            )}
                        </div>

                        <div className="form-group mt-3">
                            <label className="heading-small mb-1 ml-1">
                                Postal Code
                            </label>
                            <input
                                placeholder="0000"
                                className="form-control"
                                type="text"
                                name="strPostalCode"
                                value={
                                    userAddressState.userAddress.strPostalCode
                                }
                                onChange={handleChange}
                            />
                            {errors.strPostalCode && (
                                <p className="text-error mt-1">
                                    {errors.strPostalCode}
                                </p>
                            )}
                        </div>
                        <div className="form-group mt-3">
                            <div className="d-flex align-items-start ml-1">
                                <input
                                    className="mr-1"
                                    type="checkbox"
                                    tabIndex="0"
                                    name="bAcceptTsAndCs"
                                    id="bAcceptTsAndCs"
                                    value={
                                        userAddressState.userAddress
                                            .bAcceptTsAndCs
                                    }
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor="bAcceptTsAndCs"
                                    id="bAcceptTsAndCs-label"
                                    className="mb-0"
                                >
                                    I agree with the{" "}
                                    <span
                                        className="terms-and-conditions"
                                        onClick={handleClickOpenTerms}
                                    >
                                        &nbsp;terms & conditions.
                                    </span>{" "}
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            {errors.bAcceptTsAndCs && (
                                <p className="text-error mt-1">
                                    {errors.bAcceptTsAndCs}
                                </p>
                            )}
                        </div>

                        <div className="d-flex justify-content-end justify-content-md-between mt-3">
                            <button
                                type="button"
                                className="login-redirect mr-md-3"
                                onClick={() => {
                                    setActive("Register");
                                    userAddressState.setUserAddress({
                                        ...userAddressState.userAddress,
                                        bAcceptTsAndCs: false,
                                    });
                                }}
                            >
                                Back
                            </button>
                            <button className="register-btn" type="submit">
                                Register
                            </button>
                        </div>
                    </form>
                </>
            )}
        </>
    );
};

export default RegisterAddressForm;
