import React, {
    useState,
    useEffect,
    useRef,
    // useMemo,
    useCallback,
} from "react";
import { Table } from "reactstrap";
import { API } from "../../Utility/ApiUrlPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    // faPlus,
    faFilter,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

// Import components
import AddStockForm from "./AddStockForm/AddStockForm";
// import StockItemImage from "./StockItemImage";
import CommonDialog from "../Dialog/CommonDialog";
import AddStockInstructions from "./AddStockInstructions/AddStockInstructions";

// Import Utils
import { isObjEmpty } from "../../Utility/Utils";

// Import Custom Functions
import { scrollToSection } from "../../Utility/Utils";

// Importing the css files
import "./Dashboard.scss"; //styles exclusive to this page only

// Import Custom Functions
import GetAllProducts from "../../Utility/Functions/GetAllProducts";

// Import Custom Hooks
import useWindowDimensions from "../../Utility/hooks/useWindowDimensions";

import MinestarLoader from "../../Assests/Animations/Minestar-Loader-Small.gif";
// import AddStockProductView from "../ProductView/AddStockProductView";
import FilteredStockList from "./FilteredStockList/FilteredStockList";
import AddStockFilterSection from "./AddStockFilterSection";
import StockList from "./StockList/StockList";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../SEO/SEONoIndex";

const Addstock = () => {
    const currentSection = useRef(null);
    const usedFilter = useRef(false);
    const isFiltering = useRef(false);

    // getDimensions of the screen
    const MOBILE_WIDTH = 720;
    const { width } = useWindowDimensions();

    //handle search values
    const [searchBySize, setSearchBySize] = useState("");
    const [searchByBrand, setSearchByBrand] = useState("");

    // Dropdown options
    // const [brandOptions, setBrandOptions] = useState([]);
    // const [sizeOptions, setSizeOptions] = useState([]);

    const [addNewStockItem, setAddNewStockItem] = useState(false);
    const [activeStockItem, setActiveStockItem] = useState({});
    const [stockList, setStockList] = useState([]);
    const [filteredStockList, setFilteredStockList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingProducts, setHasErrorFetchingProducts] =
        useState(false);

    /**
     * Fetch All Products from API.
     */
    useEffect(() => {
        const abortController = new AbortController();
        fetchAllProducts(abortController);
        return () => {
            abortController.abort();
        };
    }, []);

    async function fetchAllProducts(abortController) {
        try {
            setIsLoading(true);
            setHasErrorFetchingProducts(false);

            let data = await GetAllProducts(
                `${API.QA_URL}api/v1/products/get_all_slim_products`,
                abortController
            );

            if (!isObjEmpty(data)) {
                setStockList(data);
                setFilteredStockList([]);
            }
            setIsLoading(false);
            setHasErrorFetchingProducts(false);
        } catch (error) {
            setStockList([]);
            setFilteredStockList([]);
            setHasErrorFetchingProducts(true);
        }
    }

    // function handleAddStockItem(stockItem) {
    //     setActiveStockItem(stockItem);
    //     setAddNewStockItem(true);
    // }
    const handleAddStockItem = useCallback(
        (stockItem) => {
            setActiveStockItem(stockItem);
            setAddNewStockItem(true);
        },
        [setActiveStockItem, setAddNewStockItem]
    );

    function handleResetActiveStockItem() {
        setActiveStockItem({});
        setAddNewStockItem(false);
        // setStockList([]);
        // setFilteredStockList([]);
        // let abortController = new AbortController();
        // fetchAllProducts(abortController);
    }

    /**
     * Gets and sets all the dropdown options.
     * @param products
     */
    // const populateSearchDropdowns = (products) => {
    //     const [brands, sizes] = FilterOptions(products);
    //     setBrandOptions(brands);
    //     setSizeOptions(sizes);
    // };

    /**
     * Filters products based on @param.
     * @param {searchByBrand, searchBySize} searchValues - Values used to filter through products.
     * @param productsToFilter - An array of products.
     */
    function filterSearchValues(
        { searchByBrand, searchBySize },
        productsToFilter = []
    ) {
        if (productsToFilter.length === 0) return;

        setIsLoading(true);
        let searchResults = [];

        // Search by brand and size
        if (searchByBrand !== "" || searchBySize !== "") {
            if (searchByBrand !== "" && searchBySize !== "") {
                searchResults = productsToFilter.filter(
                    (x) =>
                        (x.brand === searchByBrand) &
                        (x.tyreSize === searchBySize)
                );
            } else {
                searchResults = productsToFilter.filter(
                    (x) =>
                        x.brand === searchByBrand || x.tyreSize === searchBySize
                );
            }
            setFilteredStockList(searchResults);
        } else {
            // setFilteredStockList(productsToFilter);
            setFilteredStockList([]);
        }
        setIsLoading(false);
    }

    //dropdown filter
    const handleSubmit = (e) => {
        e.preventDefault();
        isFiltering.current = true;
        filterSearchValues(
            {
                searchBySize,
                searchByBrand,
            },
            stockList
        );
    };

    useEffect(() => {
        scrollToSection(currentSection);
    }, [addNewStockItem]);

    const [isFilterOpen, setIsFilterOpen] = useState(width > MOBILE_WIDTH);
    const handleOpenFilters = () => {
        usedFilter.current = true;
        setIsFilterOpen(true);
    };
    const handleCloseFilters = () => {
        usedFilter.current = true;
        setIsFilterOpen(false);
    };

    function showWhatIsBeingSearched() {
        let result = ["Result:"];
        if (searchByBrand !== "") {
            result.push(searchByBrand.trim());
        }
        if (searchBySize !== "") {
            result.push(searchBySize.trim());
        }

        return `${result[0]} "${result.splice(1).join(" ")}"`;
    }

    const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
    function handleOpenInstructions() {
        setIsInstructionsOpen(true);
    }
    function handleCloseInstructions() {
        setIsInstructionsOpen(false);
    }

    useEffect(() => {
        if (!usedFilter.current) {
            setIsFilterOpen(width > MOBILE_WIDTH);
        }
    }, [width]);

    const showFilters = () => {
        return (
            <AddStockFilterSection
                handleSubmit={handleSubmit}
                brandState={{
                    searchByBrand,
                    setSearchByBrand,
                }}
                sizeState={{
                    searchBySize,
                    setSearchBySize,
                }}
                stockData={stockList}
                filterSearchValues={filterSearchValues}
                handleCloseFilters={handleCloseFilters}
                isFiltering={isFiltering}
            />
        );
    };

    return (
        <div ref={currentSection}>
            <SEONoIndex title="Add Stock | Minestar South Africa" />
            <CommonDialog
                title={"How to add a stock item:"}
                open={isInstructionsOpen}
                onClose={handleCloseInstructions}
            >
                <AddStockInstructions />
            </CommonDialog>

            <div id="add-stock-table">
                <div className="d-flex align-items-center">
                    <h4 id="sold-stock-title" className="mt-1 ml-2 ml-md-0">
                        Add Stock
                    </h4>
                    <FontAwesomeIcon
                        onClick={handleOpenInstructions}
                        className="add-stock-question-icon ml-2"
                        style={{ fontSize: "clamp(18px, 1.2vw, 24px)" }}
                        icon={faQuestionCircle}
                    />
                </div>

                {isLoading && !hasErrorFetchingProducts ? (
                    <div className="featured-stock-container d-flex justify-content-center align-items-center">
                        <div className="notFoundContent">
                            <div className="d-flex flex-column align-items-center">
                                <img
                                    className="mb-3"
                                    src={MinestarLoader}
                                    alt="Loading..."
                                    height={200}
                                    width={200}
                                />
                                <p
                                    className="not-found-text mb-2"
                                    style={{
                                        color: "#e50119",
                                    }}
                                >
                                    Loading Products...
                                </p>
                            </div>
                        </div>
                    </div>
                ) : hasErrorFetchingProducts ? (
                    <div className="notFoundContent my-2 d-flex align-items-center justify-content-center">
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Sorry, could not retrieve products at this time.
                            <br /> Please try again later.
                        </p>
                    </div>
                ) : (
                    <div id="add-stock-table">
                        {!addNewStockItem ? (
                            <>
                                <div className="filter-section">
                                    {isFilterOpen ? showFilters() : null}

                                    {/* Need to Open Filter */}
                                    {!isFilterOpen && (
                                        <div
                                            className="filter-container row mx-0 pr-4 justify-content-end"
                                            onClick={() => {
                                                handleOpenFilters();
                                            }}
                                        >
                                            <button className="filter-btn d-flex align-items-center mb-3 mb-lg-4">
                                                <span className="mr-2">
                                                    filter
                                                </span>
                                                <FontAwesomeIcon
                                                    className="filter-icon"
                                                    icon={faFilter}
                                                />
                                            </button>
                                        </div>
                                    )}

                                    {/* Show Filter options */}
                                    {
                                        // stockList.length !==
                                        // filteredStockList.length ? (
                                        isFiltering.current ? (
                                            // && filteredStockList.length > 0
                                            <div className="search-results-container ml-1 ml-md-5 mb-2 mb-md-3">
                                                <div className="d-flex">
                                                    <div>
                                                        {showWhatIsBeingSearched()}
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            isFiltering.current = false;
                                                            setSearchByBrand(
                                                                ""
                                                            );
                                                            setSearchBySize("");

                                                            // Clears filters
                                                            filterSearchValues(
                                                                {
                                                                    searchByBrand:
                                                                        "",
                                                                    searchBySize:
                                                                        "",
                                                                },
                                                                stockList
                                                            );
                                                        }}
                                                        className="ml-2 clear-filter-text"
                                                    >
                                                        clear
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                </div>

                                {/* Results: */}
                                {filteredStockList?.length > 0 ||
                                stockList?.length > 0 ? (
                                    isFiltering.current ? (
                                        filteredStockList?.length > 0 ? (
                                            <FilteredStockList
                                                filteredStockList={
                                                    filteredStockList
                                                }
                                                handleAddStockItem={
                                                    handleAddStockItem
                                                }
                                            />
                                        ) : (
                                            <Table striped responsive>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="4">
                                                            No products found.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        )
                                    ) : (
                                        <StockList
                                            stockList={stockList}
                                            handleAddStockItem={
                                                handleAddStockItem
                                            }
                                        />
                                    )
                                ) : (
                                    <Table striped responsive>
                                        <tbody>
                                            <tr>
                                                <td colSpan="4">
                                                    No products found.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                )}
                            </>
                        ) : (
                            // Add New Stock Component
                            <div id="create-stock-item" className="p-3">
                                <AddStockForm
                                    handleResetActiveStockItem={
                                        handleResetActiveStockItem
                                    }
                                    activeStockItem={activeStockItem}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Addstock;
