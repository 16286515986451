import React, { useCallback } from "react";
import AddStockProductView from "../../ProductView/AddStockProductView";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import "./FilteredStockList.scss";

// const MemoizedAddStockProductView = React.memo(AddStockProductView);

// TODO: Try removing the handleAddStockItem and only tell the parent if it should render the addstockproductview component (with useState).
function FilteredStockList({ filteredStockList, handleAddStockItem }) {
    // const memoizedHandleAddStockItem = useCallback(handleAddStockItem, []);

    return (
        <Table striped responsive>
            <thead>
                <tr className="addS">
                    {/* <th
                                                    scope="col"
                                                    className="text-nowrap"
                                                ></th> */}
                    <th scope="col" className="text-nowrap pl-md-3">
                        Name
                    </th>
                    <th scope="col" className="text-nowrap">
                        Brand
                    </th>
                    <th scope="col" className="text-nowrap">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                {/* TODO: Lazy Loading? */}
                {filteredStockList.map((stockItem, index) => {
                    return (
                        <tr key={index}>
                            <td className="tableText pl-md-3">
                                <AddStockProductView product={stockItem} />
                            </td>
                            <td className="tableText">{stockItem.brand}</td>
                            <td>
                                <div className="d-flex align-items-center justify-content-around justify-content-md-start">
                                    <span
                                        onClick={() => {
                                            // memoizedHandleAddStockItem(
                                            handleAddStockItem(stockItem);
                                        }}
                                        className="my-stock-action-btn d-flex align-items-center justify-content-center"
                                    >
                                        <FontAwesomeIcon
                                            className="menu-icon my-stock-icons"
                                            icon={faPlus}
                                        />
                                    </span>
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

export default FilteredStockList;
