import React from "react";
import "./AddressItem.scss";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

const AddressItem = ({
    address,
    setCurrentSelectedAddress,
    setIsNewAddress,
    setActive,
}) => {
    return (
        <li
            className="address-item mb-3 ml-0"
            // key={address.id}
            onClick={() => {
                setIsNewAddress(false);
                setCurrentSelectedAddress(address);
            }}
        >
            <div className="row mx-0 align-items-center justify-content-center justify-content-sm-around p-3 p-md-3 p-lg-5">
                <div className="col-12 col-md-9 px-0 mb-2 mb-md-0">
                    {address.strAddressLineOne + ", "}
                    {address.strAddressLineTwo}
                    {address.bIsDefault ? " (Default)" : ""}
                </div>
                <button
                    className="secondary-outline-btn col-12 col-md-3 mx-3 mx-md-0"
                    onClick={() => {
                        setIsNewAddress(false);
                        setCurrentSelectedAddress(address);
                        setActive("editAddress");
                    }}
                >
                    Edit
                </button>
            </div>
        </li>
    );
};

export default AddressItem;
