import React from "react";
import { Table } from "reactstrap";

function OrderItemsList({ orderList = [] }) {
    return (
        <div className="mt-4">
            <h1 className="mb-2">Order Items</h1>
            <Table striped responsive id="table">
                <thead>
                    <tr>
                        <th className="pl-md-3">Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {orderList.length > 0 ? (
                        orderList?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="pl-md-3">
                                        {item.brand} {item.productSize}{" "}
                                        {item.treadPattern}
                                    </td>
                                    <td>{item.quantity}</td>
                                    <td>R {item.price}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <>
                            <p className="text-error">
                                Sorry, something went wrong.
                            </p>
                        </>
                    )}
                </tbody>
            </Table>
        </div>
    );
}

export default OrderItemsList;
