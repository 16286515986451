import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { API } from "../../../Utility/ApiUrlPath";

import "./ResetPassword.scss";

import logo from "../../../Assests/HomePage/logo/mainLogo.png";
import ResetPasswordForm from "./ResetPasswordForms/ResetPasswordForm";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../../SEO/SEONoIndex";

const ResetPassword = () => {
    const navigate = useNavigate();

    let [searchParams, setSearchParams] = useSearchParams();

    const forgotPasswordGuid = searchParams.get("guid");

    return (
        <div className="ForgotPasswordContainer">
            <SEONoIndex title="Reset Password | Minestar South Africa" />
            <div className="ForgotPasswordNav">
                <img
                    className="homeLogo"
                    src={logo}
                    alt="Minestar South Africa"
                    onClick={() => navigate("/")}
                />
            </div>
            <div className="pageContainer">
                <div className="reset-container">
                    <ResetPasswordForm
                        forgotPasswordGuid={forgotPasswordGuid}
                    />
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
