import React, { useState, useEffect } from "react";
import "./BrochureView.scss";
import pdfImage from "../../../Assests/Icons/download_pdf.png";
import GetAllProducts from "../../../Utility/Functions/GetAllProducts";
import { API } from "../../../Utility/ApiUrlPath";
import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";
import "../../../Components/Errors/Errors.scss";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";

function BrochureView({ brandName, brandBrochureId, index }) {
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [brochureData, setBrochureData] = useState(null);

    const renderErrorToast = () => {
        toast.error("Could not download file at this time.", {
            autoClose: toastrTime,
        });
    };

    const handleDownloadPdf = async () => {
        setIsLoading(true);
        if (brochureData != null || brochureData === "") {
            setHasErrors(false);
            downloadPdf(brochureData.base64);
        } else {
            setHasErrors(true);
        }
        setIsLoading(false);
    };

    const downloadPdf = (base64String) => {
        try {
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", brochureData.brochureName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {}
    };

    useEffect(() => {
        const abortController = new AbortController();
        fetchBrochureResponse(abortController);

        return () => abortController.abort();
    }, []);

    const fetchBrochureResponse = async (abortController) => {
        try {
            setIsLoading(true);
            const data = await GetAllProducts(
                `${API.QA_URL}api/v1/brands/brand_brochure_by_id?brandBrochureId=${brandBrochureId}`,
                abortController
            );

            console.log("data.status === 200", data.status === 200);
            if (data.status === 200) {
                setHasErrors(false);
                setBrochureData(data);
            } else {
                renderErrorToast();
                setHasErrors(true);
            }
        } catch (err) {
            console.log("error", err.message);
            setBrochureData(null);
            if (err.message !== "The user aborted a request.") {
                renderErrorToast();
            }
            setHasErrors(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button
            disabled={hasErrors}
            className="pdf-list-container"
            onClick={handleDownloadPdf}
        >
            <div className="image-container">
                {isLoading ? (
                    <img
                        src={MinestarLoader}
                        alt="Loading..."
                        style={{ aspectRatio: "1/1", marginBottom: "1rem" }}
                    />
                ) : (
                    <img
                        className="pdf-image"
                        src={pdfImage}
                        alt={"Pdf icon"}
                    />
                )}
            </div>
            <div className="brochure-download-btn">
                {isLoading
                    ? "Loading..."
                    : hasErrors
                    ? "Error"
                    : `${brochureData.brochureName}`}
            </div>
        </button>
    );
}

export default BrochureView;
