export default function ValidateEditAddressInfo(values) {
    const errors = {};

    if (!values.strAddressLineOne) {
        errors.strAddressLineOne = "Address Line 1 is required.";
    } else if (values.strAddressLineOne.trim().length < 2) {
        errors.strAddressLineOne = "Address Line 1 is a little too short.";
    }

    if (!values.city) {
        errors.city = "City is required.";
    } else if (values.city.trim().length < 2) {
        errors.city = "City name is a little too short.";
    }

    if (!values.province) {
        errors.province = "Province is required.";
    } else if (values.province.trim().length < 2) {
        errors.province = "Province name is a little too short.";
    }

    if (!values.country) {
        errors.country = "Country is required.";
    } else if (values.country.trim().length < 2) {
        errors.country = "Country name is a little too short.";
    }

    if (!values.postalCode) {
        errors.postalCode = "Postal Code is required.";
    } else if (values.postalCode.trim().length !== 4) {
        errors.postalCode = "Postal Code must be 4 digits long.";
    }

    return errors;
}
