import React, { useState } from "react";
import validate from "./ValidateContactInfo";
import useForm from "./ContactuseForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";

const FormSignup = () => {
    const [sentSuccessfully, setSentSuccessfully] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { handleChange, handleSubmit, values, errors } = useForm(
        validate,
        setSentSuccessfully,
        setIsLoading
    );

    const handleEmailClick = () => {
        const emailAddress = "sales@minestar.co.za";
        const mailtoUrl = `mailto:${emailAddress}`;
        window.open(mailtoUrl, "_blank");
    };

    return (
        <>
            <div className="d-flex justify-content-center mx-auto">
                <div className="d-lg-flex align-items-lg-center mx-2 px-lg-4 my-md-3">
                    <div className="office-details row mx-0 col-12 col-lg-5 my-4 flex-row-reverse flex-lg-row">
                        <div className="col-6 col-lg-12">
                            <h2 className="office-heading my-0">
                                Trading Hours
                            </h2>
                            <p className="office-hours ml-1">
                                Mon - Fri: 8AM - 5PM (EST)
                                <br />
                                Sat - Sun: Closed
                            </p>
                        </div>
                        <div className="mt-lg-5 col-6 col-lg-12">
                            <h2 className="office-heading my-0">
                                Contact Number
                            </h2>
                            <p className="office-hours ml-1">
                                (+27) 82 895 0111
                            </p>
                        </div>
                        <div className="mt-3 mt-lg-5 col-12">
                            <h2 className="office-heading my-0">
                                Email Address
                            </h2>
                            <p className="office-hours ml-1">
                                {/* <a
                                    className="contact-email-link"
                                    href="mailto:sales@minestar.co.za"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    sales@minestar.co.za
                                </a> */}
                                <a
                                    className="mt-3 footer-email"
                                    onClick={handleEmailClick}
                                    href="mailto:sales@minestar.co.za"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    sales@minestar.co.za
                                </a>
                            </p>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="col-12 col-lg-7 mx-lg-5 mt-4 mt-xl-0 d-flex flex-column align-items-center">
                            <div className="notFoundContent my-1">
                                <div className="d-flex flex-column align-items-center">
                                    <img
                                        className="my-2 minestar-loader"
                                        src={MinestarLoader}
                                        alt="Loading..."
                                        height={200}
                                        width={200}
                                    />
                                    <p
                                        className="not-found-text"
                                        style={{ color: "#e50119" }}
                                    >
                                        Sending Email...
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : sentSuccessfully != null ? (
                        sentSuccessfully ? (
                            <div className="col-12 col-lg-7 mx-lg-5 mt-4 mt-xl-0 d-flex flex-column align-items-center">
                                <div className="email-success-container mt-5 d-flex flex-column justify-content-center align-content-center px-3">
                                    <FontAwesomeIcon
                                        className="menu-icon email-success-icon"
                                        icon={faCheck}
                                    />
                                    <h1>Email Sent Successfully!</h1>
                                    <button
                                        onClick={() => {
                                            setSentSuccessfully(null);
                                        }}
                                        className="email-done-btn"
                                        type="button"
                                    >
                                        Done
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="col-12 col-lg-7 mx-lg-5 mt-4 mt-xl-0 d-flex flex-column align-items-center">
                                    <div className="email-success-container mt-5 d-flex flex-column justify-content-center align-content-center px-3">
                                        <FontAwesomeIcon
                                            className="menu-icon email-failure-icon"
                                            icon={faTimes}
                                        />
                                        <p className="title-xl">Could not send email.</p>
                                        <button
                                            onClick={() => {
                                                setSentSuccessfully(null);
                                            }}
                                            className="email-done-btn"
                                            type="button"
                                        >
                                            Try Again
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
                    ) : (
                        <>
                            <div className="col-12 col-lg-7 mx-lg-5 mt-4 mt-xl-0 d-flex flex-column align-items-center">
                                <h2 className="form-heading text-center mt-0 my-2 my-md-0">
                                    Send us an email
                                </h2>
                                <form
                                    onSubmit={handleSubmit}
                                    className="mx-auto"
                                    noValidate
                                >
                                    <div className="form-group mt-2 mb-3 mx-md-2">
                                        <label className="mb-1 ml-1">
                                            Name
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                        {errors.name && (
                                            <p className="text-errors">
                                                {errors.name}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group mb-3 mx-md-2">
                                        <label className="mb-1 ml-1">
                                            Email
                                        </label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && (
                                            <p className="text-errors">
                                                {errors.email}
                                            </p>
                                        )}
                                    </div>
                                    <div className="row mx-0 form-group companyAndPhone mb-0 mx-md-2">
                                        <div className="col-12 px-0 mr-lg-1 mb-3">
                                            <label className="mb-1 ml-1">
                                                Phone
                                            </label>
                                            <input
                                                className="form-control"
                                                id="phoneNumber"
                                                type="number"
                                                name="phonenumber"
                                                placeholder="Phone"
                                                value={values.phonenumber}
                                                onChange={handleChange}
                                            />
                                            {errors.phonenumber && (
                                                <p className="text-errors">
                                                    {errors.phonenumber}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group mx-md-2">
                                        <label className="mb-1 ml-1">
                                            How can we help you?
                                        </label>
                                        <textarea
                                            rows="6"
                                            className="form-control"
                                            type="text"
                                            name="message"
                                            placeholder="Leave us a message"
                                            id="contact-textarea"
                                            value={values.message}
                                            onChange={handleChange}
                                        />
                                        {errors.message && (
                                            <p className="text-errors">
                                                {errors.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button
                                            className="main-contact-btn mt-3 mr-2"
                                            type="submit"
                                        >
                                            Send Email
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default FormSignup;
