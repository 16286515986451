import React, { useState } from "react";
import "./DeliveryAddressListMobile.scss";
import AddressMobileItem from "./AddressMobileItem";

function DeliveryAddressListMobile({
    addressList,
    currentSelectedAddressState,
    setFinalAddress,
}) {
    const [currentActiveAddress, setCurrentActiveAddress] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const addressChangeHandler = (address) => {
        setCurrentActiveAddress(address);
        currentSelectedAddressState.setCurrentSelectedAddress(address);
        setFinalAddress(address);
        setIsLoading(false);
    };

    return (
        <div>
            {addressList
                // .sort((a, b) => {
                //     return isLoading && a.bIsDefault
                //         ? -1
                //         : a.iUserInformationId ===
                //           currentActiveAddress.iUserInformationId
                //         ? -1
                //         : 1;
                // })
                .map((address, index) => (
                    <AddressMobileItem
                        key={index}
                        addressChangeHandler={addressChangeHandler}
                        address={address}
                        currentActiveAddress={currentActiveAddress}
                        isLoading={isLoading}
                    />
                ))}
        </div>
    );
}

export default DeliveryAddressListMobile;
