import React, { useState, useContext, useEffect } from "react";
import { API } from "../../Utility/ApiUrlPath";

import "./AddStockForm/AddStockForm.scss";

import Select from "react-select";

import { UserContext } from "../../Contexts/user.context";

// Import Custom Functions
import GetAllProducts from "../../Utility/Functions/GetAllProducts";

// Import Utilities
import { isObjEmpty } from "../../Utility/Utils";

/**
 *
 * @param {handleSubmit, brandState, sizeState, applicationState, stockData, filterSearchValues} props
 * @returns
 */
function MyStockFilterSection({
    handleSubmit,
    brandState,
    sizeState,
    stockData,
    filterSearchValues,
    handleCloseFilters,
}) {
    const { user } = useContext(UserContext);

    // Dropdown options
    const [brandOptions, setBrandOptions] = useState([]);

    const [sizeOptions, setSizeOptions] = useState([]);

    // const [applicationOptions, setApplicationOptions] = useState([]);

    const filterStyle = {
        control: (base) => ({
            ...base,
            backgroundColor: "white",
            boxShadow: "#e50019",

            "&:hover": {
                borderColor: "#e50019",
            },
        }),

        menuList: (base) => ({
            ...base,
            backgroundColor: "white",
            color: "black",
            border: "white",
            borderStyle: "solid",
            borderWidth: "thin",
        }),

        dropdownIndicator: (base) => ({
            ...base,
            color: "transparent",
            width: "100%",
            height: "2.5em",
            backgroundImage:
                "linear-gradient(45deg, transparent 50%, #fff 50%),\n        linear-gradient(135deg, #fff 50%, transparent 50%),\n        radial-gradient(#e50019, 70%, transparent 72%)",
            backgroundPosition:
                "calc(100% - 20px) calc(1em + 2px),\n        calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em",
            backgroundSize: "5px 5px, 5px 5px, 1.5em 1.5em",
            backgroundRepeat: "no-repeat",
            paddingBottom: "2px",

            "&:hover": {
                color: "transparent",
            },
        }),
    };

    /**
     * Gets and sets all the dropdown options.
     * @param filterOptions - Object which contains all the filter option data.
     */
    const populateSearchDropdowns = (filterOptions) => {
        setBrandOptions(filterOptions?.brandOptions ?? []);
        setSizeOptions(filterOptions?.sizeOptions ?? []);
        // setApplicationOptions(filterOptions.applicationOptions ?? []);
    };

    /**
     * Fetch All Featured Products from API.
     */
    useEffect(() => {
        const abortController = new AbortController();

        (async () => {
            try {
                const data = await GetAllProducts(
                    `${API.QA_URL}api/v1/product_reseller/my_stock_filters?resellerGuid=${user?.resellerGuid}`,
                    abortController
                );

                if (!isObjEmpty(data)) {
                    populateSearchDropdowns(data);
                }
            } catch (error) {
                console.error(error);
            }
        })();

        // 👇️ scroll to top on page load
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        return () => abortController.abort();
    }, []);

    return (
        <div className="searchFilter d-block d-md-flex justify-content-around">
            <form
                className="col-12 pb-4 px-2 d-flex justify-content-center align-items-center"
                onSubmit={handleSubmit}
            >
                <div className="dropdown-container d-block d-md-flex flex-md-wrap justify-content-center">
                    <div
                        className="close-filter-btn row mx-0"
                        onClick={handleCloseFilters}
                    >
                        Close X
                    </div>
                    <div className="row mx-0 col-md-4">
                        <p className="filter-title mt-md-4 col-12">Brand</p>
                        <Select
                            key={1}
                            className="col-12"
                            isClearable
                            styles={filterStyle}
                            placeholder="Choose Brand"
                            options={brandOptions}
                            onChange={(event, action) => {
                                if (action.action === "clear") {
                                    brandState.setSearchByBrand("");
                                    filterSearchValues(
                                        {
                                            searchBySize:
                                                sizeState.searchBySize,
                                            searchByBrand: "",
                                        },
                                        stockData
                                    ); // Clears filters
                                } else {
                                    brandState.setSearchByBrand(event.value);
                                }
                            }}
                        />
                    </div>
                    <div className="row mx-0 col-md-4">
                        <p className="filter-title mt-2 mt-md-4 col-12">Size</p>
                        <Select
                            key={2}
                            className="searchround-new col-12"
                            isClearable
                            styles={filterStyle}
                            placeholder="Choose Size"
                            options={sizeOptions}
                            onChange={(event, action) => {
                                if (action.action === "clear") {
                                    sizeState.setSearchBySize("");
                                    filterSearchValues(
                                        {
                                            searchBySize: "",
                                            searchByBrand:
                                                brandState.searchByBrand,
                                        },
                                        stockData
                                    ); // Clears filters
                                } else {
                                    sizeState.setSearchBySize(event.value);
                                }
                            }}
                        />
                    </div>
                    <div className="col-12 row mx-0 mr-sm-4 mr-md-3 mt-2 mt-md-3">
                        <div className="ml-auto d-flex align-items-center mt-2 mt-md-0">
                            <button
                                onClick={() => {
                                    brandState.setSearchByBrand("");
                                    sizeState.setSearchBySize("");

                                    // Clears filters
                                    filterSearchValues(
                                        {
                                            searchByBrand: "",
                                            searchBySize: "",
                                        },
                                        stockData
                                    );
                                }}
                                type="button"
                                className="reset-filters-btn mr-2"
                            >
                                Reset All
                            </button>
                            <button
                                type="button submit"
                                className="search-products-btn"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default MyStockFilterSection;
