import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastrTime } from "../../../Utility/Constants/ToastrTime";

import "./ResellerProduct.scss";

import ProductQuantityControl from "../../ProductQuantityControl/ProductQuantityControl";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
    selectCart,
    addItem,
    changeQty,
} from "../../../Redux/Features/Cart/cartSlice";

import useTyreImage from "../../../Utility/hooks/useTyreImage";
import defaultTyre from "../../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";

function ResellerProduct({ reseller, newProductImage, onClose }) {
    const { loading, error, image } = useTyreImage(
        reseller.brand,
        reseller.bIsConditionNew ? newProductImage : reseller.imageName
    );

    const [modalVisibility, setModalVisibility] = useState(false);

    const cart = useSelector(selectCart);
    const dispatch = useDispatch();

    const AddToCart = (e, currentCartItem) => {
        e.preventDefault();

        if (qty > 0) {
            if (cart.cartList?.length > 0) {
                const alreadyInCart = cart.cartList.filter(
                    (x) =>
                        x.iProductResellerId ===
                        currentCartItem.iProductResellerId
                );

                if (alreadyInCart?.length === 0) {
                    dispatch(
                        addItem(
                            currentCartItem,
                            qty,
                            reseller?.imageName && reseller?.imageName !== ""
                                ? reseller?.imageName
                                : newProductImage
                        )
                    );
                    toast.success(({ closeToast }) => (
                        <div
                            className="go-to-cart-btn"
                            onClick={handleGoToCart}
                        >
                            Go to Cart
                        </div>
                    ));
                    onClose();
                } else {
                    toast.error(
                        ({ closeToast }) => (
                            <div
                                className="go-to-cart-btn"
                                onClick={handleGoToCart}
                            >
                                Go to Cart
                            </div>
                        ),
                        {
                            autoClose: toastrTime,
                        }
                    );
                }
            } else {
                dispatch(
                    addItem(
                        currentCartItem,
                        qty,
                        reseller?.imageName && reseller?.imageName !== ""
                            ? reseller?.imageName
                            : newProductImage
                    )
                );
                toast.success(({ closeToast }) => (
                    <div className="go-to-cart-btn" onClick={handleGoToCart}>
                        Go to Cart
                    </div>
                ));
                onClose();
            }
        } else {
            toast.error(({ closeToast }) => <>Quantity cannot be zero</>, {
                autoClose: 2000,
            });
        }
    };

    const navigate = useNavigate();

    const handleGoToCart = () => {
        navigate("/cart");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setModalVisibility(false);
    };

    // Product quantity controls
    const [qty, setQty] = useState(0); // Start off with 1 item to add to cart.

    const handleIncrement = (currentProduct) => {
        if (qty + 1 <= currentProduct.quantity) {
            setQty(qty + 1);
            dispatch(changeQty(currentProduct.iProductResellerId, qty + 1));
        }
    };
    const handleDecrement = (currentProduct) => {
        if (qty > 0) {
            setQty(qty - 1);
            dispatch(changeQty(currentProduct.iProductResellerId, qty - 1));
        }
    };
    const handleChange = (e, currentProduct) => {
        if (e.target.value <= currentProduct.quantity) {
            setQty(e.target.value);
            dispatch(
                changeQty(currentProduct.iProductResellerId, e.target.value)
            );
        }
    };

    return (
        <div className="reseller-product-details d-flex justify-content-center align-items-center">
            <div className="reseller-details-content row">
                <div className="col-12 reseller-product-pricing px-md-4 px-lg-5">
                    <div className="reseller-product-container py-2">
                        <div className="reseller-product-title row mx-0">
                            <h2 className="col-12 px-0">
                                {reseller?.bIsConditionNew
                                    ? reseller?.productName + " (New)"
                                    : reseller?.productName + " (2nd Hand)"}
                            </h2>
                        </div>

                        <h1 className="reseller-product-brand">
                            {reseller?.brand}
                        </h1>

                        <div className="reseller-product-image">
                            <img
                                className="productDetailImage"
                                src={
                                    loading
                                        ? defaultTyre
                                        : error
                                        ? defaultTyre
                                        : image
                                }
                                alt="Tyre"
                            />
                        </div>

                        <span className="reseller-product-stock">
                            {reseller?.quantity} IN STOCK
                        </span>

                        <h3 className="reseller-product-price">
                            R {reseller?.sellingPrice}
                        </h3>

                        <div className="reseller-cart-item-qty-control">
                            <ProductQuantityControl
                                qty={qty}
                                handleDecrement={() =>
                                    handleDecrement(reseller)
                                }
                                handleIncrement={() =>
                                    handleIncrement(reseller)
                                }
                                handleChange={(e) => handleChange(e, reseller)}
                                cartItem={reseller}
                            />
                        </div>

                        <button
                            key={reseller?.iProductResellerId}
                            className="reseller-cart-btn add-address-btn m-1 mt-md-3"
                            onClick={(e) => {
                                AddToCart(e, reseller);
                            }}
                        >
                            ADD TO CART
                        </button>
                        <button
                            className="reseller-bid-btn m-1 mt-md-3"
                            onClick={() => setModalVisibility(!modalVisibility)}
                        >
                            {!modalVisibility
                                ? "MAKE AN OFFER"
                                : "CANCEL OFFER"}
                        </button>

                        <div>
                            {modalVisibility && (
                                <div className="modal">
                                    <form
                                        className="row biddingFormInputs mt-3 mb-3"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="col-12 col-md-7">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter amount"
                                            />
                                        </div>
                                        <div className="col-12 col-md-5 row mx-0 mt-2 mt-md-0">
                                            <button
                                                className="reseller-bid-now-btn col-12"
                                                type="submit"
                                            >
                                                Make Offer
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResellerProduct;
