import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.scss";

import logo from "../../../Assests/HomePage/logo/mainLogo.png";
import ForgotPasswordForm from "./ForgotPasswordForms/ForgotPasswordForm";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../../SEO/SEONoIndex";

const ForgotPassword = () => {
    const navigate = useNavigate();

    return (
        <div className="ForgotPasswordContainer">
            <SEONoIndex title="Forgot Password | Minestar South Africa" />
            <div className="ForgotPasswordNav">
                <img
                    className="homeLogo"
                    src={logo}
                    alt="Minestar South Africa"
                    onClick={() => navigate("/")}
                />
            </div>
            <div className="pageContainer">
                <div className="forgot-container">
                    <ForgotPasswordForm />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
