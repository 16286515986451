import React from "react";

import useTyreImage from "../../Utility/hooks/useTyreImage";
import defaultTyre from "../../Assests/Tyres/TYRE_IMAGE_NOT_FOUND.png";
import { Link } from "react-router-dom";
import { removeSlashFromTyreSize, removeFileExtension } from "../../Utility/Utils";

function FeaturedStockCard({
    product,
    setResellerProducts,
    handleClickOpenModal,
}) {
    const { loading, error, image } = useTyreImage(
        product?.brand,
        product?.imageName
    );
    return (
        <Link to={`/product/${product?.brand}/${removeSlashFromTyreSize(product?.tyreSize)}/${removeFileExtension(product?.imageName)}/${product?.productGuid}`}>
            <div className="featuredCard card my-2">
                {/* used to fix the card width. */}
                <div style={{ width: "300px" }}></div>

                <img
                    src={
                        loading || error !== null
                            ? defaultTyre
                            : image === null
                                ? defaultTyre
                                : image
                    }
                    alt={
                        loading || error !== null
                            ? ""
                            : image === null
                                ? ""
                                : product?.brand + " " + product.tyreSize + " " + product.treadPattern
                    }
                    className="card__image"
                />
                <div className="card__overlay">
                    <div className="card__header">
                        <svg
                            className="card__arc"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path />
                        </svg>
                        <div className="card__header-text">
                            <h3 className="card__title">
                                {product.tyreSize + " " + product.treadPattern}
                            </h3>
                            <span className="card__status"></span>
                        </div>
                    </div>
                    <p className="card__description">
                        <span className="brand">{product?.brand}</span>
                        <span className="price">
                            {"R" + (product?.sellingPrice ?? "-")}
                        </span>
                    </p>
                    <div
                        className="featured-more-details-btn py-2 d-flex justify-content-center"
                        onClick={() => {
                            setResellerProducts(product);
                            handleClickOpenModal();
                        }}
                    >
                        View Details
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default FeaturedStockCard;
