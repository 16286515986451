import React, { useEffect } from "react";

// Importing the css files
import "./BiddingPage.css";

// Paths for the images used in the page
// import TyreImage from "../../Assests/Tyres/tractortyre.png";

// Importing components
import Navigation from "../../Components/Navigation/Navigation";
import Footer from "../../Components/Footer/Footer";

//deadline counterdown
//tabs menu

// Importing SEO component
import SEONoIndex from "../../Components/SEO/SEONoIndex";

//start of bidding page
const BiddingPage = () => {
    // 👇️ scroll to top on page load
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="container">
            <SEONoIndex title={"Bidding"}/>
            <div className="bidding-page-header">
                <header className="main-header" role="banner">
                    {/* Beginning: The main navigation component */}
                    <Navigation page="BiddingPage" />
                    {/* End: The main navigation component */}

                    {/* contact us hero section  */}
                    <section className="homepage-hero">
                        <div className="row">
                            <div className="common-header ml-3 ml-md-5 pl-md-4">
                                <h1>
                                    Bid on the tyres <br /> and secure your
                                    stock
                                </h1>
                            </div>
                        </div>
                    </section>
                    {/* end of contact us hero section */}
                </header>
            </div>
            {/* single tyre auction */}
            <div className="single-tyre-auction">
                <div className="tyre-on-auction">
                    <div className="auction-tyre-image">
                        <div id="banner">
                            <div className="product-branding skew-right skewed-all-screens">
                                <h2 className="displayed-products">Product</h2>
                            </div>
                        </div>
                        <img
                            className="img-fluid"
                            // src={TyreImage}
                            alt="Tyre One"
                        />
                    </div>
                    <div className="auction-tyre-spec">
                        <div className="part-number">
                            <h5>EM Master 29.6 R25</h5>
                            <span>Part #1256262626</span>
                        </div>
                        <h1>EM-Master E4/L4</h1>
                        <h4>50+ In Stock</h4>
                        <hr className="hr-style1" />
                        <h3>Time Left:</h3>
                        {/* auction deadline */}
                        <p className="timer"></p>
                        <p className="text-left">
                            <b>Auction ends:</b> December 21, 2021 12:00 am
                            <br />
                            Time zone: GMT
                        </p>
                        <div className="row biddingActions">
                            <div className="col-sm-4 mt-4 biddingPrice">
                                <p>
                                    Bid from R<span>50 000</span>
                                </p>
                            </div>
                            <div className="col-sm-4 mt-4 biddingQuantity">
                                <input type="text" />
                            </div>
                            <div className="col-sm-4 mt-4 biddingBtn">
                                <button>Bid Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*single tyre auction details*/}
            {/* <div className="auction-products">
                <div>
                    <div className="product-branding skew-right skewed-all-screens">
                        <h2 className="displayed-products">Products On Auction</h2>
                    </div>
                </div>
                <h1>JOIN THE AUCTION</h1>
                <table className="auction-products-table">
                    <tr>
                        <th>TYRE</th>
                        <th>TITLE</th>
                        <th>PRODUCT</th>
                        <th>CURRENT ID</th>
                        <th>EXPIRES ON</th>
                        <th>IN STOCK</th>
                        <th>PLACE BID</th>
                    </tr>
                    <tr>
                        <td className="auction-tyre">
                            <img className="img-fluid" src={TyreImage} alt="Tyre One" />
                        </td>
                        <td>
                            <span><b>VF CombineMaster</b></span>
                        </td>
                        <td>
                            <span>5268466456</span>
                        </td>
                        <td>
                            <span>50 000</span>
                        </td>
                        <td>
                            <span>2021-07-08</span>
                        </td>
                        <td>
                            <span className="auction-stock-status" type="btn">50+ IN STOCK</span>
                        </td>
                        <td>
                            <span className="bidding-btn"><b>BID NOW</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td class="auction-tyre">
                            <img class="img-fluid" src={TyreImage} alt="Tyre One" />
                        </td>
                        <td>
                            <span><b>VF CombineMaster</b></span>
                        </td>
                        <td>
                            <span>5268466456</span>
                        </td>
                        <td>
                            <span>50 000</span>
                        </td>
                        <td>
                            <span>2021-07-08</span>
                        </td>
                        <td>
                            <span class="auction-stock-status" type="btn">50+ IN STOCK</span>
                        </td>
                        <td>
                            <span className="bidding-btn"><b>BID NOW</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td class="auction-tyre">
                            <img class="img-fluid" src={TyreImage} alt="Tyre One" />
                        </td>
                        <td>
                            <span><b>VF CombineMaster</b></span>
                        </td>
                        <td>
                            <span>5268466456</span>
                        </td>
                        <td>
                            <span>50 000</span>
                        </td>
                        <td>
                            <span>2021-07-08</span>
                        </td>
                        <td>
                            <span class="auction-stock-status" type="btn">50+ IN STOCK</span>
                        </td>
                        <td>
                            <span className="bidding-btn"><b>BID NOW</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td class="auction-tyre">
                            <img class="img-fluid" src={TyreImage} alt="Tyre One" />
                        </td>
                        <td>
                            <span><b>VF CombineMaster</b></span>
                        </td>
                        <td>
                            <span>5268466456</span>
                        </td>
                        <td>
                            <span>50 000</span>
                        </td>
                        <td>
                            <span>2021-07-08</span>
                        </td>
                        <td>
                            <span class="auction-stock-status" type="btn">50+ IN STOCK</span>
                        </td>
                        <td>
                            <span className="bidding-btn"><b>BID NOW</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td class="auction-tyre">
                            <img class="img-fluid" src={TyreImage} alt="Tyre One" />
                        </td>
                        <td>
                            <span><b>VF CombineMaster</b></span>
                        </td>
                        <td>
                            <span>5268466456</span>
                        </td>
                        <td>
                            <span>50 000</span>
                        </td>
                        <td>
                            <span>2021-07-08</span>
                        </td>
                        <td>
                            <span class="auction-stock-status" type="btn">50+ IN STOCK</span>
                        </td>
                        <td>
                            <span className="bidding-btn"><b>BID NOW</b></span>
                        </td>
                    </tr>
                </table>
            </div> */}
            {/*auction header*/}
            <div className="category-auction mt-5">
                <h1>TYRES BY CATEGORY</h1>
            </div>
            {/*top brands on auction*/}
            <div className="category-nav mb-5">
                <button>CONTINENTAL</button>
                <button>MICHELIN </button>
                <button>BRIDGESTONE</button>
                <button>PIRELLI</button>
            </div>
            {/*auction item card*/}
            <div className="auction-tyres">
                <div className="auction-tyres-properties">
                    <img
                        className="img-fluid brand-properties"
                        // src={TyreImage}
                        alt="Tyre One"
                    />
                </div>
                {/*descriptions or tyre specifications*/}
                <div className="auction-tyres-properties">
                    <h5>VF CombineMaster</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat
                    </p>
                    <p>
                        Current Bid: <span>$1,205.00</span>
                    </p>
                </div>
                <div className="auction-tyres-properties">
                    <i className="fa fa-eye"></i>
                </div>
            </div>
            {/*auction item card*/}
            <div className="auction-tyres">
                <div className="auction-tyres-properties">
                    <img
                        className="img-fluid brand-properties"
                        // src={TyreImage}
                        alt="Tyre One"
                    />
                </div>
                <div className="auction-tyres-properties">
                    <h5>VF CombineMaster</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat
                    </p>
                    <p>
                        Current Bid: <span>$1,205.00</span>
                    </p>
                </div>
                <div className="auction-tyres-properties">
                    <i className="fa fa-eye"></i>
                </div>
            </div>
            {/*auction item card*/}
            <div className="auction-tyres">
                <div className="auction-tyres-properties">
                    <img
                        className="img-fluid brand-properties"
                        // src={TyreImage}
                        alt="Tyre One"
                    />
                </div>
                <div className="auction-tyres-properties">
                    <h5>VF CombineMaster</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat
                    </p>
                    <p>
                        Current Bid: <span>$1,205.00</span>
                    </p>
                </div>
                <div className="auction-tyres-properties">
                    <i className="fa fa-eye"></i>
                </div>
            </div>
            {/*auction item card*/}
            <div className="auction-tyres">
                <div className="auction-tyres-properties">
                    <img
                        className="img-fluid brand-properties"
                        // src={TyreImage}
                        alt="Tyre One"
                    />
                </div>
                <div className="auction-tyres-properties">
                    <h5>VF CombineMaster</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat
                    </p>
                    <p>
                        Current Bid: <span>$1,205.00</span>
                    </p>
                </div>
                <div className="auction-tyres-properties">
                    <i className="fa fa-eye"></i>
                </div>
            </div>
            {/*auction item card*/}
            <div className="auction-tyres">
                <div className="auction-tyres-properties">
                    <img
                        className="img-fluid brand-properties"
                        // src={TyreImage}
                        alt="Tyre One"
                    />
                </div>
                <div className="auction-tyres-properties">
                    <h5>VF CombineMaster</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat
                    </p>
                    <p>
                        Current Bid: <span>$1,205.00</span>
                    </p>
                </div>
                <div className="auction-tyres-properties">
                    <i className="fa fa-eye"></i>
                </div>
            </div>

            {/*contact/get in touch/need help links*/}
            <h2 className="contact-us-header">
                You have any questions ? Contact us:
            </h2>
            <div className="contact-us-options">
                <button>CHAT WITH US</button>
                <p>or</p>
                <button>SEND US AN EMAIL</button>
            </div>

            {/* Beginning: footer */}
            <Footer />
            {/* End: footer */}
        </div>
    );
};

export default BiddingPage;
