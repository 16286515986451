import React, { useEffect } from "react";
import { Button } from "semantic-ui-react";
import validate from "./ValidateDeliveryAddressInfo";
import useForm from "./AddressuseForm";
import "./AddressForm.scss";

const AddressForm = ({ setActive }) => {
    const { handleChange, handleSubmit, values, errors } = useForm(
        validate,
        setActive
    );
    useEffect(() => {
        window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
    }, []);
    return (
        <>
            <h4 className="address-title col-12 col-sm-9 px-0 my-1 ml-2 ml-md-0">
                New Address
            </h4>
            <div id="addressForm" className="p-3 p-md-4">
                <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-1">
                                <label className="mb-1 pl-1 heading-small">
                                    Address Line 1
                                </label>
                                <input
                                    placeholder=""
                                    className="form-control"
                                    type="text"
                                    name="strAddressLineOne"
                                    value={values.strAddressLineOne}
                                    onChange={handleChange}
                                />
                                {errors.strAddressLineOne && (
                                    <p className="text-error ml-1 mt-1">
                                        {errors.strAddressLineOne}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group mt-1">
                                <label className="mb-1 pl-1 heading-small">
                                    Address Line 2
                                </label>
                                <input
                                    placeholder=""
                                    className="form-control"
                                    type="text"
                                    name="strAddressLineTwo"
                                    value={values.strAddressLineTwo}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-1">
                                <label className="mb-1 pl-1 heading-small">
                                    City
                                </label>
                                <input
                                    type="text"
                                    placeholder=""
                                    className="form-control"
                                    name="strCity"
                                    value={values.strCity}
                                    onChange={handleChange}
                                />
                                {errors.strCity && (
                                    <p className="text-error ml-1 mt-1">
                                        {errors.strCity}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-1">
                                <label className="mb-1 pl-1 heading-small">
                                    Province
                                </label>
                                <input
                                    placeholder=""
                                    className="form-control"
                                    type="text"
                                    name="strProvince"
                                    value={values.strProvince}
                                    onChange={handleChange}
                                />
                                {errors.strProvince && (
                                    <p className="text-error ml-1 mt-1">
                                        {errors.strProvince}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-1">
                                <label className="mb-1 pl-1 heading-small">
                                    Country
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="strCountry"
                                    placeholder=""
                                    value={values.strCountry}
                                    onChange={handleChange}
                                />
                                {errors.strCountry && (
                                    <p className="text-error ml-1 mt-1">
                                        {errors.strCountry}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group mt-1">
                                <label className="mb-1 pl-1 heading-small">
                                    Postal Code
                                </label>
                                <input
                                    placeholder="0000"
                                    className="form-control"
                                    type="text"
                                    name="strPostalCode"
                                    value={values.strPostalCode}
                                    onChange={handleChange}
                                />
                                {errors.strPostalCode && (
                                    <p className="text-error ml-1 mt-1">
                                        {errors.strPostalCode}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 ml-1">
                            <div className="form-group mt-2 d-flex align-items-center">
                                <input
                                    className="m-0 mr-1"
                                    width="20px"
                                    height="20px"
                                    type="checkbox"
                                    tabIndex="0"
                                    name="bIsDefault"
                                    id="bIsDefault"
                                    value={values.bIsDefault}
                                    onChange={handleChange}
                                    checked={values.bIsDefault}
                                ></input>
                                <label className="mb-0" htmlFor="bIsDefault">
                                    Make Default
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-sm-flex justify-content-end">
                        <Button
                            id="cancel-edit-address-btn"
                            className="secondary-outline-btn mr-2 mb-1 mb-sm-0"
                            onClick={() => {
                                setActive("AddressList");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button id="submit-address">Add</Button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddressForm;
