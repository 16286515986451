import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Importing the css files
import "./LoginPage.scss";

// Importing components
import LoginForm from "../Forms/LoginForm/LoginForm";

// import images
import Logo from "../../Assests/Icons/main.png";

// Importing SEO component
import SEONoIndex from "../../Components/SEO/SEONoIndex";

const LoginPage = () => {
    const navigate = useNavigate();
    return (
        <div className="container">
            <SEONoIndex title={"Login"}/>
            <div className="loginContainer">
                <div
                    className="login-logo-container col-12"
                    onClick={() => navigate("/")}
                >
                    <img
                        src={Logo}
                        width={500}
                        alt="Minestar South Africa"
                        className=""
                        id="login-logo"
                    />
                </div>
                <div className="col-12 col-lg-8 LoginCover"></div>
                <div className="col-12 col-lg-4 loginForm my-5 my-lg-0">
                    <LoginForm />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
