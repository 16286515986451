export default function validateInfo(values) {
    let errors = {};

    if (!values.password) {
        errors.password = "Password is required.";
    } else if (values.password.trim().length < 6) {
        errors.password = "Password needs to be 6 characters or more.";
    }

    if (!values.password2) {
        errors.password2 = "Password confirmation is required.";
    } else if (values.password2.trim() !== values.password.trim()) {
        errors.password2 = "Passwords do not match.";
    }

    return errors;
}
