import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { toastrTime } from "../../Utility/Constants/ToastrTime";
import { API } from "../../Utility/ApiUrlPath";
import { isObjEmpty } from "../../Utility/Utils";

// Import Custom Functions
import GetAllProducts from "../../Utility/Functions/GetAllProducts";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// Paths for the images used in the page
import tyreIcon from "../../Assests/Icons/tyreIcon.png";
import BrandIcon from "../../Assests/Icons/brandIcon.png";
import MachineIcon from "../../Assests/Icons/gearIcon.png";

// Importing the css files
import "./Filtering.scss";

toast.configure();

const Filtering = () => {
    const navigate = useNavigate();

    const [searchBySize, setSearchBySize] = useState("");
    const [searchByBrand, setSearchByBrand] = useState("");
    const [searchByApplication, setSearchApplication] = useState("");

    // let history = useHistory();

    // Dropdown options
    const [brandOptions, setBrandOptions] = useState([]);

    const [sizeOptions, setSizeOptions] = useState([]);

    const [applicationOptions, setApplicationOptions] = useState([]);

    /**
     * Gets and sets all the dropdown options.
     * @param filterOptions - Object which contains all the filter option data.
     */
    const populateSearchDropdowns = (filterOptions) => {
        setBrandOptions(filterOptions?.brandOptions ?? []);
        setSizeOptions(filterOptions?.sizeOptions ?? []);
        setApplicationOptions(filterOptions.applicationOptions ?? []);
    };

    /**
     * Fetch All Filter Options from API.
     */
    useEffect(() => {
        const abortController = new AbortController();

        (async () => {
            try {
                const data = await GetAllProducts(
                    `${API.QA_URL}api/v1/products/get_all_product_filters`,
                    abortController
                );
                if (!isObjEmpty(data)) {
                    populateSearchDropdowns(data);
                }
            } catch (error) {
                console.error(error);
            }
        })();

        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        return () => abortController.abort();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let filterValues = { searchBySize, searchByBrand, searchByApplication };

        // redirect to products page with search filter values
        navigate("/products", { state: filterValues });
    };

    const filterStyle = {
        option: (base) => ({
            ...base,
            "&:hover": {
                backgroundColor: "#e50019",
            },
            backgroundColor: "#0d141c",
            color: "white",
        }),

        control: (base) => ({
            ...base,
            backgroundColor: "#0d141c",
            boxShadow: "#e50019",
            borderColor: "white",

            "&:hover": {
                borderColor: "#e50019",
            },
        }),

        menuList: (base) => ({
            ...base,
            backgroundColor: "#0d141c",
            color: "white",
            border: "white",
            borderStyle: "solid",
            borderWidth: "thin",
        }),

        singleValue: (base) => ({
            ...base,
            color: "white",
        }),

        dropdownIndicator: (base) => ({
            ...base,
            color: "transparent",
            width: "100%",
            height: "2.5em",
            backgroundImage:
                "linear-gradient(45deg, transparent 50%, #fff 50%),\nlinear-gradient(135deg, #fff 50%, transparent 50%),\nradial-gradient(#e50019, 70%, transparent 72%)",
            backgroundPosition: "44% 50%, 57% 50%,50.1% 50%",
            backgroundSize: "5px 5px, 5px 5px, 1.5em 1.5em",
            backgroundRepeat: "no-repeat",
            paddingBottom: "2px",

            "&:hover": {
                color: "transparent",
            },
            padding: "0px",
        }),
    };

    return (
        // get form inputs => re-route to products page => search & display the results
        <div>
            <section className="homepage-hero">
                <div className="homepage-filter-container">
                    <div className="homepage-filter">
                        <div className="styled-filter">
                            <form
                                role="search"
                                method="get"
                                className="mt-2"
                                onSubmit={handleSubmit}
                            >
                                <div className="form-body py-2 my-4 col-12 col-lg-8">
                                    <div className="filters-container">
                                        <div className="filter px-2 py-2">
                                            <div
                                                className="fs-label-wrap"
                                                style={{ height: "unset" }}
                                            >
                                                <div className="fs-label d-flex justify-content-center">
                                                    <img
                                                        className="filterIcons"
                                                        src={BrandIcon}
                                                        alt="Tyre Brand"
                                                    />
                                                    <Select
                                                        id="choose-brand"
                                                        className="ml-0 ml-sm-2 col-11 col-md-10"
                                                        styles={filterStyle}
                                                        defaultValue={""}
                                                        placeholder="Choose Brand"
                                                        options={brandOptions}
                                                        onChange={(event) =>
                                                            setSearchByBrand(
                                                                event.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter px-2 py-2">
                                            <div
                                                className="fs-label-wrap"
                                                style={{ height: "unset" }}
                                            >
                                                <div className="fs-label d-flex justify-content-center">
                                                    <img
                                                        className="filterIcons"
                                                        src={tyreIcon}
                                                        alt="Tyre Size"
                                                    />
                                                    <Select
                                                        id="choose-size"
                                                        className="ml-0 ml-sm-2 col-11 col-md-10"
                                                        styles={filterStyle}
                                                        defaultValue={""}
                                                        placeholder="Choose Size"
                                                        options={sizeOptions}
                                                        onChange={(event) =>
                                                            setSearchBySize(
                                                                event.value
                                                            )
                                                        }
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                            },
                                                            backgroundColor:
                                                                "black",
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter px-2 py-2">
                                            <div
                                                className="fs-label-wrap"
                                                style={{ height: "unset" }}
                                            >
                                                <div className="fs-label d-flex justify-content-center">
                                                    <img
                                                        className="filterIcons"
                                                        src={MachineIcon}
                                                        alt="Tyre Application"
                                                    />
                                                    <Select
                                                        id="choose-machine"
                                                        className="ml-0 ml-sm-2 col-11 col-md-10"
                                                        styles={filterStyle}
                                                        defaultValue={""}
                                                        placeholder="Choose Application"
                                                        options={
                                                            applicationOptions
                                                        }
                                                        onChange={(event) =>
                                                            setSearchApplication(
                                                                event.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="search-btn-container"
                                            className="px-2 pt-2"
                                        >
                                            <button
                                                type="button submit"
                                                className="search-btn add-address-btn minestar-button mx-lg-1"
                                            >
                                                SEARCH
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="homepage-hero-container">
                    <div className="homepage-hero-title d-flex justify-content-center">
                        <h1 className="ml-4 title-xxl title-hero title-hero-home">
                            World Wide <br />
                            Mining Solutions
                            <br />
                            <span>OTR Tyre Supply</span>
                        </h1>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Filtering;
