export default function validateInfo(values) {
    let errors = {};
    if (!values.Quantity) {
        errors.Quantity = "Stock Quantity is required.";
    } else if (values.Quantity <= 0) {
        errors.Quantity = "Stock Quantity must be a positive number.";
    }

    if (!values.AskingPrice) {
        errors.AskingPrice = "Stock price is required.";
    } else if (
        /^[0-9\b]+$/.test(values.AskingPrice) &&
        values.AskingPrice <= 0
    ) {
        errors.AskingPrice = "Stock price must be a positive number.";
    }

    if (values.bIsUsed) {
        if (!values.TreadDepth) {
            errors.TreadDepth = "Tread depth is required.";
        } else if (values.TreadDepth <= 0) {
            errors.TreadDepth = "Tread depth must be a positive number.";
        }

        if (!values.ProductImage) {
            errors.ProductImage = "Image is required.";
        }
    }

    return errors;
}
