import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./Contexts/user.context";
import { ProductListProvider } from "./Contexts/product_list.context";
import App from "./App";

// Importing the css files
import "./CSS/Custom-Theme.scss";
import "./CSS/Theme.scss";

// Redux Related
import { store, persistor } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Construction from "./Construction";

ReactDOM.render(
    <StrictMode>
        <BrowserRouter>
            <UserProvider>
                <ProductListProvider>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <App />
                        </PersistGate>
                    </Provider>
                </ProductListProvider>
            </UserProvider>
        </BrowserRouter>
        {/* <Construction /> */}
    </StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
