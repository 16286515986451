import React, { useState } from "react";

import "./GenericEmailButton.scss";

import CommonDialog from "../Dialog/CommonDialog";
import EmailQueryForm from "../Forms/EmailQueryForm/EmailQueryForm";

function GenericEmailButton() {
    const [open, setOpen] = useState(false);
    function handleOpenEmailQueryModal() {
        setOpen(true);
    }
    function handleCloseEmailQueryModal() {
        setOpen(false);
    }

    return (
        <>
            <CommonDialog
                title={"Email Us"}
                open={open}
                onClose={handleCloseEmailQueryModal}
            >
                <EmailQueryForm onClose={handleCloseEmailQueryModal} />
            </CommonDialog>
            <button onClick={handleOpenEmailQueryModal}>EMAIL US</button>
        </>
    );
}

export default GenericEmailButton;
