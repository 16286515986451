import React, { useState } from "react";
import { isObjEmpty } from "../../Utility/Utils";
import "./ProductView.scss";
import CommonDialog from "../Dialog/CommonDialog";
import SlimProductDetails from "../ProductDetails/SlimProductDetails";

const MemoizedSlimProductDetails = React.memo(SlimProductDetails);

const RenderModal = ({
    isViewingProduct,
    handleCloseProduct,
    productDetails,
}) => {
    return (
        <CommonDialog
            title={"Product Details"}
            open={isViewingProduct}
            onClose={handleCloseProduct}
        >
            {!isObjEmpty(productDetails) ? (
                <MemoizedSlimProductDetails
                    product={productDetails}
                    onClose={handleCloseProduct}
                />
            ) : (
                <>
                    Sorry, we could not retrieve this product's details at this
                    time. Please try again later.
                </>
            )}
        </CommonDialog>
    );
};

/**
 *
 * @param {product} must have productName, brand, ImageName, and productGuid
 * @returns
 */
function AddStockProductView({ product }) {
    const [productDetails, setProductDetails] = useState({});
    const [isViewingProduct, setIsViewingProduct] = useState(false);

    const handleViewProduct = (product) => {
        setProductDetails({
            brand: product.brand,
            imageName: product.imageName,
            productGuid: product.productGuid,
        });
        setIsViewingProduct(true);
    };
    const handleCloseProduct = () => {
        setProductDetails({});
        setIsViewingProduct(false);
    };

    return (
        <>
            {isViewingProduct && (
                <RenderModal
                    isViewingProduct={isViewingProduct}
                    handleCloseProduct={handleCloseProduct}
                    productDetails={productDetails}
                />
            )}
            <div
                className="product-name"
                onClick={() => handleViewProduct(product)}
            >
                {product?.productName}
            </div>
        </>
    );
}

export default AddStockProductView;
