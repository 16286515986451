import React from "react";
import "./PaymentRadioButton.scss";
import {
    OZOW_PAYMENT,
    MANUAL_PAYMENT,
    ACCOUNT_PAYMENT,
} from "../../../Utility/PaymentOptions";

const PaymentRadioButton = ({
    id,
    changed,
    value = "",
    isSelected,
    paymentOption,
}) => {
    function handlePaymentOption(option) {
        switch (option) {
            case OZOW_PAYMENT:
                return <>Pay with Ozow (1.50% transaction fee)</>;
            case MANUAL_PAYMENT:
                return <>Manual EFT</>;
            case ACCOUNT_PAYMENT:
                return <>Buy on Account</>;
            default:
                return "";
        }
    }
    return (
        <div
            key={id}
            className={
                isSelected ? "radio-btn radio-btn-selected" : "radio-btn"
            }
        >
            <div className="row align-items-center p-5">
                <input
                    id={id}
                    onChange={changed}
                    value={value}
                    type="radio"
                    checked={isSelected}
                />
                <label htmlFor={id}>
                    {
                        <div className="ml-4 d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <div className="delivery-address-heading">
                                    {paymentOption !== null
                                        ? handlePaymentOption(paymentOption)
                                        : ""}
                                </div>
                            </div>
                        </div>
                    }
                </label>
            </div>
        </div>
    );
};

export default PaymentRadioButton;
