import React, { useState, useEffect, useContext } from "react";
import "./SoldStock.scss";

import { API } from "../../Utility/ApiUrlPath";
import { UserContext } from "../../Contexts/user.context";
import { Table } from "reactstrap";
// import MinestarLoader from "../../Assests/Animations/Minestar-Loader.gif";
import MinestarLoader from "../../Assests/Animations/Minestar-Loader-Small.gif";

// Import Components
import ProductView from "../ProductView/ProductView";

// Import Utils
import { isObjEmpty } from "../../Utility/Utils";

// Import Custom Functions
import GetAllProducts from "../../Utility/Functions/GetAllProducts";
import PriceSplitter from "../../Utility/Functions/PriceSplitter";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../SEO/SEONoIndex";

function SoldStock() {
    const { user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingProducts, setHasErrorFetchingProducts] =
        useState(false);

    const [soldStockList, setSoldStockList] = useState([]);
    const [totalAmountDue, setTotalAmountDue] = useState(0);
    const [totalAmountPaid, setTotalAmountPaid] = useState(0);

    /**
     * Fetch All Products from API.
     */
    useEffect(() => {
        const abortController = new AbortController();

        (async () => {
            try {
                setIsLoading(true);
                let response = await GetAllProducts(
                    `${API.QA_URL}api/v1/reseller/sold_stock?resellerGuid=${user?.resellerGuid}`,
                    abortController
                );

                if (!isObjEmpty(response)) {
                    const data = response?.resellerSoldStockItems.$values;

                    setSoldStockList(
                        data
                        //     .map(item => {
                        //     return {
                        //         item.dblAmountOwed,
                        //         item.iUnitsSold}
                        // })
                    );
                    setTotalAmountDue(response.totalAmountDue);
                    setTotalAmountPaid(response.totalAmountPaid);
                }
                setIsLoading(false);
                setHasErrorFetchingProducts(false);
            } catch (error) {
                setSoldStockList([]);
                setHasErrorFetchingProducts(true);
            }
        })();

        return () => abortController.abort();
    }, []);

    return (
        <>
            <SEONoIndex title="Solf Stock | Minestar South Africa" />
            <div className="stockTable mb-0">
                <h4
                    id="sold-stock-title"
                    className="my-1 ml-2 ml-md-0 mb-0 mb-md-2"
                >
                    Sold Stock
                </h4>

                <Table striped responsive>
                    <thead>
                        <tr>
                            {/* <th scope="col" className="text-nowrap pl-md-3">
                                #
                            </th> */}
                            <th scope="col" className="text-nowrap">
                                Name
                            </th>
                            <th scope="col" className="text-nowrap">
                                Units Sold
                            </th>
                            <th scope="col" className="text-nowrap">
                                Amount Due
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading && !hasErrorFetchingProducts ? (
                            <tr>
                                <td colSpan="6">
                                    <div className="featured-stock-container">
                                        <div className="notFoundContent">
                                            <div className="d-flex flex-column align-items-center">
                                                <img
                                                    className="mb-3"
                                                    src={MinestarLoader}
                                                    alt="Loading..."
                                                    height={200}
                                                    width={200}
                                                />
                                                <p
                                                    className="not-found-text mb-2"
                                                    style={{
                                                        color: "#e50119",
                                                    }}
                                                >
                                                    Loading Your Sold Stock...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ) : hasErrorFetchingProducts ? (
                            <tr>
                                <td colSpan="4">
                                    <div className="notFoundContent my-2 d-flex align-items-center justify-content-center">
                                        <p
                                            className="not-found-text"
                                            style={{ color: "#e50119" }}
                                        >
                                            Sorry, could not retrieve your sold
                                            stock at this time.
                                            <br /> Please try again later.
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        ) : soldStockList.length > 0 ? (
                            <>
                                {soldStockList.map((stockItem, index) => {
                                    return (
                                        <tr
                                            key={
                                                stockItem.resellerSoldStockItemGuid
                                            }
                                        >
                                            {/* <td className="pl-md-3">
                                                {index + 1}
                                            </td> */}
                                            <td>
                                                <ProductView
                                                    product={stockItem}
                                                />
                                            </td>
                                            <td>{stockItem.unitsSold}</td>
                                            <td>
                                                {stockItem.amountOwed
                                                    ? "R " +
                                                      PriceSplitter(
                                                          stockItem.amountOwed
                                                      )
                                                    : "-"}
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr
                                    style={{
                                        borderBottom: "1px solid white",
                                    }}
                                    id="row-total"
                                >
                                    <td
                                        id="total-text"
                                        className="pl-md-3"
                                        colSpan="2"
                                    >
                                        Total Due
                                    </td>
                                    <td>R {PriceSplitter(totalAmountDue)}</td>
                                </tr>
                                <tr
                                    style={{
                                        borderBottom: "1px solid white",
                                    }}
                                    id="row-total"
                                >
                                    <td
                                        id="total-text"
                                        className="pl-md-3"
                                        colSpan="2"
                                    >
                                        Total Paid
                                    </td>
                                    <td>R {PriceSplitter(totalAmountPaid)}</td>
                                </tr>
                                <tr id="row-total">
                                    <td
                                        id="total-text"
                                        className="pl-md-3"
                                        colSpan="2"
                                    >
                                        Total Balance Outstanding
                                    </td>
                                    <td>
                                        R{" "}
                                        {PriceSplitter(
                                            totalAmountDue - totalAmountPaid
                                        )}
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td colSpan="3">No products found.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default SoldStock;
