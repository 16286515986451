import React from "react";
import Tooltip from "@mui/material/Tooltip";

function TooltipWrapper({ children, title }) {
    return (
        <Tooltip
            title={title}
            arrow
            placement="top"
            // sx={{ fontSize: 12, color: "red" }}
        >
            {children}
        </Tooltip>
    );
}

export default TooltipWrapper;
