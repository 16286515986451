import React from "react";

function PaymentOptionMobile({
    paymentOption,
    handlePaymentChange,
    currentActivePaymentOption,
}) {
    return (
        <div className="payment-mobile-item py-3 mb-3">
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between px-2">
                <div className="d-flex flex-column">
                    <span>{paymentOption}</span>
                </div>
                {paymentOption === currentActivePaymentOption ? (
                    <button
                        onClick={() => {
                            handlePaymentChange(paymentOption);
                        }}
                        className="selected-outline-btn mt-1"
                    >
                        Selected
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            handlePaymentChange(paymentOption);
                        }}
                        className="select-option-btn mt-1"
                    >
                        Select
                    </button>
                )}
            </div>
        </div>
    );
}

export default PaymentOptionMobile;
