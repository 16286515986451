import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { toastrTime } from "../../../../Utility/Constants/ToastrTime";
import { isObjEmpty } from "../../../../Utility/Utils";
import { API } from "../../../../Utility/ApiUrlPath";

import { UserContext } from "../../../../Contexts/user.context";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

const AddressUseForm = (validate, setActive) => {
    toast.configure();

    const { user, setUser } = useContext(UserContext);

    const [values, setValues] = useState({
        id: 0,
        strAddressLineOne: "",
        strAddressLineTwo: "",
        strCity: "",
        strProvince: "",
        strCountry: "",
        strPostalCode: "",
        bIsDefault: false,
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            const { name, checked } = e.target;
            setValues({ ...values, [name]: checked });
        } else {
            const { name, value } = e.target;
            setValues({ ...values, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errorList = validate(values); // This is an object, not an array.
        setErrors(errorList);

        if (isObjEmpty(errorList)) {
            var userInfo = {
                gGuid: user.userGuid,
                addressTitle: "", // TODO: Needs the backend to implement address title.
                strCountry: values.strCountry,
                strAddressLineOne: values.strAddressLineOne,
                strAddressLineTwo: values.strAddressLineTwo,
                strProvince: values.strProvince,
                strCity: values.strCity,
                strPostalCode: values.strPostalCode,
                bIsDefault: values.bIsDefault,
            };

            let response = null;

            try {
                response = await fetch(
                    `${API.QA_URL}api/v1/users/create_user_information`,
                    {
                        method: "POST",
                        body: JSON.stringify(userInfo),
                        headers: {
                            "Content-Type": "application/json",
                            "minestar-api-token": "xyz",
                        },
                    }
                );

                const responseJson = await response?.json();

                // eslint-disable-next-line eqeqeq
                if (responseJson !== null && responseJson === 1) {
                    toast.success("Created address.", {
                        autoClose: toastrTime,
                    });

                    // eslint-disable-next-line eqeqeq
                } else if (responseJson.message == "login failed") {
                    toast.error("Invalid credentials.", {
                        autoClose: toastrTime,
                    });
                } else {
                    toast.error("Something went wrong. Please try again.", {
                        autoClose: toastrTime,
                    });
                }
            } catch (error) {
                toast.error("Something went wrong. Please try again.", {
                    autoClose: toastrTime,
                });
            }
            setActive("AddressList");
        }
    };

    return { handleChange, handleSubmit, values, errors };
};

export default AddressUseForm;
