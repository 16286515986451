import React, { useEffect, useState, useContext, useRef } from "react";
import TooltipWrapper from "../Tooltip/TooltipWrapper";
import { API } from "../../Utility/ApiUrlPath";
import { Table } from "reactstrap";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRedo,
    faQuestion,
    faFilter,
} from "@fortawesome/free-solid-svg-icons";

import MinestarLoader from "../../Assests/Animations/Minestar-Loader-Small.gif";

// Import Utils
import { formatDate, isObjEmpty } from "../../Utility/Utils";

// Import Custom Functions
import GetAllProducts from "../../Utility/Functions/GetAllProducts";
import PriceSplitter from "../../Utility/Functions/PriceSplitter";

// Import Components
import DeclineMessage from "./DeclineMessage";
import ReAddStockForm from "./ReAddStockForm/ReAddStockForm";
import ProductView from "../ProductView/ProductView";
import MyStockFilterSection from "./MyStockFilterSection";

// Import Custom Hooks
import useWindowDimensions from "../../Utility/hooks/useWindowDimensions";

// Import Modal
import CommonDialog from "../Dialog/CommonDialog";

// Importing the css files
import "./Dashboard.scss"; //styles exclusive to this page only

import { UserContext } from "../../Contexts/user.context";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../SEO/SEONoIndex";

//start of bidding page
const Mystock = () => {
    const { user } = useContext(UserContext);

    // getDimensions of the screen
    const MOBILE_WIDTH = 720;
    const { width } = useWindowDimensions();

    //handle search values
    const [searchBySize, setSearchBySize] = useState("");
    const [searchByBrand, setSearchByBrand] = useState("");

    // Dropdown options
    // const [brandOptions, setBrandOptions] = useState([]);
    // const [sizeOptions, setSizeOptions] = useState([]);

    const [myStockList, setMyStockList] = useState([]);
    const [myFilteredStockList, setMyFilteredStockList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingProducts, setHasErrorFetchingProducts] =
        useState(false);

    // For Modal display
    const [openDecline, setOpenDecline] = useState(false);
    const [declineMessage, setDeclineMessage] = useState("");

    const [retryItem, setRetryItem] = useState(null);
    const [openRetryRequest, setOpenRetryRequest] = useState(false);

    /**
     * Fetch All Products from API.
     */
    useEffect(() => {
        const abortController = new AbortController();
        fetchAllMyStock(abortController);
        return () => abortController.abort();
    }, []);

    async function fetchAllMyStock(abortController) {
        (async () => {
            try {
                setIsLoading(true);
                let response = await GetAllProducts(
                    `${API.QA_URL}api/v1/product_reseller/my_stock?resellerGuid=${user?.resellerGuid}`,
                    abortController
                );

                if (response.StatusCode >= 200 && response.StatusCode < 300) {
                    const data = response?.MyStockList;

                    if (!isObjEmpty(data)) {
                        //populateSearchDropdowns(data);
                        setMyStockList(data);
                        setMyFilteredStockList(data);
                    }
                    setIsLoading(false);
                    setHasErrorFetchingProducts(false);
                } else {
                    setMyStockList([]);
                    setMyFilteredStockList([]);

                    if (
                        response.StatusCode >= 300 &&
                        response.StatusCode < 500
                    ) {
                        setHasErrorFetchingProducts(true);
                    }
                    setIsLoading(false);
                }
            } catch (error) {
                setMyStockList([]);
                setMyFilteredStockList([]);
                setIsLoading(false);
                setHasErrorFetchingProducts(true);
            }
        })();
    }

    // Switch between MyStock
    const switchStockStatus = (status) => {
        /**
         * My Stock Status:
         * 0 - N/A
         * 1 - Pending
         * 2 - Declined
         * 3 - Accepted
         */
        switch (status) {
            case 0:
                return (
                    <Badge pill style={{ color: "grey" }} className="me-1">
                        {"N/A"}
                    </Badge>
                );
            case 1:
                return (
                    <Badge pill style={{ color: "orange" }} className="me-1">
                        {"Pending"}
                    </Badge>
                );
            case 2:
                return (
                    <Badge pill style={{ color: "red" }} className="me-1">
                        {"Declined"}
                    </Badge>
                );
            case 3:
                return (
                    <Badge pill style={{ color: "green" }} className="me-1">
                        {"Accepted"}
                    </Badge>
                );
            default:
                return (
                    <Badge pill style={{ color: "grey" }} className="me-1">
                        {"N/A"}
                    </Badge>
                );
        }
    };

    /**
     * Gets and sets all the dropdown options.
     * @param products
     */
    // const populateSearchDropdowns = (products) => {
    //     const [brands, sizes] = FilterOptions(products);
    //     setBrandOptions(brands);
    //     setSizeOptions(sizes);
    // };

    /**
     * Filters products based on @param.
     * @param {searchByBrand, searchBySize} searchValues - Values used to filter through products.
     * @param productsToFilter - An array of products.
     */
    function filterSearchValues(
        { searchByBrand, searchBySize },
        productsToFilter = []
    ) {
        if (productsToFilter.length === 0) return;

        setIsLoading(true);
        let searchResults = [];

        if (searchByBrand !== "" || searchBySize !== "") {
            // Search by brand and size
            if (searchByBrand !== "" && searchBySize !== "") {
                searchResults = productsToFilter.filter(
                    (x) =>
                        (x.brand === searchByBrand) &
                        (x.tyreSize === searchBySize)
                );
            } else {
                searchResults = productsToFilter.filter(
                    (x) =>
                        x.brand === searchByBrand || x.tyreSize === searchBySize
                );
            }
            setMyFilteredStockList(searchResults);
        } else {
            setMyFilteredStockList(productsToFilter);
        }
        setIsLoading(false);
    }

    //dropdown filter
    const handleSubmit = (e) => {
        e.preventDefault();
        filterSearchValues(
            {
                searchBySize,
                searchByBrand,
            },
            myStockList
        );
    };

    const handleOpenDeclineMessage = (message, stockItem) => {
        setRetryItem(stockItem);
        setDeclineMessage(message);
        setOpenDecline(true);
    };
    const handleCloseDeclineMessage = () => {
        setOpenDecline(false);
        setDeclineMessage("");
    };

    const handleStockRetry = (product) => {
        setRetryItem(product);
        setOpenRetryRequest(true);
    };

    const handleCloseStockRetry = () => {
        setRetryItem(null);
        setOpenRetryRequest(false);
        const abortController = new AbortController();
        fetchAllMyStock(abortController);
    };

    const usedFilter = useRef(false);
    const [isFilterOpen, setIsFilterOpen] = useState(width >= MOBILE_WIDTH);
    const handleOpenFilters = () => {
        usedFilter.current = true;
        setIsFilterOpen(true);
    };
    const handleCloseFilters = () => {
        usedFilter.current = true;
        setIsFilterOpen(false);
    };

    function showWhatIsBeingSearched() {
        let result = ["Result:"];
        if (searchByBrand !== "") {
            result.push(searchByBrand.trim());
        }
        if (searchBySize !== "") {
            result.push(searchBySize.trim());
        }

        return `${result[0]} "${result.splice(1).join(" ")}"`;
    }

    useEffect(() => {
        if (!usedFilter.current) {
            setIsFilterOpen(width > MOBILE_WIDTH);
        }
    }, [width]);

    return (
        <div>
            <SEONoIndex title="My Stock | Minestar South Africa" />
            <h4
                id="sold-stock-title"
                className="my-1 ml-2 ml-md-0 mb-0 mb-md-2"
            >
                My Stock
            </h4>
            {isLoading && !hasErrorFetchingProducts ? (
                <div className="featured-stock-container my-2 d-flex justify-content-center align-items-center">
                    <div className="notFoundContent">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                className="mb-3"
                                src={MinestarLoader}
                                alt="Loading..."
                                height={200}
                                width={200}
                            />
                            <p
                                className="not-found-text mb-2"
                                style={{
                                    color: "#e50119",
                                }}
                            >
                                Loading Your Stock...
                            </p>
                        </div>
                    </div>
                </div>
            ) : hasErrorFetchingProducts ? (
                <div className="notFoundContent my-2 d-flex align-items-center justify-content-center">
                    <p className="not-found-text" style={{ color: "#e50119" }}>
                        Sorry, could not retrieve your stock at this time.
                        <br /> Please try again later.
                    </p>
                </div>
            ) : (
                <>
                    {/* BEGIN: Search Area */}
                    <div className="filter-section">
                        {myFilteredStockList.length > 0 && isFilterOpen ? (
                            <MyStockFilterSection
                                handleSubmit={handleSubmit}
                                brandState={{
                                    searchByBrand,
                                    setSearchByBrand,
                                }}
                                sizeState={{
                                    searchBySize,
                                    setSearchBySize,
                                }}
                                stockData={myStockList}
                                filterSearchValues={filterSearchValues}
                                handleCloseFilters={handleCloseFilters}
                            />
                        ) : null}

                        {/* Need to Open Filter */}
                        {myFilteredStockList.length > 0 && !isFilterOpen && (
                            <div
                                className="filter-container row mx-0 pr-4 justify-content-end"
                                onClick={() => {
                                    handleOpenFilters();
                                }}
                            >
                                <button className="filter-btn d-flex align-items-center mb-3 mb-lg-4">
                                    <span className="mr-2">filter</span>
                                    <FontAwesomeIcon
                                        className="filter-icon"
                                        icon={faFilter}
                                    />
                                </button>
                            </div>
                        )}

                        {/* Show Filter options */}
                        {myStockList.length !== myFilteredStockList.length ? (
                            <div className="search-results-container ml-1 ml-md-5 mb-2 mb-md-3">
                                <div className="d-flex">
                                    <div>{showWhatIsBeingSearched()}</div>
                                    <div
                                        onClick={() => {
                                            setSearchByBrand("");
                                            setSearchBySize("");

                                            // Clears filters
                                            filterSearchValues(
                                                {
                                                    searchByBrand: "",
                                                    searchBySize: "",
                                                },
                                                myStockList
                                            );
                                        }}
                                        className="ml-2 clear-filter-text"
                                    >
                                        clear
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {/* END: Search Area */}
                    {/* BEGIN: Decline Message Modal */}
                    <CommonDialog
                        title={"Reason for Decline"}
                        open={openDecline}
                        onClose={handleCloseDeclineMessage}
                    >
                        <DeclineMessage
                            declineMessage={declineMessage}
                            handleStockRetry={handleStockRetry}
                            retryItem={retryItem}
                            handleCloseDeclineMessage={
                                handleCloseDeclineMessage
                            }
                        />
                    </CommonDialog>
                    {/* END: Decline Message Modal */}

                    {/* BEGIN: Reapply stock item after it has been declined */}
                    <CommonDialog
                        title={"Reapply Stock Item"}
                        open={openRetryRequest}
                        onClose={handleCloseStockRetry}
                    >
                        <ReAddStockForm
                            stockItem={{
                                ProductResellerGuid:
                                    retryItem?.ProductResellerGuid,
                                Quantity: retryItem?.Quantity,
                                AskingPrice: retryItem?.Amount,
                                bIsUsed: !retryItem?.Condition,
                                TreadDepth: retryItem?.treadDepth,
                                ProductImage: retryItem?.imageName,
                                brand: retryItem?.brand,
                                treadPattern: retryItem?.treadPattern,
                                iProductResellerId:
                                    retryItem?.iProductResellerId,
                            }}
                            onClose={handleCloseStockRetry}
                        />
                    </CommonDialog>
                    {/* END: Reapply stock item after it has been declined */}

                    {/* BEGIN: My Stock Table */}
                    {myFilteredStockList.length > 0 ? (
                        <div className="stockTable">
                            {/* BEGIN: My Stock Table */}
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        {/* <th scope="col" className="text-nowrap pl-md-3">
                                #
                            </th> */}
                                        <th
                                            scope="col"
                                            className="text-nowrap pl-md-3"
                                        >
                                            Name
                                        </th>
                                        <th scope="col" className="text-nowrap">
                                            Date
                                        </th>
                                        <th scope="col" className="text-nowrap">
                                            Quantity
                                        </th>
                                        <th scope="col" className="text-nowrap">
                                            Price
                                        </th>
                                        <th scope="col" className="text-nowrap">
                                            Status
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-nowrap pr-md-3"
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myFilteredStockList.map(
                                        (stockItem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="pl-md-3">
                                                        <ProductView
                                                            product={stockItem}
                                                            fetchAllMyStock={
                                                                fetchAllMyStock
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        {stockItem.DateAdded !==
                                                        ""
                                                            ? formatDate(
                                                                  stockItem.DateAdded,
                                                                  {
                                                                      day: "numeric",
                                                                      month: "long",
                                                                      year: "numeric",
                                                                  }
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        {stockItem.Quantity
                                                            ? stockItem.Quantity
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        R{" "}
                                                        {stockItem.Amount
                                                            ? PriceSplitter(
                                                                  stockItem.Amount
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        {switchStockStatus(
                                                            stockItem.Status
                                                        )}
                                                    </td>
                                                    <td className="pr-md-3">
                                                        <div className="d-flex align-items-center justify-content-around">
                                                            {stockItem.Status ===
                                                                2 && (
                                                                <>
                                                                    <TooltipWrapper title="Reason">
                                                                        <span
                                                                            className="my-stock-action-btn d-flex align-items-center justify-content-center"
                                                                            onClick={() =>
                                                                                handleOpenDeclineMessage(
                                                                                    stockItem.DeclinedNote,
                                                                                    stockItem
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                className="menu-icon my-stock-icons"
                                                                                icon={
                                                                                    faQuestion
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </TooltipWrapper>

                                                                    <TooltipWrapper title="Resubmit">
                                                                        <span
                                                                            onClick={() => {
                                                                                handleStockRetry(
                                                                                    stockItem
                                                                                );
                                                                            }}
                                                                            className="my-stock-action-btn d-flex align-items-center justify-content-center"
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                className="menu-icon my-stock-icons"
                                                                                icon={
                                                                                    faRedo
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </TooltipWrapper>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </Table>
                            {/* END: My Stock Table */}
                        </div>
                    ) : (
                        <div className="stockTable">
                            <Table striped responsive>
                                <tbody>
                                    <tr>
                                        <td colSpan="7">No products found.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Mystock;
