import React, { useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import {
    faSignInAlt,
    faSignOutAlt,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
// import { faUser } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Forklift } from "../../Assests/Icons/forklift.svg";
import { toast } from "react-toastify";
import { toastrTime } from "../../Utility/Constants/ToastrTime";

import { UserContext } from "../../Contexts/user.context";

// Importing the css files
import "./Navigation.scss";

// Paths for the images used in the page
import Logo from "../../Assests/Icons/main.png";

//import components
import Filtering from "../Filtering/Filtering";
import MobileNavigation from "./MobileNavigation/MobileNav";

// Redux
import { useSelector } from "react-redux";

const Navigation = ({ page }) => {
    let { user, setUser } = useContext(UserContext);
    const cartCount = useSelector((state) => state.cart.count);
    const navigate = useNavigate();

    function handleLogout() {
        if (user) {
            localStorage.removeItem("currentUser");
            setUser(null);
        }
        toast.success("Logged out", {
            autoClose: toastrTime,
        });
    }

    return (
        <div>
            <div className="header-container">
                <MobileNavigation />
                <div className="desktop-nav row no-gutters">
                    <div className="nav-container nav-container-menu">
                        <nav
                            className="secondary-navigation navbar narbar-expand-xl"
                            role="navigation"
                        >
                            <ul className="navbar-secondary menu">
                                <li className="menu-item d-flex">
                                    <NavLink
                                        className={({ isActive }) =>
                                            "mx-0" +
                                            (isActive
                                                ? " is-active-nav-item"
                                                : "")
                                        }
                                        id="cart-link"
                                        to="/cart"
                                    >
                                        <Forklift className="menu-icon mr-2" />
                                        Cart
                                        <span className="cart-count">
                                            {cartCount !== 0 && cartCount}
                                        </span>
                                    </NavLink>
                                </li>
                                {user && (
                                    <li className="menu-item account-dropdown-container d-flex">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            id="account-link"
                                            to="/accountsettings"
                                        >
                                            <FontAwesomeIcon
                                                className="menu-icon mr-2"
                                                style={{ fontSize: "2em" }}
                                                icon={faUser}
                                            />
                                            Account
                                        </NavLink>
                                        <div className="account-dropdown">
                                            <NavLink
                                                to="profile"
                                                className={({ isActive }) =>
                                                    "menu-icon-subnav" +
                                                    (isActive
                                                        ? " is-active-subnav"
                                                        : "")
                                                }
                                            >
                                                Profile
                                            </NavLink>

                                            <NavLink
                                                to="password"
                                                className={({ isActive }) =>
                                                    "menu-icon-subnav" +
                                                    (isActive
                                                        ? " is-active-subnav"
                                                        : "")
                                                }
                                            >
                                                Password
                                            </NavLink>

                                            <NavLink
                                                to="address"
                                                className={({ isActive }) =>
                                                    "menu-icon-subnav" +
                                                    (isActive
                                                        ? " is-active-subnav"
                                                        : "")
                                                }
                                            >
                                                Address
                                            </NavLink>
                                        </div>
                                    </li>
                                )}

                                {user === null ? (
                                    <li className="menu-item menu-item-login-register d-flex">
                                        <NavLink
                                            className={({ isActive }) =>
                                                "mx-0" +
                                                (isActive
                                                    ? " is-active-nav-item"
                                                    : "")
                                            }
                                            id="profile-link"
                                            to="/login"
                                        >
                                            <FontAwesomeIcon
                                                className="menu-icon mr-2"
                                                style={{ fontSize: "2em" }}
                                                icon={faSignInAlt}
                                            />
                                            Sign in
                                        </NavLink>
                                    </li>
                                ) : (
                                    <li className="menu-item menu-item-login-register d-flex">
                                        <Link
                                            className="mx-0"
                                            id="logout-link"
                                            to="/"
                                            onClick={(e) => handleLogout(e)}
                                        >
                                            <FontAwesomeIcon
                                                className="menu-icon mr-2"
                                                style={{ fontSize: "2em" }}
                                                icon={faSignOutAlt}
                                            />
                                            Logout
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </nav>
                        <div className="nav-container nav-container-branding">
                            <div
                                className="site-branding skew-right skewed-all-screens"
                                onClick={() => navigate("/")}
                            >
                                <img
                                    src={Logo}
                                    alt="Minestar South Africa"
                                    className="logo__img"
                                    id="mainLogo"
                                />
                            </div>
                        </div>
                        <nav
                            className="main-navigation navbar narbar-expand-xl"
                            role="navigation"
                        >
                            <div className="navbar-primary-container">
                                <div className="navbar-primary-wrap d-flex">
                                    <div className="nav-container  nav-container-branding">
                                        <div className="site-branding"></div>
                                    </div>
                                    <ul className="navbar-primary menu">

                                        <li className="menu-item">
                                            <NavLink
                                                className={({ isActive }) =>
                                                    "menuLink" +
                                                    (isActive
                                                        ? " is-active-nav-item"
                                                        : "")
                                                }
                                                to="/reseller"
                                            >
                                                Reseller
                                            </NavLink>
                                        </li>

                                        <li className="menu-item">
                                            <NavLink
                                                className={({ isActive }) =>
                                                    "menuLink" +
                                                    (isActive
                                                        ? " is-active-nav-item"
                                                        : "")
                                                }
                                                to="/products"
                                            >
                                                Products
                                            </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink
                                                className={({ isActive }) =>
                                                    "menuLink" +
                                                    (isActive
                                                        ? " is-active-nav-item"
                                                        : "")
                                                }
                                                to="/about"
                                            >
                                                About
                                            </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink
                                                className={({ isActive }) =>
                                                    "menuLink" +
                                                    (isActive
                                                        ? " is-active-nav-item"
                                                        : "")
                                                }
                                                to="/contact"
                                            >
                                                Contact
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            {page === "HomePage" ? <Filtering /> : null}
        </div>
    );
};

export default Navigation;
