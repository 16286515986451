import React, { useEffect, useState } from "react";
import "./RenderPdfDownloads.scss";
import GetAllProducts from "../../../Utility/Functions/GetAllProducts";
import { API } from "../../../Utility/ApiUrlPath";

import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";
import "../../../Components/Errors/Errors.scss";
import BrochureView from "./BrochureView";

function RenderPdfDownloads({ brandName, brandId }) {
    const [isFetching, setIsFetching] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [pdfList, setPdfList] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        getAllBrochures(abortController);
        return () => abortController.abort();
    }, []);

    const getAllBrochures = async (abortController) => {
        if (brandId) {
            try {
                setIsFetching(true);

                const data = await GetAllProducts(
                    `${API.QA_URL}api/v1/brands/brand_brochure_count?brandId=${brandId}`,
                    abortController
                );

                if (data.status === 200) {
                    setHasErrors(false);

                    setPdfList(data.brochureIdList.$values);
                } else {
                    setHasErrors(true);
                    setPdfList([]);
                }
                setIsFetching(false);
            } catch (e) {
                setPdfList([]);
                setHasErrors(true);
                setIsFetching(false);
            }
        } else {
            setIsFetching(false);
            setHasErrors(true);
        }
    };

    return (
        <div className="px-3">
            {isFetching ? (
                <div className="notFoundContent my-3 my-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="my-2"
                            src={MinestarLoader}
                            alt="Loading..."
                            height={150}
                            width={150}
                        />
                        <p
                            className="not-found-text"
                            style={{ color: "#e50119" }}
                        >
                            Checking for files...
                        </p>
                    </div>
                </div>
            ) : hasErrors ? (
                <div className="notFoundContent my-2 d-flex align-items-center justify-content-center">
                    <p
                        className="not-found-text mx-4"
                        style={{ color: "#e50119" }}
                    >
                        Sorry, we could not retrieve the files at this time.
                        <br />
                        Please try again later.
                    </p>
                </div>
            ) : (
                <div
                    className="d-flex flex-wrap justify-content-start"
                    style={{ gap: "2rem" }}
                >
                    {pdfList.length > 0 ? (
                        pdfList?.map((id, index) => {
                            return (
                                <BrochureView
                                    key={index}
                                    brandName={brandName}
                                    brandBrochureId={id}
                                    index={index + 1}
                                />
                            );
                        })
                    ) : (
                        <div className="no-files-container">
                            <h3>No files found.</h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default RenderPdfDownloads;
