import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Chatbot.scss";
import { CHATBOT_API } from "../../Utility/ChatbotAuth";

const Chatbot = () => {
    const [messages, setMessages] = useState([
    {
        role: "system",
        content: "Hey there! How can I help you?",
    },
    ]);
const [loading, setLoading] = useState(false);
const [inputValue, setInputValue] = useState("");
const [isTyping, setIsTyping] = useState(false);
const messagesContainerRef = useRef(null);
const textareaRef = useRef(null);
const [isChatbotVisible, setIsChatbotVisible] = useState(false);
const [threadId, setThreadId] = useState(null);

async function getThreadId() {
    const response = await fetch(
        `${CHATBOT_API.URL}/thread`,
        {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            },
        }
    );
    if (!response.ok) {
        throw new Error("Error getting thread id");
    }
    const data = await response.json();
    setThreadId(data.threadId);

    return data.threadId;
}

useEffect(() => {
    scrollToBottom();
}, [messages]);

useEffect(() => {
    resizeTextarea();
}, [inputValue]);

useEffect(() => {
    if (window.innerWidth > 991) {
        setTimeout(() => {
            setIsChatbotVisible(true);
        }, 3000);
    }
}, []);

const chatgptAPICall = async (prompt, threadId) => {
    try {
        const response = await fetch(
            `${CHATBOT_API.URL}/message`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                threadId: threadId,
                message: prompt,
                }),
            }
        );

        if (!response.ok) {
            throw new Error("Error in response");
        }

        const data = await response.json();
        return data.message;
    } catch (error) {
        const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
        toast.error(errorMessage);
    }
};

const scrollToBottom = () => {
    if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
};

const handleSend = async () => {
    if (!inputValue.trim()) {
        toast.error("Please type something");
        return;
    }

    let currentThreadId = threadId;
    if (!currentThreadId) {
        try {
            currentThreadId = await getThreadId();
        } catch (error) {
            console.error(error);
            return;
        }
    }


    setIsTyping(true);
    const userMessage = { role: "user", content: inputValue };
    setMessages([...messages, userMessage]);
    setInputValue("");

    try {
        const botResponseText = await chatgptAPICall(inputValue,currentThreadId);
        if (!botResponseText) {
            throw new Error("Error in response");
        }
        //console.log(botResponseText);

        setIsTyping(false);
        const botResponse = { role: "system", content: "" };
        setMessages((prevMessages) => {
            const newMessages = [...prevMessages, botResponse];
            typewriterEffect(botResponseText, newMessages.length - 1);
            return newMessages;
        });
    } catch (error) {
        const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
        toast.error(errorMessage);
        setIsTyping(false);
    }
};

const handleInputChange = (e) => {
    setInputValue(e.target.value);
};

const handleKeyPress = (e) => {
    if (e.key === "Enter") {
        handleSend();
    }
};

const typewriterEffect = (text, index) => {
    let charIndex = -1;
    const interval = setInterval(() => {
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            if (updatedMessages[index].content.length < text.length) {
                updatedMessages[index].content += text.charAt(charIndex);
            }
            return updatedMessages;
        });
        charIndex++;
        if (charIndex === text.length) {
            clearInterval(interval);
        }
    }, 20);
};

const resizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
        textarea.style.height = "auto";
        const scrollHeight = textarea.scrollHeight;
        const lineHeight = parseInt(
            window.getComputedStyle(textarea).lineHeight,
            10
        );
        const rows = Math.floor(scrollHeight / lineHeight);
        const maxRows = 3;

        if (rows > maxRows) {
            textarea.style.height = `${lineHeight * maxRows}px`;
            textarea.style.overflowY = "auto";
        } else {
            textarea.style.height = `${scrollHeight}px`;
            textarea.style.overflowY = "hidden";
        }
    }
};

const toggleChatbot = () => {
    setIsChatbotVisible(!isChatbotVisible);
};

return (
    <>
        <ToastContainer />
        <button className={`chatbot-toggle-button`} onClick={toggleChatbot}>
            <i>
                <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" fill="#FFF" className="bi bi-chat-fill" viewBox="0 0 16 16">
                    <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9 9 0 0 0 8 15"/>
                </svg>
            </i>
        </button>

        {isChatbotVisible && (
        <div className={`chatbot-container ${isChatbotVisible ? "" : "hidden"}`} >
            <div className="chatbot-header m-0">
                <span className="title-lg">Chat with us</span>
                <button onClick={toggleChatbot}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#FFF" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                </button>
            </div>
        <div
            id="msg"
            ref={messagesContainerRef}
            className="chatbot-messages enhanced-textarea" >
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`mb-4 ${
                        message.role === "system" ? "text-left" : "text-right"
                        }`} >
                        <span
                        className={`d-inline-block p-3 animate-pulse ${
                            message.role === "system"
                            ? "chatbot-system-message"
                            : "chatbot-user-message"
                        }`} >
                            <pre>{message.content}</pre>
                        </span>
                    </div>
                ))}

            {isTyping && (
                <div className="text-left mb-2">
                    <span className="d-inline-block p-3 chatbot-system-message typing-text">
                        Typing...
                    </span>
                </div>
            )}
        </div>
            <div className="chatbot-input">
                <textarea
                    ref={textareaRef}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyPress}
                    placeholder="Type here..."
                    className="flex-grow p-2 border rounded-md outline-none resize-none overflow-y-hidden"
                    rows="1"
                />
                <button
                    onClick={handleSend}
                    className="p-2"
                    disabled={false} >
                    <i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#FFF" className="bi bi-send" viewBox="0 0 16 16">
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                        </svg>
                    </i>
                </button>
            </div>
        </div>
        )}
    </>
);
};

export default Chatbot;
