/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Table } from "reactstrap";
import { toast } from "react-toastify";
import { toastrTime } from "../../../../Utility/Constants/ToastrTime";
import { API } from "../../../../Utility/ApiUrlPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "../../../../Contexts/user.context";

import CommonDialog from "../../../Dialog/CommonDialog";

// Import Components
import OrderView from "../OrderView/OrderView";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import MinestarLoader from "../../../../Assests/Animations/Minestar-Loader-Small.gif";

// Importing the css files
import "./OrdersList.scss"; //styles exclusive to this page only

// Import Utils
import { formatDate, isObjEmpty } from "../../../../Utility/Utils";

// Import Contants
import { OrderStatus } from "../../../../Utility/Constants/OrderStatus";

// Importing SEO component (this page shouoldn't be indexed by search engines)
import SEONoIndex from "../../../SEO/SEONoIndex";

const OrdersList = () => {
    let [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorFetchingAddresses, setHasErrorFetchingAddresses] =
        useState(false);

    const [open, setOpen] = useState(false);
    const [order, setOrder] = useState({});

    const { user } = useContext(UserContext);

    useEffect(() => {
        setIsLoading(true);
        setHasErrorFetchingAddresses(false);
        fetch(
            `${API.QA_URL}api/v1/order/get_all_orders_user?gGuid=${user?.userGuid}`
        )
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                }
                if (response.status >= 400 && response.status <= 499) {
                    toast.error(
                        "Order details could not be loaded successfully. Please try again.",
                        {
                            autoClose: toastrTime,
                        }
                    );
                }
            })
            .then((responseJson) => {
                if (responseJson) {
                    // handle success
                    const orders = [];
                    if (responseJson?.ordersModelList?.$values.length !== 0) {
                        responseJson.ordersModelList.$values.forEach(
                            (order) => {
                                orders.push(order);
                            }
                        );
                    }

                    const sortedOrders = orders
                        .sort((a, b) => {
                            return a.iOrderId <= b.iOrderId ? 1 : -1;
                        })
                        .map((order, index) => {
                            return (
                                <tr key={index}>
                                    <td className="pl-md-3" data-label="#Ref">
                                        {order.iOrderId}
                                    </td>
                                    <td data-label="Date">
                                        {order.dtEdited
                                            ? formatDate(order.dtEdited, {
                                                  // weekday: "long",
                                                  day: "numeric",
                                                  month: "long",
                                                  year: "numeric",
                                                  // hour: "numeric",
                                                  // minute: "numeric",
                                                  // second: "numeric",
                                              })
                                            : formatDate(order.dtAdded, {
                                                  // weekday: "long",
                                                  day: "numeric",
                                                  month: "long",
                                                  year: "numeric",
                                                  // hour: "numeric",
                                                  // minute: "numeric",
                                                  // second: "numeric",
                                              })}
                                    </td>
                                    <td data-label="Amount">
                                        {order.dblTotalCost &&
                                            "R" + order.dblTotalCost}
                                    </td>
                                    {/*
                                        Order Status:
                                        0 - Processing
                                        1- Shipped
                                        2- Delivered
                                     */}
                                    {order.iOrderStatus ===
                                    OrderStatus.PENDINGPAYMENT ? (
                                        <td
                                            data-label="Status"
                                            className="processing"
                                        >
                                            Pending Payment
                                        </td>
                                    ) : order.iOrderStatus ===
                                      OrderStatus.PROCESSING ? (
                                        <td
                                            data-label="Status"
                                            className="shipped"
                                        >
                                            Processing
                                        </td>
                                    ) : order.iOrderStatus ===
                                      OrderStatus.SHIPPED ? (
                                        <td
                                            data-label="Status"
                                            className="shipped"
                                        >
                                            Shipped
                                        </td>
                                    ) : order.iOrderStatus ===
                                      OrderStatus.DELIVERED ? (
                                        <td
                                            data-label="Status"
                                            className="delivered"
                                        >
                                            Delivered
                                        </td>
                                    ) : (
                                        <td data-label="Status">-</td>
                                    )}
                                    <td className="pr-md-3">
                                        <div className="d-flex align-items-center justify-content-around">
                                            <span
                                                onClick={() => {
                                                    handleViewOrder(order);
                                                }}
                                                className="my-stock-action-btn d-flex align-items-center justify-content-center"
                                            >
                                                <FontAwesomeIcon
                                                    className="menu-icon my-stock-icons"
                                                    icon={faEye}
                                                />
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        });
                    setData(sortedOrders);
                    setIsLoading(false);
                    setHasErrorFetchingAddresses(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setHasErrorFetchingAddresses(true);
                console.error(error);
            });
    }, []);

    const handleViewOrder = (order) => {
        setOrder(order);
        setOpen(true);
    };
    const handleCloseOrder = () => {
        setOrder({});
        setOpen(false);
    };

    return (
        <>
            <SEONoIndex title="Orders | Minestar South Africa" />
            <h4 className="orders-title my-1 ml-2 ml-md-0 mb-2">Orders</h4>
            {/* BEGIN: View Product Modal */}
            <CommonDialog
                title={"Order Details"}
                open={open}
                onClose={handleCloseOrder}
            >
                {!isObjEmpty(order) ? (
                    <OrderView order={order} />
                ) : (
                    "No order found."
                )}
            </CommonDialog>
            {/* END: View Product Modal */}
            {/* {!isLoading && !hasErrorFetchingAddresses ? (
                <> */}
            {/* {data && data.length > 0 ? (
                        <div id="orders-table">
                            <Table striped responsive id="table">
                                <thead>
                                    <tr>
                                        <th className="pl-md-3">#Ref</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th className="pr-md-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>{data}</tbody>
                            </Table>
                        </div>
                    ) : ( */}
            <div id="orders-table">
                <Table striped responsive id="table">
                    <thead>
                        <tr>
                            <th scope="col" className="text-nowrap pl-md-3">
                                #Ref
                            </th>
                            <th scope="col" className="text-nowrap">
                                Date
                            </th>
                            <th scope="col" className="text-nowrap">
                                Amount
                            </th>
                            <th scope="col" className="text-nowrap">
                                Status
                            </th>
                            <th scope="col" className="text-nowrap pr-md-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && !hasErrorFetchingAddresses ? (
                            data && data.length > 0 ? (
                                <>{data}</>
                            ) : (
                                <tr>
                                    <td colSpan="5">No orders found.</td>
                                </tr>
                            )
                        ) : isLoading ? (
                            <tr>
                                <td colSpan="5">
                                    <div className="notFoundContent my-3 my-md-5">
                                        <div className="d-flex flex-column align-items-center">
                                            <img
                                                className="my-2"
                                                src={MinestarLoader}
                                                alt="Loading..."
                                                height={200}
                                                width={200}
                                            />
                                            <p
                                                className="not-found-text"
                                                style={{ color: "#e50119" }}
                                            >
                                                Loading Orders...
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            hasErrorFetchingAddresses && (
                                <tr>
                                    <td colSpan="5">
                                        <div className="notFoundContent my-2 my-md-5 d-flex align-items-center justify-content-center">
                                            <p
                                                className="not-found-text mx-4"
                                                style={{ color: "#e50119" }}
                                            >
                                                Sorry, we could not retrieve
                                                your addresses at this time.
                                                <br />
                                                Please try again later.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default OrdersList;
