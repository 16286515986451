import React from "react";
import "./TermsAndConditionsToggle.scss";

function TermsConditions({ checkboxState, handleClickOpenTerms }) {
    return (
        <>
            <div className="consentField d-flex align-items-center justify-content-end mr-2 mt-2">
                <input
                    type="checkbox"
                    name="consentApproval"
                    id="consentApproval"
                    value={checkboxState.isCheckboxSelected}
                    onChange={() => {
                        checkboxState.setIsCheckboxSelected((value) => !value);
                    }}
                />
                <label
                    htmlFor="consentApproval"
                    className="agreement mb-0 ml-2"
                >
                    I agree to the
                </label>
                <span
                    className="terms-and-conditions"
                    onClick={handleClickOpenTerms}
                >
                    &nbsp;terms & conditions.
                </span>{" "}
            </div>
        </>
    );
}

export default TermsConditions;
