import { useState } from "react";
import { isObjEmpty } from "../../../Utility/Utils";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

const RegisteruseForm = (validate, userDetailsState, setActive) => {
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;

        userDetailsState.setUserDetails({
            ...userDetailsState.userDetails,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const err = validate(userDetailsState.userDetails);
        setErrors(err);

        if (isObjEmpty(err)) {
            setActive("RegisterAddress");
        }
    };

    return { handleChange, handleSubmit, errors };
};

export default RegisteruseForm;
