import React, { useState, useContext } from "react";
import { Card, CardTitle, CardBody, CardText, Row, Col } from "reactstrap";
import "../../../Pages/CartPage/Cart.scss";
import "./CartTotal.scss";
import CommonDialog from "../../Dialog/CommonDialog";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import { UserContext } from "../../../Contexts/user.context";

// Redux
import { useSelector } from "react-redux";
import { selectCart } from "../../../Redux/Features/Cart/cartSlice";

// Imort Comonents
import FormSignup from "../../Forms/LoginForm/FormSignup";

const CartTotal = ({ setActive }) => {
    const cart = useSelector(selectCart);

    const { user } = useContext(UserContext);

    const [open, setOpen] = useState(false);

    function handleOrder() {
        if (user) {
            setActive("checkout");
        } else {
            handleClickOpenModal();
        }
    }

    const handleClickOpenModal = () => {
        setOpen(true);
    };
    const handleClickCloseModal = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="row mx-0">
                <h1 className="col-12">Cart Total</h1>
            </div>
            <CommonDialog
                title={"Login"}
                open={open}
                onClose={handleClickCloseModal}
            >
                <FormSignup
                    isFromCartPage={true}
                    handleCartFunc={() => setActive("checkout")}
                />
            </CommonDialog>
            <Row>
                <Col>
                    <Card className="p-3">
                        <CardBody>
                            <CardTitle tag="h3" className="py-1 m-0">
                                Price Details
                            </CardTitle>
                            <CardText>
                                <span className="row mx-0">
                                    <span className="text text-left col-8">
                                        Subtotal
                                    </span>
                                    <span className="value col-4">
                                        {cart.subTotal !== 0
                                            ? "R" + cart.subTotal
                                            : "R0"}
                                    </span>
                                </span>
                                <span className="row mx-0">
                                    <span className="text text-left col-8">
                                        Tax
                                    </span>
                                    <span className="value col-4">
                                        {cart.subTotal !== 0 &&
                                        cart.taxAmount !== 0
                                            ? "R" + cart.taxAmount
                                            : "R0"}
                                    </span>
                                </span>
                            </CardText>
                            <CardTitle tag="h3" className="py-1 my-0">
                                <div className="row mx-0">
                                    <p className="col-8 px-0">
                                        Subtotal (incl. tax)
                                    </p>
                                    <p className="col-4">
                                        {cart.subTotal !== 0 && cart.total !== 0
                                            ? "R" + cart.total
                                            : "R0"}
                                    </p>
                                </div>
                            </CardTitle>
                            {cart.subTotal !== 0 ? (
                                <button
                                    onClick={handleOrder}
                                    className="add-address-btn mt-2"
                                    disabled={
                                        cart?.subTotal && cart.subTotal !== 0
                                            ? false
                                            : true
                                    }
                                >
                                    Checkout
                                </button>
                            ) : null}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CartTotal;
