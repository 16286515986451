export default function validateInfo(values) {
    let errors = {};

    if (!values.email) {
        errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Email address is invalid.";
    }
    if (!values.consentApproval) {
        errors.consentApproval = "You must agree to terms & conditions.";
    }

    return errors;
}
