import React from "react";
import { Row, Col } from "reactstrap";
import "../../../Pages/CartPage/Cart.scss";
import "./CartList.scss";

// Components
import CartItem from "./CartItem";

// Redux
import { useSelector } from "react-redux";
import { selectCart } from "../../../Redux/Features/Cart/cartSlice";

const CartList = () => {
    const cart = useSelector(selectCart);

    return (
        <>
            <div className="row mx-0">
                <h1 className="col-12">Items ({cart.count})</h1>
            </div>
            <Row>
                <Col>
                    {cart.cartList?.length !== 0 ? (
                        cart.cartList?.map((itemData) => {
                            return (
                                <CartItem
                                    key={itemData.iProductResellerId}
                                    iProductResellerId={
                                        itemData.iProductResellerId
                                    }
                                />
                            );
                        })
                    ) : (
                        <span className="ml-3">Cart is empty :(.</span>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default CartList;
