import React, { useState, useEffect } from "react";
import { Card, CardTitle, CardBody, CardText, Row, Col } from "reactstrap";

import PriceSplitter from "../../../Utility/Functions/PriceSplitter";

// Redux
import { useSelector } from "react-redux";
import { selectCart } from "../../../Redux/Features/Cart/cartSlice";

import Decimal from "decimal.js-light"; // http://mikemcl.github.io/decimal.js-light/
Decimal.set({
    rounding: Decimal.ROUND_HALF_UP,
});

function Summary() {
    const [cartTotal, setCartTotal] = useState(null);
    const cart = useSelector(selectCart);

    useEffect(() => {
        let total = new Decimal(cart.total);
        setCartTotal(total.plus(cart.transactionFee).toFixed(2));
    }, []);

    return (
        <Row className="order-summary mx-0">
            <Col>
                <Card className="p-2 p-md-3">
                    <CardBody>
                        <CardTitle tag="h3" className="py-1 m-0">
                            Summary
                        </CardTitle>
                        <CardText className="row mx-0">
                            <span className="text text-left col-8 px-0">
                                {cart.cartList.length} items (incl. tax)
                            </span>
                            <span className="value col-4 px-0">
                                {cart.total !== 0 ? "R" + cart.total : "R0"}
                            </span>
                        </CardText>
                        {cart.transactionFee !== null &&
                            cart.transactionFee > 0 && (
                                <CardText className="row mx-0">
                                    <span className="text text-left col-8 px-0">
                                        Ozow Transaction Fee
                                    </span>
                                    <span className="value col-4 px-0">
                                        R {cart.transactionFee}
                                    </span>
                                </CardText>
                            )}
                        <CardTitle tag="h3" className="py-1 my-0">
                            <div className="row mx-0">
                                <p className="col-8 px-0">To Pay</p>
                                <p className="col-4 px-0">
                                    {cart.subTotal !== "0.00" &&
                                    cart.total !== "0.00"
                                        ? "R " + PriceSplitter(cartTotal)
                                        : "R0"}
                                </p>
                            </div>
                        </CardTitle>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default Summary;
