/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Modal Component
import CommonDialog from "../Dialog/CommonDialog";

// Importing the css files
import "./Footer.scss";

// Paths for the images used in the page
import Logo from "../../Assests/Icons/main.png";

// Importing components
import PreFooter from "./Pre.Footer";
import SubscribeForm from "../Forms/SubscribeForm/SubscribeForm";
import TermsAndConditions from "../LegalDocs/TermsAndConditions";
import PrivacyPolicy from "../LegalDocs/PrivacyPolicy";
import FAQ from "../FAQ/FAQ";
import PostFooter from "./Post.Footer";

const Footer = () => {
    // Modal
    const [openTerms, setOpenTerms] = useState(false);
    const [openPrivacy, setOpenPrivacy] = useState(false);
    const [openFAQ, setOpenFAQ] = useState(false);

    const handleClickOpenTerms = () => {
        setOpenTerms(true);
    };
    const handleClickCloseTerms = () => {
        setOpenTerms(false);
    };

    const handleClickOpenPrivacy = () => {
        setOpenPrivacy(true);
    };
    const handleClickClosePrivacy = () => {
        setOpenPrivacy(false);
    };

    const handleClickOpenFAQ = () => {
        setOpenFAQ(true);
    };
    const handleClickCloseFAQ = () => {
        setOpenFAQ(false);
    };

    const navigate = useNavigate();

    const handleEmailClick = () => {
        const emailAddress = "sales@minestar.co.za";
        const mailtoUrl = `mailto:${emailAddress}`;
        window.open(mailtoUrl, "_blank");
    };

    return (
        <div className="footer-container">
            <CommonDialog
                title={"Minestar Terms & Conditions"}
                open={openTerms}
                onClose={handleClickCloseTerms}
            >
                <TermsAndConditions />
            </CommonDialog>

            <CommonDialog
                title={"Minestar Privacy Policy"}
                open={openPrivacy}
                onClose={handleClickClosePrivacy}
            >
                <PrivacyPolicy />
            </CommonDialog>

            <CommonDialog
                title={"FAQ"}
                open={openFAQ}
                onClose={handleClickCloseFAQ}
            >
                <FAQ />
            </CommonDialog>

            <PreFooter />
            {/*footer row*/}
            <div className="row mx-0 px-3 px-sm-4 px-md-5 pt-4 pb-2">
                {/*1st column*/}
                <div className="footer-column col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                    <div className="footer-card">
                        <img
                            className="logo__img__footer"
                            src={Logo}
                            alt="Minestar South Africa"
                            onClick={() => navigate("/")}
                        />
                        <a
                            className="mt-3 footer-email"
                            onClick={handleEmailClick}
                            href="mailto:sales@minestar.co.za"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            sales@minestar.co.za
                        </a>
                    </div>
                </div>
                {/*2nd column*/}
                <div className="footer-column col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                    <div className="footer-card">
                        <p className="footer-header">Useful links</p>
                        <div>
                            <Link to="/products" className="footer_links">
                                Products
                            </Link>
                        </div>
                        <div>
                            <Link to="/about" className="footer_links">
                                About Us
                            </Link>
                        </div>
                    </div>
                </div>
                {/*3th column*/}
                <div className="footer-column col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                    <div className="footer-card">
                        <p className="footer-header">Help & Support</p>
                        <div>
                            <a
                                className="footer_links"
                                onClick={handleClickOpenFAQ}
                            >
                                FAQ
                            </a>
                        </div>
                        <div>
                            <Link to="/contact" className="footer_links">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
                {/*4th column*/}
                <div className="footer-column col-12 col-lg-3 mb-3 d-flex justify-content-center my-md-3 my-lg-0">
                    <div className="footer-card">
                        <p className="footer-header">
                            Subscribe for <br />
                            Deals and Promotions
                        </p>
                        {/*subscription form*/}
                        <SubscribeForm
                            handleClickOpenTerms={handleClickOpenTerms}
                        />
                    </div>
                </div>
            </div>
            <PostFooter
                handleClickOpenTerms={handleClickOpenTerms}
                handleClickOpenPrivacy={handleClickOpenPrivacy}
            />
        </div>
    );
};

export default Footer;
