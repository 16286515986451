import React from "react";

import "./OrderSummary.scss";
import Summary from "./Summary";

function OrderSummary({ isOrderProcessComplete = false }) {
    return <>{!isOrderProcessComplete && <Summary />}</>;
}

export default OrderSummary;
