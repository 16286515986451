/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

import "./Register.scss";

import { useNavigate } from "react-router-dom";

// import components
import validate from "./ValidateRegisterInfo";
import useForm from "./RegisteruseForm";

const RegisterForm = ({ setActive, userDetailsState, type }) => {
    const navigate = useNavigate();
    const { handleChange, handleSubmit, errors } = useForm(
        validate,
        userDetailsState,
        setActive
    );

    const location = useLocation();
    let IsReseller = location?.state ?? null;

    const manageSelect = (SyntheticEvent, newValue) => {
        type.setUserType(newValue);
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="loginForm row mx-0 px-2 px-xl-5"
            noValidate
        >
            <h1 className="col-12 m-0">Register As Resller</h1>
            <Box
                className="mb-2 mx-1"
                sx={{
                    width: "fit-content",
                }}
            >
                {IsReseller ? (
                    manageSelect(null, "Reseller")
                ) : (
                    <Tabs
                        value={type.userType}
                        className="slider-border"
                        textColor="inherit"
                        onChange={manageSelect}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#e50019",
                            },
                        }}
                    >
                        <Tab
                            sx={{ fontSize: { sm: "10px", md: "12px" } }}
                            label="Customer"
                            value="Buyer"
                        />
                        <Tab
                            sx={{ fontSize: { sm: "10px", md: "12px" } }}
                            label="Reseller"
                            value="Reseller"
                        />
                    </Tabs>
                )}
            </Box>

            <div className="form-group mt-3 mb-2">
                <label className="heading-small mb-1 ml-1">First Name</label>
                <input
                    className="form-control"
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    // value={values.firstname}
                    value={userDetailsState.userDetails.firstname}
                    onChange={handleChange}
                />
                {errors.firstname && (
                    <p className="text-errors">{errors.firstname}</p>
                )}
            </div>
            <div className="form-group my-2">
                <label className="heading-small mb-1 ml-1">Last Name</label>
                <input
                    className="form-control"
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    value={userDetailsState.userDetails.lastname}
                    onChange={handleChange}
                />
                {errors.lastname && (
                    <p className="text-errors">{errors.lastname}</p>
                )}
            </div>
            <div className="form-group my-2">
                <label className="heading-small mb-1 ml-1">Email</label>
                <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={userDetailsState.userDetails.email}
                    onChange={handleChange}
                />
                {errors.email && <p className="text-errors">{errors.email}</p>}
            </div>
            <div className="form-group my-2">
                <label className="heading-small mb-1 ml-1">Phone Number</label>
                <input
                    className="form-control"
                    type="text"
                    name="phonenumber"
                    placeholder="0700000000"
                    value={userDetailsState.userDetails.phonenumber}
                    onChange={handleChange}
                />
                {errors.phonenumber && (
                    <p className="text-errors">{errors.phonenumber}</p>
                )}
            </div>

            <div className="form-group my-2">
                <label className="heading-small mb-1 ml-1">Password</label>
                <input
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={userDetailsState.userDetails.password}
                    onChange={handleChange}
                />
                {errors.password && (
                    <p className="text-errors">{errors.password}</p>
                )}
            </div>

            <div className="form-group mt-2 mb-4">
                <label className="heading-small mb-1 ml-1">
                    Confirm Password
                </label>
                <input
                    className="form-control"
                    type="password"
                    name="password2"
                    placeholder="Confirm Password"
                    value={userDetailsState.userDetails.password2}
                    onChange={handleChange}
                />
                {errors.password2 && (
                    <p className="text-errors">{errors.password2}</p>
                )}
            </div>

            <div className="col-12 px-0 mb-3">
                <button className="register-btn mb-2 mr-2" type="submit">
                    Next
                </button>
                <button
                    type="button"
                    className="login-redirect mt-2"
                    onClick={() => navigate("/login")}
                >
                    Sign In
                </button>
            </div>
        </form>
    );
};

export default RegisterForm;
