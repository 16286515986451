import React, { useState } from "react";

import validate from "./ValidateReAddStockForm";
import useForm from "./ReAddStockuseForm";

import UploadIcon from "../../../Assests/Icons/upload.png";
import MinestarLoader from "../../../Assests/Animations/Minestar-Loader-Small.gif";

function ReAddStock({ stockItem, onClose }) {
    const [isLoading, setIsLoading] = useState(false);

    const { handleChange, handleImageChange, handleSubmit, values, errors } =
        useForm(validate, stockItem, onClose, setIsLoading);

    return (
        <>
            {isLoading ? (
                <div className="featured-stock-container d-flex justify-content-center align-items-center">
                    <div className="notFoundContent">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                className="mb-3"
                                src={MinestarLoader}
                                alt="Loading..."
                                height={200}
                                width={200}
                            />
                            <p
                                className="not-found-text mb-2"
                                style={{
                                    color: "#e50119",
                                }}
                            >
                                Processing stock to add...
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <form
                            onSubmit={handleSubmit}
                            className="p-2"
                            id="reseller-add-stock-form"
                            noValidate
                        >
                            <div className="row">
                                <div className="form-inputs mt-4 col-6">
                                    <label className="pl-1 mb-1 heading-small">
                                        Stock Quantity
                                    </label>
                                    <input
                                        className="form-control loginInput"
                                        type="text"
                                        name="Quantity"
                                        placeholder="0"
                                        value={
                                            values.bIsUsed
                                                ? 1
                                                : values.Quantity ?? ""
                                        }
                                        disabled={values.bIsUsed}
                                        onChange={handleChange}
                                        tabIndex={1}
                                    />
                                    {errors.Quantity && (
                                        <p className="text-errors">
                                            {errors.Quantity}
                                        </p>
                                    )}
                                </div>
                                <div className="form-inputs mt-4 col-6">
                                    <label className="pl-1 mb-1 heading-small">
                                        Price
                                    </label>
                                    <input
                                        className="form-control loginInput"
                                        type="number"
                                        name="AskingPrice"
                                        placeholder="0"
                                        value={values.AskingPrice ?? ""}
                                        onChange={handleChange}
                                        tabIndex={1}
                                    />
                                    {errors.AskingPrice && (
                                        <p className="text-errors">
                                            {errors.AskingPrice}
                                        </p>
                                    )}
                                </div>
                                <div className="form-inputs mt-md-2 col-12">
                                    <input
                                        className="ml-1"
                                        type="checkbox"
                                        name="bIsUsed"
                                        id="bIsUsed"
                                        onChange={handleChange}
                                        tabIndex={3}
                                        style={{ backgroundColor: "red" }}
                                        checked={values.bIsUsed}
                                    ></input>
                                    <label
                                        htmlFor="bIsUsed"
                                        className="form-check-label pl-1"
                                        value={values.bIsUsed}
                                    >
                                        Is this tyre used?
                                    </label>
                                </div>
                            </div>
                            {values.bIsUsed && (
                                <div className="row">
                                    <div className="form-inputs col-6 mt-1 mt-md-2">
                                        <label className="pl-1 heading-small mb-1">
                                            Tread Depth
                                        </label>
                                        <input
                                            className="form-control loginInput"
                                            type="text"
                                            name="TreadDepth"
                                            placeholder="0"
                                            value={values.TreadDepth ?? ""}
                                            onChange={handleChange}
                                        />
                                        {errors.TreadDepth && (
                                            <p className="text-errors">
                                                {errors.TreadDepth}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-inputs upload-img-container col-6 mt-1 mt-md-2">
                                        <label
                                            id="add-img-label"
                                            htmlFor="add-single-img"
                                        >
                                            <img
                                                className="uploadIcon"
                                                src={
                                                    values.ProductImage === null
                                                        ? UploadIcon
                                                        : values.ProductImage
                                                }
                                                alt="Upload"
                                            />
                                            {values.ProductImage === null && (
                                                <span className="uploadText">
                                                    Upload Image
                                                </span>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            id="add-single-img"
                                            name="ProductImage"
                                            accept="image/png"
                                            onChange={(e) => {
                                                handleImageChange(e);
                                            }}
                                        />
                                        {errors.ProductImage && (
                                            <p className="text-errors">
                                                {errors.ProductImage}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="row d-flex justify-content-end">
                                <button
                                    className="col-6 add-stock-btn float-right mt-2"
                                    type="submit"
                                >
                                    Reapply
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default ReAddStock;
